import moment from "moment";
import * as localDb from '../localDb/index.js';
import getBackupsDirHandle from "./getBackDirHandle.js";
import download from "downloadjs";

export default async function createBackup({type, fileData, downloadWhenComplete, setLoadingAlert}) {
    try {
        // Get handle to draft file in OPFS
        //const backupsDirHandle = await deletePreviousBackups();
        const backupsDirHandle = await getBackupsDirHandle();
        let backup;

        if (!fileData) {
             backup = await localDb.exportDb({
                downloadFile: false,
                progressCallback: ({completedRows, totalRows, done}) => setLoadingAlert((prev) => ({...prev, show: !done, message: `Realizando backup ${Math.round((completedRows / totalRows)*100)}%`}))
        });
        } else {
            backup = fileData;
        }

        const fileNames = {
            user: 'user_backup_' + moment().format('YYYY-MM-DD HH:mm:ss') + '.json',
            uploaded: 'uploaded_backup.json',
            auto: 'auto_backup.json',
            undefined: 'auto_backup.json'
        }

        const backupHandle = await backupsDirHandle.getFileHandle(fileNames[type], { create: true });
        //Check if browser supports writable
        if (backupHandle.createWritable) {
            const writableStream = await backupHandle.createWritable();
            //setLoadingAlert({show: true, message: 'Cargando...'});
            await writableStream.write(backup);
            await writableStream.close();
            //setLoadingAlert({show: false, message: ''});
        } else {
            await backupsDirHandle.removeEntry(fileNames[type]);
        }
        //Download backup to user if user requested.
        if (downloadWhenComplete) {
            download(backup, fileNames[type]);
        }
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to backup current data`);
    }
}