import { v4 as UUIDV4 } from 'uuid';
import moment from 'moment';
import * as localDb from '../localDb';

export default async function checkEventComplete(task, currentUser) {
    try {
        let eventActivities = await localDb.getMany('activities').then(result => result.where({ monitoringEventId: task.monitoringEventId }).toArray());
        let totalActivities = eventActivities.length;
        let completedActivities = eventActivities.filter(act => act.taskStatusId === 2).length;
        let startedActivities = eventActivities.filter(act => act.taskStatusId === 1).length;
        let event = await localDb.getOne('events', task.monitoringEventId);

        if (event.totalActivities === completedActivities) {
            let update = {
                totalActivities: totalActivities,
                completedActivities: completedActivities,
                updatedAt: moment.utc().format(),
                updatedBy: currentUser,
                taskStatusId: 2,
                _rev: UUIDV4()
            }
            return await localDb.update('events', event.id, update, true);
        }
        if (startedActivities) {
            let update = {
                totalActivities: totalActivities,
                completedActivities: completedActivities,
                updatedAt: moment.utc().format(),
                updatedBy: currentUser,
                taskStatusId: 1,
                _rev: UUIDV4()
            }
            return await localDb.update('events', event.id, update, true);
        }
        return await localDb.update('events', event.id, {_rev: UUIDV4()}, true);
    } catch (error) {
        console.error(`Unable to check event completion: ${error.message}`);
    }
}