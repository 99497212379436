//React
import { useState, useEffect, useCallback, useRef, useContext, useLayoutEffect } from "react";

import * as localDb from "../../services/localDb";

//Context
import AppContext from "../../context/appContext";

//Leaflet
import L from "leaflet";
import "leaflet.offline";
import "leaflet/dist/leaflet.css";
import storageLayer from "./storageLayer";
import debounce from "debounce";

import { MapContainer } from "react-leaflet/MapContainer";
import { Popup } from "react-leaflet/Popup";
import { Marker } from "react-leaflet/Marker";
import { LayerGroup } from "react-leaflet/LayerGroup";
import { GeoJSON } from "react-leaflet/GeoJSON";
import { initialTask, currentLocation, noTasks, validationSite } from "../../assets/leafletIcons";
import { FeatureGroup } from "react-leaflet";

//Turf
import { helpers } from "@turf/turf";
import getCentroid from "../../services/geolocationScripts/getCentroid.js";

//MUI
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//Custom
import LinearWithValueLabel from "../LinearWithValueLabel/LinearWithValueLabel.jsx";

//Icons
import LocationOn from "@mui/icons-material/MyLocation";
import LocationOff from "@mui/icons-material/GpsNotFixed";
import Download from "@mui/icons-material/Download";
import Delete from "@mui/icons-material/Delete";

//Services
import buildSiteFeatureCollection from "../../services/geolocationScripts/buildSiteFeature.js";
import { ApplicationError } from "../../models/errors/index.js";

//Consts for MapContainer Config
const zoom = 13;
const maxZoom = 21;
const maxNativeZoom = 19;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default function OverviewMap({ height, width, enableLocation, initialZoom, allowZoom, eventId, allowDonwload, flyToPosition, getCoords, farm }) {
    const { setAlert, storageFreeze, errorCatcher } = useContext(AppContext);
    const [map, setMap] = useState();
    const [watching, setWatching] = useState(true);
    const [sites, setSites] = useState();
    const [samplingAreas, setSamplingAreas] = useState();
    const [paddocks, setPaddocks] = useState();
    const [position, setPosition] = useState();
    const [firstLocation, setFirstLocation] = useState(true);
    const [progressPct, setProgressPct] = useState(0);
    const watchId = useRef();

    let saveControl = useRef();
    let downloadProgress = useRef();
    let totalTyles = useRef();

    const geoSuccess = useCallback(
        (pos) => {
            let posLatLng = new L.LatLng(pos.coords.latitude, pos.coords.longitude);
            setPosition(posLatLng);
            if (getCoords) getCoords(pos);
        },
        [getCoords]
    );

    const geoError = (error) => {
        console.error("Geolocation Error");
        console.error(error);
    };

    const watch = useCallback(() => {
        let options = {
            enableHighAccuracy: true,
            timeout: 60000,
            maximumAge: 0,
        };
        watchId.current = navigator.geolocation.watchPosition(geoSuccess, geoError, options);
    }, [geoSuccess]);

    const stopWatch = () => {
        navigator.geolocation.clearWatch(watchId.current);
    };

    const toggleWatch = () => {
        if (watching) {
            setWatching(false);
            stopWatch();
        } else {
            setFirstLocation(true);
            setWatching(true);
            watch();
        }
    };

    const handleStyle = (feature) => {
        return {
            fillColor: feature.properties.color,
            color: feature.properties.color,
            weight: 2,
            opacity: 1,
            fillOpacity: 0.1,
        };
    };

    const handlePaddockStyle = (feature) => {
        return {
            color: "#0F0F0F",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.1,
        };
    };

    const setupGridLayer = (feature, layer) => {
        layer.bindPopup(
            Object.keys(feature.properties).reduce((acc, cur) => {
                if (cur === "id") {
                    acc += `<b>${feature.properties[cur]}</b>`;
                } else {
                    if (!["hasActivities", "isValidationSite"].includes(cur)) acc += `<p>${cur}: ${feature.properties[cur]}</p>`;
                }
                return acc;
            }, "")
        );
    };

    const setupMakers = (feature, latlng) => {
        let marker;
        if (feature.properties.hasActivities) {
            marker = L.marker(latlng, { icon: initialTask });
        } else {
            marker = L.marker(latlng, { icon: noTasks });
        }
        if (feature.properties.isValidationSite) {
            marker = L.marker(latlng, { icon: validationSite });
        }
        return marker;
    };

    const showProgress = debounce(() => {
        setProgressPct((downloadProgress.current / totalTyles.current) * 100);
        if (downloadProgress.current === totalTyles.current) {
            setTimeout(() => setProgressPct(0), 1000);
        }
    }, 10);

    const handleSaveTiles = () => {
        saveControl.current._saveTiles();
    };

    const handleRemoveTiles = () => {
        saveControl.current._rmTiles();
    };

    const getGeometries = useCallback(async () => {
        try {
            let event = await localDb.getOne("events", eventId);

            const paddocks = localDb.getMany("paddocks").then((result) => result.where({ farmId: event.farmId }).toArray());
            const sampAreas = localDb.getMany("samplingAreas").then((result) => result.where({ farmId: event.farmId }).toArray());
            const sites = localDb.getMany("sites").then((result) => result.where({ farmId: event.farmId }).toArray());

            let geometries = await Promise.all([paddocks, sampAreas, sites]);

            let paddockFeatures = helpers.featureCollection(geometries[0].map((area) => area.toGeoJSON));
            let samplingAreaFeatures = helpers.featureCollection(geometries[1].map((area) => area.toGeoJSON));
            let siteFeatures = await buildSiteFeatureCollection(geometries[2]);

            setPaddocks(paddockFeatures);
            setSites(siteFeatures);
            setSamplingAreas(samplingAreaFeatures);
        } catch (error) {
            errorCatcher(new ApplicationError(`Unable to get geometries: ${error.message}`));
        }
    }, [eventId, errorCatcher]);

    useEffect(() => {
        getGeometries();
        try {
            getGeometries();
            if (enableLocation) watch();
        } catch (error) {
            errorCatcher(new ApplicationError(error.message));
        }
        return () => {
            if (watchId.current) stopWatch();
        };
    }, [watch, enableLocation, errorCatcher, getGeometries]);

    useEffect(() => {
        try {
            if (map && farm && !flyToPosition) 
                {   
                    map.fitBounds(L.geoJSON(farm.toGeoJSON).getBounds(), { padding: [20, 20] });
                }
        } catch (error) {
            map.setView(getCentroid(farm.toGeoJSON), 13, { animate: true } )
            setAlert({ show: true, severity: "error", message: `Ha ocurrido un error: ${error.message}}` });
        }
    }, [farm, map, flyToPosition, setAlert]);

    useLayoutEffect(() => {
        if (map && firstLocation && position && enableLocation) {
            map.flyTo(position, 13);
            setFirstLocation(false);
        }
    }, [map, position, firstLocation, enableLocation]);

    useEffect(() => {
        if (map) {
            const baseLayer = L.tileLayer
                .offline("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
                    subdomains: ["mt0", "mt1", "mt2", "mt3"],
                    maxZoom: maxZoom + 1,
                })
                .addTo(map);

            if (allowDonwload) {
                saveControl.current = L.control.savetiles(baseLayer, {
                    zoomlevels: [13, 15, 17, 19], // optional zoomlevels to save, default current zoomlevel
                    alwaysDownload: false,
                    confirm(layer, successCallback) {
                        // eslint-disable-next-line no-alert
                        if (window.confirm(`Tamaño aprox. a guardar ${Math.round((layer._tilesforSave.length * 10) / 1024, 2)} MB`)) {
                            successCallback();
                        }
                    },
                    confirmRemoval(layer, successCallback) {
                        if (window.confirm("Remove all the tiles?")) {
                            successCallback();
                        }
                    },
                    saveText: '<i class="fa fa-download fa-lg" title="Save tiles"></i>',
                    rmText: '<i class="fa fa-trash fa-lg" title="Remove tiles"></i>',
                });
                saveControl.current.addTo(map);

                //oculta savetiles control.
                let saveTilesDiv = document.querySelector(".savetiles");
                saveTilesDiv.style.display = "none";

                const layerswitcher = new L.Control.Layers(
                    {
                        "Mapa base": baseLayer,
                    },
                    null,
                    { collapsed: false }
                ).addTo(map);
                // add storage overlay
                storageLayer(baseLayer, layerswitcher);

                baseLayer.on("savestart", (e) => {
                    downloadProgress.current = 0;
                    totalTyles.current = e._tilesforSave.length;
                });
                baseLayer.on("loadtileend", () => {
                    downloadProgress.current += 1;
                    showProgress();
                });
            }
        }
    }, [map, allowDonwload]);

    return (
        eventId && (
            <Stack display='flex' direction='column' flexGrow={0} justifyItems='flex-start' alignContent={"space-between"} height={height + 20} width={width}>
                <MapContainer
                    style={{ height: height, width: width }}
                    center={[-32.234, -61.197]}
                    zoom={initialZoom || zoom}
                    maxZoom={maxZoom}
                    maxNativeZoom={maxNativeZoom}
                    scrollWheelZoom={false}
                    doubleClickZoom={allowZoom || false}
                    closePopupOnClick={allowZoom || false}
                    dragging={allowZoom || false}
                    zoomSnap={allowZoom || false}
                    zoomDelta={allowZoom || false}
                    trackResize={allowZoom || false}
                    touchZoom={allowZoom || false}
                    zoomControl={allowZoom || false}
                    attributionControl={false}
                    ref={setMap}
                >
                    {sites && (
                        <FeatureGroup>
                            <LayerGroup>
                                <GeoJSON data={sites} pointToLayer={setupMakers} onEachFeature={setupGridLayer}></GeoJSON>
                            </LayerGroup>
                        </FeatureGroup>
                    )}
                    {samplingAreas && (
                        <FeatureGroup>
                            <LayerGroup>
                                <GeoJSON name='samplingAreas' data={samplingAreas} style={handleStyle} />
                            </LayerGroup>
                        </FeatureGroup>
                    )}
                    {paddocks && (
                        <FeatureGroup>
                            <LayerGroup>
                                <GeoJSON name='paddocks' data={paddocks} style={handlePaddockStyle} />
                            </LayerGroup>
                        </FeatureGroup>
                    )}
                    {sites && samplingAreas && position && (
                        <Marker position={position} icon={currentLocation}>
                            <Popup>Estás aqui</Popup>
                        </Marker>
                    )}
                </MapContainer>
                {false && (
                    <Stack position='absolute' top='85vh' right='2.5vw' spacing={2} alignItems='flex-end'>
                        <Fab color={watching ? "primary" : "greyedOut"} size='medium' onClick={toggleWatch}>
                            {watching ? <LocationOn /> : <LocationOff />}
                        </Fab>
                    </Stack>
                )}
                {allowDonwload && (
                    <Stack pt={0} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-around"}>
                        {progressPct > 0 && <LinearWithValueLabel value={progressPct} />}
                        <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
                            <Typography id='storage' variant='caption'></Typography>
                            <Stack direction={"row"}>
                                <Button disabled={storageFreeze} startIcon={<Download />} variant='text' onClick={() => handleSaveTiles()}>
                                    MAPA
                                </Button>
                                <Button startIcon={<Delete />} variant='text' onClick={() => handleRemoveTiles()}>
                                    BORRAR
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        )
    );
}
