import { useContext, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import UserInformationModal from '../UserInformationModal/UserInformationModal';

//Context
import AppContext from '../../context/appContext';


export function UserInformationButton() {
    const { currentUser } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal((prev) => !prev)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <>
            {currentUser ?
                <PersonIcon onClick={handleClick} color='primary' />
                :
                <PersonOffIcon onClick={() => console.log('logged off')} color='warning' />
                }
            {showModal &&
                <UserInformationModal show={showModal} closeModal={closeModal} />}
        </>
    )
}