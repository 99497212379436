import { useContext } from 'react';
import Wifi from '@mui/icons-material/Wifi';
import WifiOff from '@mui/icons-material/WifiOff';

//Context
import AppContext from '../../context/appContext';

export function WifiIconIndicator () {
    const { isOnline, setAlert } = useContext(AppContext);

    const handleClick = () => {
        if (isOnline) {
            setAlert({show: true, message: 'Dispositivo online', severity: 'success'});
        } else {
            setAlert({show: true, message: 'Dispositivo offline. Puede seguir trabajando de manera offline. Deberá tener conectividad para sincronizar datos al servidor o descargar mapas', severity: 'warning'});
        }
      }

    return (
        isOnline ?
                <Wifi onClick={handleClick} color='primary'/>
            :
                <WifiOff onClick={handleClick} color='warning'/>
    )
}