export function getInitials(input) {
    // Trim the input to remove leading or trailing whitespace
    input = input.trim();

    // Remove hyphens, split by spaces, and filter out empty strings
    let words = input
        .replace(/-/g, " ")
        .split(/\s+/)
        .filter((word) => word);

    // Map each word to its first character (uppercase), and keep any numbers
    let initials = words.map((word) => {
        return isNaN(word) ? word.charAt(0).toUpperCase() : word;
    });

    // Join the initials together
    return initials.join("");
}
