export async function getStorageUsageEstimate() {

    if (!navigator.storage.estimate) {
        return null
    };
    const maxUsagePct = 90;
    const warningUsagePct = 80;
    let estimate = await navigator.storage.estimate();
    let quotaGb = Math.round(estimate.quota / 1024 / 1024 / 1024);
    let usagePct = Math.round((estimate.usage / estimate.quota) * 100);
    let usageGb = Math.round((estimate.usage) / 1024 / 1024 / 1024);
    let availableGb = Math.round((estimate.quota - estimate.usage) / 1024 / 1024 / 1024);
    let colorCode = 'primary';

    if (usagePct <= warningUsagePct) {
        colorCode = 'primary';
    }
    if (usagePct > warningUsagePct && usagePct < maxUsagePct) {
        colorCode = 'warning';
    }
    if (usagePct >= maxUsagePct) {
        colorCode = 'error'
    }

    return ({
        ...estimate,
        maxUsagePct: maxUsagePct,
        usagePct: usagePct,
        usageGb: usageGb,
        quotaGb: quotaGb,
        availableGb: availableGb,
        colorCode: colorCode,
    })
}