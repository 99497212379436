import { v4 as UUIDV4 } from 'uuid';
import generateTasks from './generateTasks.js';
import moment from 'moment';

import * as localDb from '../../services/localDb'

export default async function generateActivity(userName, site, monitoringEventId, farmId, workflowId, samplingAreaId, options) {
    try {
        let farm = await localDb.getOne('farms', farmId);
        let workflow = JSON.parse(localStorage.getItem('workflows')).find(w => w.id === workflowId)
        let workFlowName = workflow.name;
        let workflowDescription = workflow.description;

        let activityKey = workflow.siteRequired ? `${workFlowName}${site ? '-' + site.name: ''}` : `${workFlowName}${farm ? '-' + farm.name: ''}`;
        
        if (site) {
            let existingActivity = await localDb.getMany('activities').then((result) => result.where({key: `${workFlowName}-${site.name}`, monitoringEventId: monitoringEventId}).toArray());
            if(existingActivity?.length) {
                throw new Error (`Actividad ya existente para este sitio`);
            }
        } else {
            let existingActivity = await localDb.getMany('activities').then((result) => result.where({key: `${workFlowName}-${farm.name}`, monitoringEventId: monitoringEventId}).toArray());
            if(existingActivity?.length) {
                throw new Error (`Actividad ya existente para este establecimiento`);
            }
        }

        let activityLayout = JSON.parse(localStorage.getItem('activityLayouts')).find(l => l.id === workflow.activityLayoutId)
        let newActivity =
        {
            id: UUIDV4(),
            _rev: UUIDV4(),
            key: activityKey,
            monitoringWorkflowId: workflowId,
            description: workflowDescription,
            monitoringEventId: monitoringEventId,
            samplingAreaId: samplingAreaId,
            monitoringSiteId: site?.id || null,
            plannedLocation: [],
            backupPoints: [],
            hasLayoutPattern: activityLayout ? true : false,
            activityLayoutId: activityLayout?.id,
            activityGrid: null,
            allowContingencyOffset: true,
            offsetMts: 100,
            actualLocation: [],
            goodToGo: false,
            taskStatusId: 0,
            accuracyRangeMts: 3,
            completedTasks: 0,
            totalTasks: 0,
            createdBy: userName,
            updatedBy: userName,
            createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss')
        }
        let tasks = await generateTasks(site, userName, newActivity.monitoringWorkflowId, newActivity.monitoringEventId, newActivity.id, options);
        newActivity.totalTasks = tasks.length;
        const db = await localDb.getDb();

        await db.transaction('rw', ['activities', 'tasks', 'changelog'], async () => {
            await localDb.put('activities', newActivity.id, newActivity, true, db);
            await localDb.bulkPut('tasks', tasks, true, db);
        })
        return ('Success');
    }
    catch (e) {
        console.error(e);
        throw new Error(`Error al generar la actividad: ${e.message}`);
    }
}