import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import remarkGfm from 'remark-gfm'
import Markdown from 'react-markdown'


export default function AlertDialog({ show, toggleAlert, severity, title, message, action, actionButtonTitle, cancelButtonTitle, cancelAction}) {
    const handleClose = () => {
        toggleAlert(false);
        if (cancelAction) cancelAction();
    };

    const handleAction = async () => {
        if (action) action();
        handleClose();

    }

    const getIcon = (severity) => {
        if (severity === 'error') {
            return (
                <Stack display='flex' direction='row' spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <ErrorOutlinedIcon color='error' sx={{ fontSize: '24px' }} />
                    {title && <Typography>{title}</Typography>}
                </Stack>
            )
        }
        if (severity === 'success') {
            return (
                <Stack display='flex' direction='row' spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <CheckCircleOutlineOutlinedIcon color='success' sx={{ fontSize: '24px' }} />
                    {title && <Typography>{title}</Typography>}
                </Stack>
            )
        }
        if (severity === 'warning') {
            return (
                <Stack display='flex' direction='row' spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <WarningAmberIcon color='warning' sx={{ fontSize: '24px' }} />
                    {title && <Typography>{title}</Typography>}
                </Stack>
            )
        }
    }

    return (
        <Dialog
            open={show || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{zIndex: 9999}}
        >
            <DialogTitle id="alert-dialog-title">
                {getIcon(severity)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component='div' sx={{ textAlign: 'center' }} id="alert-dialog-description">
                    <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>
                </DialogContentText>
                <Stack display='flex' direction='row' pt={2} spacing={1} alignItems={'center'} justifyContent={'space-around'}>
                    <Button onClick={handleAction}>
                        {actionButtonTitle || 'CERRAR'}
                    </Button>
                    {cancelButtonTitle &&
                        <Button color='secondary' onClick={handleClose}>
                            {cancelButtonTitle}
                        </Button>
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    );
}