//REACT
import { useContext, useState } from 'react'

//Hook Form
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import refDataByKey from '../../../services/localRefData/refDataByKey.js'

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import AppContext from '../../../context/appContext.js';
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';
import { PhotoInput } from '../../PhotoInput/PhotoInput.jsx';
import TaskContext from '../../../context/taskContext.js';

const defaultHorizon = {
    code: '',
    depthStart: 0,
    depthEnd: 0,
    soilTexture: '',
    structureType: '',
    structureSize: '',
    structureGrade: '',
    colorWet: '',
    colorDry: '',
    rootsAmount: '',
    gravelsAmount: '',
    comments: ''
}

export default function TaskForm({
    geolocation,
    position,
    handleCameraInput,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    pictures
}) {
    const { setAlert } = useContext(AppContext);
    const { task } = useContext(TaskContext);
    const [accordionExpanded, setAccordionExpanded] = useState([])
    const { control, setValue, formState } = useFormContext();

    const horizons = useFieldArray({
        control,
        name: 'horizons'
    });

    const handleAccordion = (num) => {
        if (accordionExpanded.includes(num)) {
            setAccordionExpanded(s => s.filter( a => a !== num))
        } else {
            setAccordionExpanded(s => [...s, num])
        }
    }

    const getMenuOptions = (key, includeLong = true, lang = 'es_AR') => {

        return refDataByKey(key).map((option, index) => {
            return (
                <MenuItem key={index} value={option.id}>{option[lang]}{includeLong ? ' - ' + option[`${lang}_long`] : ''}</MenuItem>
            );
        });
    }

    const confirmDelete = (index) => {
        setAlert({
            show: true,
            severity: 'warning',
            message: '¿Desea borrar este registro?',
            action: () => horizons.remove(index),
            actionButtonTitle: 'Confirmar',
            cancelButtonTitle: 'Cancelar'
        })
    }

    return (
        <Stack spacing={2}>
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Box pt={1} textAlign={'center'}>
                <Typography><b>Realizar pozo exploración con una pala, de al menos 60cm de profundidad. Una cara del pozo debe recibir luz del sol directa. Una vez realizado, tome una fotografía colocando una regla como referencia.</b></Typography>
            </Box>
            <Stack display='flex' direction={'column'} spacing={1} width={'100%'} p={1}>
                {horizons?.fields.map((item, index) =>
                    <Accordion sx={{ width: '100%' }} key={index} expanded={ 'horizons' in formState.errors ? true : accordionExpanded.includes(index)} onChange={() => handleAccordion(index)}>
                        <AccordionSummary
                            sx={{ backgroundColor: 'primary' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Stack display={'flex'} direction={'row'} alignItems={'center'} justifyContent={'left'} width={'100%'}>
                                {index !== 0 &&
                                    <IconButton disabled={index === 0} size='small' color='error'
                                        onClick={() => confirmDelete(index)}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                }
                                <Typography>Horizonte {index + 1}</Typography>
                            </Stack>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack display={'flex'} direction={'column'} key={item.id} spacing={1.5}>
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.code`}
                                    control={control}
                                    name={`horizons.${index}.code`}
                                    render={({ field, fieldState }) =>
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="horizonCode-select-label">Código</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                                                                label="Código"
                                                fullWidth
                                                labelId='horizonCode-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}

                                            >
                                                {getMenuOptions('horizonCodes', true, 'es_AR')}
                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    }
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.depthStart`}
                                    control={control}
                                    name={`horizons.${index}.depthStart`}
                                    render={({ field, fieldState }) => (
                                        <NumericInput 
                                            field={field}
                                            fieldState={fieldState}
                                            label={'Profundidad Comienzo (cm)'}
                                            min={0}
                                            max={null}
                                            unit={'cm'}
                                        />
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.depthEnd`}
                                    control={control}
                                    name={`horizons.${index}.depthEnd`}
                                    render={({ field, fieldState }) => (
                                        <NumericInput 
                                            field={field}
                                            fieldState={fieldState}
                                            label={'Profundidad fin (cm)'}
                                            min={0}
                                            max={null}
                                            unit={'cm'}
                                        />
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.soilTexture`}
                                    control={control}
                                    name={`horizons.${index}.soilTexture`}
                                    render={({ field, fieldState }) => (
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="soilTexture-select-label">Textura</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                label="Tipo de estructura"
                                                name="soilTexture"
                                                fullWidth
                                                labelId='soilTexture-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}

                                            >
                                                {getMenuOptions('soilTextures', true, 'es_AR')}
                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.structureType`}
                                    control={control}
                                    name={`horizons.${index}.structureType`}
                                    render={({ field, fieldState }) => (
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="structureType-select-label">Tipo de estructura</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                label="Tipo de estructura"
                                                name="structureType"
                                                fullWidth
                                                labelId='structureType-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}
                                            >
                                                {getMenuOptions('structureTypes', true, 'es_AR')}
                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.structureSize`}
                                    control={control}
                                    name={`horizons.${index}.structureSize`}
                                    render={({ field, fieldState }) => (
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="structureSize-select-label">Tamaño de estructura</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                label="Tamaño de estructura"
                                                name="structureSize"
                                                fullWidth
                                                labelId='structureSize-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}

                                            >
                                                {getMenuOptions('structureSizes', true, 'es_AR')}
                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.structureGrade`}
                                    control={control}
                                    name={`horizons.${index}.structureGrade`}
                                    render={({ field, fieldState }) => (
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="structureGrade-select-label">Grado de estructura</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                label="Grado de estructura"
                                                name="structureGrade"
                                                fullWidth
                                                labelId='structureGrade-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}

                                            >
                                                {getMenuOptions('structureGrades', true, 'es_AR')}

                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.colorWet`}
                                    control={control}
                                    name={`horizons.${index}.colorWet`}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            error={fieldState.error ? true : false}
                                            helperText={fieldState.error?.message}
                                            type='text'
                                            label='Color húmedo'
                                            InputProps={{
                                                type: 'text'
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.colorDry`}
                                    control={control}
                                    name={`horizons.${index}.colorDry`}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            error={fieldState.error ? true : false}
                                            helperText={fieldState.error?.message}
                                            type='text'
                                            label='Color seco'
                                            InputProps={{
                                                type: 'text'
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.rootsAmount`}
                                    control={control}
                                    name={`horizons.${index}.rootsAmount`}
                                    render={({ field, fieldState }) => (
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="roots-select-label">Raices</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                label="Raices"
                                                fullWidth
                                                labelId='roots-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}
                                            >
                                                <MenuItem value={'none'}>Nulo</MenuItem>
                                                <MenuItem value={'light'}>Escaso</MenuItem>
                                                <MenuItem value={'moderate'}>Moderado</MenuItem>
                                                <MenuItem value={'intense'}>Intenso</MenuItem>
                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`horizons.${item.id}.gravelsAmount`}
                                    control={control}
                                    name={`horizons.${index}.gravelsAmount`}
                                    render={({ field, fieldState }) => (
                                        <FormControl variant='outlined' error={fieldState.error ? true : false}>
                                            <InputLabel id="gravels-select-label">Gravas</InputLabel>
                                            <Select
                                                {...field}
                                                MenuProps={{ sx: { zIndex: 10000 } }}
                                                label="Gravas"
                                                fullWidth
                                                labelId='gravels-select-label'
                                                onChange={(_, change) => {
                                                    field.onChange(change.props.value);
                                                }}
                                                value={field.value}
                                            >
                                                <MenuItem value={'none'}>Nulo</MenuItem>
                                                <MenuItem value={'light'}>Escaso</MenuItem>
                                                <MenuItem value={'moderate'}>Moderado</MenuItem>
                                                <MenuItem value={'intense'}>Intenso</MenuItem>
                                            </Select>
                                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    key={`horizons.${item.id}.comments`}
                                    control={control}
                                    name={`horizons.${index}.comments`}
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            error={fieldState.error ? true : false}
                                            helperText={fieldState.error?.message}
                                            type='text'
                                            multiline
                                            rows={5}
                                            label='Comentarios'
                                            InputProps={{
                                                type: 'text'
                                            }}
                                        />
                                    )}
                                />
                                <PhotoInput
                                    width='200'
                                    height='150'
                                    handleCameraFile={handleCameraInput}
                                    picture={pictures?.find(pic => pic.key === `${task.key}-horizon-${index}`)}
                                    pictureKey={`${task.key}-horizon-${index}`}
                                    pictureIndex={pictures.findIndex(pic => pic.key === `${task.key}-horizon-${index}`) >= 0 ? pictures.findIndex(pic => pic.key === `${task.key}-horizon-${index}`) : index}
                                    pictureId={pictures.find(pic => pic.key === `${task.key}-horizon-${index}`)?.id || null}
                            />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )}
                <Button color='primary' variant='text' size='small' onClick={() => horizons.append(defaultHorizon)}>+ AGREGAR HORIZONTE</Button>
            </Stack>
        </Stack>
    )
}