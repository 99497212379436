
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import * as localDb from '../localDb';

export default async function savePictures (username, monitoringEventId, entityName, entityId,  pictures) {
    if (!pictures || !pictures.length) {
        throw new Error ('Missing pictures')
    }
    try {
        for (let pic of pictures) {
            if (pic.id) {
                let dbPic = await localDb.getOne('pictures', pic.id);
                //pic changed?
                if (dbPic && dbPic._rev !== pic._rev) {
                    await localDb.update('pictures', pic.id,
                        {
                            syncStatus: 'not-synced',
                            _rev: pic._rev,
                            data: pic.data,
                            updatedAt: moment.utc().format(),
                            updatedBy: username
                        })
                }
            } else {
                //pic doesn't exist, create pic in localDb
                let picData = {
                    id: uuid(),
                    _rev: pic._rev,
                    monitoringEventId: monitoringEventId,
                    entity: entityName,
                    entityId: entityId,
                    name: pic.name,
                    data: pic.data,
                    key: pic.key,
                    updatedAt: moment.utc().format(),
                    updatedBy: username
                }
                await localDb.put('pictures', picData.id, picData, true)
            }
        }
        
    } catch(e) {
        throw new Error (`Unable to save pictures: ${e.message}`)
    }

}

