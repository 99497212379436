import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { isStoragePersisted } from "../../services/helpers/storageUtils.js";

import { useContext } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

import AppContext from "../../context/appContext.js";
import { Check, Warning } from "@mui/icons-material";
import { green, orange, red } from "@mui/material/colors";
export function StorageInformationModal({ show, message, severity, handleClose }) {
    const { storageData } = useContext(AppContext);

    const handleAction = async () => {
        handleClose();
    };

    const getIcon = (severity) => {
        if (severity === "error") {
            return (
                <Box sx={{ width: "8rem", height: "8rem", position: "relative", display: "inline-flex" }}>
                    <CircularProgressbar
                        text={`${storageData.usagePct}%`}
                        value={storageData.usagePct}
                        styles={{
                            path: {
                                stroke: red[600],
                                strokeLinecap: "butt",
                                transition: "stroke-dashoffset 0.5s ease 0s",
                                transformOrigin: "center center",
                            },
                            trail: {
                                stroke: "#d6d6d6",
                                strokeLinecap: "butt",
                                transformOrigin: "center center",
                            },
                            text: {
                                fontSize: "20px",
                            },
                            background: {
                                fill: "#3e98c7",
                            },
                        }}
                    />
                </Box>
            );
        }
        if (severity === "success") {
            return (
                <Box sx={{ width: "8rem", height: "8rem", position: "relative", display: "inline-flex" }}>
                    <CircularProgressbar
                        text={`${storageData.usagePct}%`}
                        value={storageData.usagePct}
                        styles={{
                            path: {
                                stroke: green[500],
                                strokeLinecap: "butt",
                                transition: "stroke-dashoffset 0.5s ease 0s",
                                transformOrigin: "center center",
                            },
                            trail: {
                                stroke: "#d6d6d6",
                                strokeLinecap: "butt",
                                transformOrigin: "center center",
                            },
                            text: {
                                fontSize: "20px",
                            },
                            background: {
                                fill: "#3e98c7",
                            },
                        }}
                    />
                </Box>
            );
        }
        if (severity === "warning") {
            return (
                <Box sx={{ width: "8rem", height: "8rem", position: "relative", display: "inline-flex" }}>
                    <CircularProgressbar
                        text={`${storageData.usagePct}%`}
                        value={storageData.usagePct}
                        styles={{
                            path: {
                                stroke: orange[600],
                                strokeLinecap: "butt",
                                transition: "stroke-dashoffset 0.5s ease 0s",
                                transformOrigin: "center center",
                            },
                            trail: {
                                stroke: "#d6d6d6",
                                strokeLinecap: "butt",
                                transformOrigin: "center center",
                            },
                            text: {
                                fontSize: "20px",
                            },
                            background: {
                                fill: "#3e98c7",
                            },
                        }}
                    />
                </Box>
            );
        }
    };
    return (
        <>
            <Dialog open={show || false} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' sx={{ zIndex: 9999 }}>
                <DialogTitle sx={{ textAlign: "center" }} id='alert-dialog-title'>
                    Información de almacenamiento
                </DialogTitle>
                <DialogContent>
                    <Stack flex direction={"row"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
                        {getIcon(severity)}
                    </Stack>
                    <Stack alignItems={"center"} justifyContent={'center'} pt={1} pb={2} spacing={2} direction={"row"}>
                        <DialogContentText><Typography variant="caption">Almacenamiento persistente </Typography></DialogContentText>
                        { isStoragePersisted() ? 
                            <Check fontSize={"14px"} color={"success"} />
                            : 
                            <Warning fontSize={"14px"} color={"warning"}/>
                        }
                    </Stack>
                    {storageData && storageData.usagePct !== 0 && <LinearProgress value={storageData.usagePct} variant='determinate' color={storageData.colorCode} />}
                    <DialogContentText sx={{ paddingTop: 2, textAlign: "center" }} id='alert-dialog-description'>
                        {message}
                    </DialogContentText>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}></Stack>
                    <Stack display='flex' direction='row' pt={2} spacing={1} alignItems={"center"} justifyContent={"space-around"}>
                        <Button onClick={handleAction}>{"CERRAR"}</Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}
