import { templates } from "./templates";

export function getTaskDependencies(monitoringWorkflowId, order, totalTasks) {
    try {
        let tasks;
        if (monitoringWorkflowId === 0) {
            tasks = templates.socTasks({name: ''}, null, null, null);
        }
        if (monitoringWorkflowId === 1) {
            tasks = templates.stmTasks({name: ''}, null, null, null);
        }
        if (monitoringWorkflowId === 2) {
            tasks = templates.ltmTasks({name: ''}, null, null, null);
        }
        if (monitoringWorkflowId === 3) {
            tasks = templates.socTasksNative({name: ''}, null, null, null);
        }
        if (monitoringWorkflowId === 4) {
            tasks = templates.fbcTasks({name: ''}, null, null, {amountOfSites: totalTasks});
        }
        if (!tasks) {
            throw new Error( `Task flow not found`);
        }
        let filteredTaskbyOrder = tasks.find(t => t.order === order);
        return filteredTaskbyOrder.dependencies;
    } catch(error) {
        console.error(error);
        throw new Error( `Unable to create tasks: ${error.message}`);
    }
}