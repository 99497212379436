import { v4 as UUIDV4 } from 'uuid';

export default function fbcTasks(userName, monitoringEventId, monitoringActivityId, options) {
  let tasks = [];
  for (let i = 0; i < options.amountOfSites ; i++) {
    tasks.push({
      id: UUIDV4(),
      key: `FIELD-BOUNDARIES-CHECK-${i + (1 + (options.startOrder || 0))}`,
      order: i + (options.startOrder || 0),
      enabled: true,
      actionRangeMts: null,
      monitoringSiteId: null,
      monitoringActivityId: monitoringActivityId,
      monitoringEventId: monitoringEventId,
      taskStatusId: 0,
      formName: 'CreateValidationSite',
      type: 'createValidationSite',
      actualLocation: [],
      plannedLocation: [],
      dependencies: [],
      description: `Crear sitio de validación ${i + (1 + (options.startOrder || 0))}`,
      dataPayload: null,
      pictures: null,
      updatedBy: null,
      createdBy: userName,
    })
  }
  return tasks
}


