import { red, green, grey } from '@mui/material/colors'

export const themeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Quicksand',
          margin: 0,
          padding: 0
        },
        fieldset: {
          color: grey[600],
          border: '1px solid',
          borderColor: grey[300],
          borderRadius: '4px 4px 4px 4px',
          padding: '15px'
        }
      }
    }
  },
  palette: {
    withinRange: { main: green[500] },
    outOfRange: { main: red[500] },
    greyedOut: { main: grey[400] },
    action: {
      disabledBackground: grey[300],
      disabled: grey[600]
    },
    success: {
      light: green[400],
      main: green[500],
      dark: green[800],
      contrastText: grey[100]
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    bodyBold: {
      fontFamily: 'Quicksand',
      fontWeight: 600
    },
    bodyBoldHighLight: {
      fontFamily: 'Quicksand',
      fontWeight: 600
    },
    gpsNavBearingBig: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '32px'
    },
    gpsNavBearingSmall: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '18px'
    },
    gpsNavDistanceBig: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '26px',
    },
    gpsNavDistanceSmall: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '24px',
    },
    taskTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
    },
    h1: {
      fontFamily: 'Montserrat',
      fontSize: '4rem'
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '3rem'

    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '2rem'

    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '1.5rem'

    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: '1.2rem'
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 400
    },
  }
};