import { getDb } from "../localDb";

export default async function permanentlyDeleteEvent(eventData) {
  try {
    const db = await getDb();
    const eventsOnSameFarm = await db.events
      .where({ farmId: eventData.farmId })
      .and((event) => event.id !== eventData.id)
      .toArray();
    await db.transaction(
      "rw",
      [
        "events",
        "activities",
        "tasks",
        "pictures",
        "sites",
        "farms",
        "samplingAreas",
        "paddocks",
        "changelog"
      ],
      async () => {
        await db.activities.where({ monitoringEventId: eventData.id }).delete();
        await db.tasks.where({ monitoringEventId: eventData.id }).delete();
        await db.pictures.where({ monitoringEventId: eventData.id }).delete();
        if (!eventsOnSameFarm.length) {
          await db.sites.where({ farmId: eventData.farmId }).delete();
          await db.samplingAreas.where({ farmId: eventData.farmId }).delete();
          await db.paddocks.where({ farmId: eventData.farmId }).delete();
          await db.farms.where({ id: eventData.farmId }).delete();
        }
        await db.changelog.where({monitoringEventId: eventData.id}).delete();
        await db.events.delete(eventData.id);
      }
    );
  } catch (error) {
    throw new Error(error.message);
  }
}
