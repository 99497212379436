import download from 'downloadjs';

export default async function downloadBackup({backup}) {
    try {
        const fileData = await backup.handle.getFile();
        download(fileData, fileData.name);
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to backup current data: ${error.message}`);
    }
}