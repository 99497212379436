import * as localDb from '../localDb'
import { v4 as UUIDV4 } from 'uuid';

export async function saveRelativeGrid ({position, activity, setActivity, task, tempGrid, userName, taskComplete}) {
    try {
        //let gridGeoJson = generateUtmGrid(position, activity.activityLayoutId);
        setActivity({...activity, activityGrid: tempGrid});
        await localDb.update('activities', activity.id, { activityGrid: tempGrid}, true);

        //Recorre los puntos de la grilla y los asigna a las Task ya existentes en la DB.
        let tasks = await localDb.getMany('tasks').then(result => result.where({monitoringActivityId: activity.id}).toArray());
        await Promise.all(tasks.map( async (task) => {
            try {
                let [currentFeature] = tempGrid.features.filter(( feature ) => {
                    return  feature.properties.key === task.gridPositionKey}
                );
                if (currentFeature) {
                    await localDb.update('tasks', task.id, {plannedLocation: [ currentFeature.geometry.coordinates[1],  currentFeature.geometry.coordinates[0]]}, true);
                } 
            } catch (e) {
                throw new Error(`Error updating tasks with location ${e.message}`);
            }
        }));
        
        //FALTA PLANNED LOCATION, CUANDO NO HAY POSITION PINCHA
        let taskUpdate = {
            _rev: UUIDV4(),
            actualLocation: task.actualLocation ? task.actualLocation : [position.lat, position.lng],
            taskStatusId: 2,
            updatedAt: new Date(),
            updatedBy: userName
        }
        taskComplete({data: taskUpdate, pictures: []});
    } catch (error) {
        console.error(error);
        throw new Error (`Unable to perform task updates: ${error.message}`);
    }
}