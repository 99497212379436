import { v4 as uuid } from 'uuid';
import moment from 'moment';
import * as localDb from '../localDb';

export default async function checkActivityComplete(monitoringActivityId, currentUser) {
    try {
        let activityTasks = await localDb.getMany('tasks').then(result => result.where({ monitoringActivityId: monitoringActivityId }).toArray());
        let totalTasks = activityTasks.length;
        let completedTasks = activityTasks.filter(task => (task.taskStatusId === 2 || task.taskStatusId === 4)).length;

        let activity = await localDb.getOne('activities', monitoringActivityId);

        if (totalTasks !== activity.totalTasks) {
            await localDb.update('activities', activity.id, {totalTasks: totalTasks}, true);
        }

        if (totalTasks === completedTasks) {
            let update = {
                totalTasks: totalTasks,
                completedTasks: completedTasks,
                updatedAt: moment.utc().format(),
                updatedBy: currentUser,
                taskStatusId: 2,
                _rev: uuid()
            }
            return await localDb.update('activities', activity.id, update, true);
        }
        if (completedTasks) {
            let update = {
                totalTasks: totalTasks,
                completedTasks: completedTasks,
                updatedAt: moment.utc().format(),
                updatedBy: currentUser,
                taskStatusId: 1,
                _rev: uuid()
            }
            return await localDb.update('activities', activity.id, update, true);
        }

        return await localDb.update('activities', activity.id, { _rev: uuid() }, true);

    } catch (error) {
        console.error(`Unable to check activity completion: ${error.message}`);
        throw new Error(`Unable to check activity completion: ${error.message}`)
    }
}