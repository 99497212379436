export default async function checkCreateWritableSupported ({stateSetter}) {
    // Get handle to draft file in OPFS
    if (!navigator.storage) {
        return stateSetter({
            storage: false,
            createWritable: false
        })
    }
    const root = await navigator.storage.getDirectory();        
    const testHandle = await root.getFileHandle('test_access_file', { create: true });

    if (!testHandle.createWritable) {
        return stateSetter({
            storage: true,
            createWritable: false
        })
    }

    return stateSetter({
        storage: true,
        createWritable: true
    })

}