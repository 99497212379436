//REACT
import { useContext } from 'react'

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

//Components
import { PhotoInput } from '../../PhotoInput/PhotoInput.jsx';
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';

export default function TaskForm({
    geolocation, 
    position, 
    handleCameraInput, 
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    pictures,
}) {
    const { task } = useContext(TaskContext);
    const { control, setValue } = useFormContext();

    return (

            <Stack spacing={2}>
                <Controller
                    control={control}
                    name={'id'}
                    render={({ field }) => (
                            <input
                                type={'hidden'}
                                {...field}
                                id={'id'}
                            />
                    )}
                />
                <Controller
                    control={control}
                    name="siteName"
                    defaultValue={task.key}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            disabled
                            error={fieldState.error ? true : false}
                            fullWidth
                            name="siteName"
                            label="Nombre del sitio"
                        />
                    )}
                />
                <CoordinatesInputField
                    control={control}
                    editMode={editMode} 
                    setValue={setValue}
                    position={position}
                    plannedLocation={plannedLocation}
                    watch={watchPosition}
                    stopWatch={stopWatch}
                    geolocation={geolocation}
                />
                <PhotoInput
                    width='150'
                    height='150'
                    handleCameraFile={handleCameraInput}
                    picture={pictures.find(pic => pic.key === task.key)}
                    pictureKey={pictures.find(pic => pic.key === task.key)?.key || task.key}
                    pictureIndex={pictures.findIndex(pic => pic.key === task.key) >= 0 ? pictures.findIndex(pic => pic.key === task.key) : 0}
                    pictureId={pictures.find(pic => pic.key === task.key)?.id || null}
                />
            </Stack>
    )
}