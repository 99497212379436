import axios from 'axios';
import auth0Client from '../auth0/authClient.js'

export default async function updateApiData (endpoint, payload) {
    try {
        let authData = await auth0Client.getTokenSilently({ detailedResponse: true, ignoreCache: false, authorizeTimeoutInSeconds:30 }).catch(e => console.error(e));

        let options = {
            url: process.env.REACT_APP_RUUTS_API_URL + endpoint,
            method: 'PUT',
            data: payload,
            headers: {
                Authorization: `Bearer ${authData.access_token}`
            }
        }
        let response = await axios.request(options);
        return response.data;
    } catch (error) {
        console.error(error);
        console.error(`Unable to update API data for ${endpoint}. Probably offline. Data will sync when online`);
        throw new Error(`Unable to update data from ${endpoint}: ${error.message}`)
    }
}