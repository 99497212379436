export const v1 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite',
    events: '&id, name, farmId, assignedTo',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId',
    tasks: '&id, key, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
};
export const v2 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite',
    events: '&id, name, farmId, assignedTo',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId',
    tasks: '&id, key, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
    programConfigs: 'id&, monitoringEventId'
};
export const v3 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite',
    events: '&id, name, farmId, assignedTo',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId, [monitoringWorkflowId+monitoringEventId]',
    tasks: '&id, key, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
    programConfigs: 'id&, monitoringEventId'
};
export const v4 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite, name',
    events: '&id, name, farmId, assignedTo',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId, [monitoringWorkflowId+monitoringEventId]',
    tasks: '&id, key, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
    programConfigs: 'id&, monitoringEventId'
};
export const v5 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite, name',
    events: '&id, name, farmId, assignedTo',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId, [monitoringWorkflowId+monitoringEventId]',
    tasks: '&id, key, taskStatusId, type, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
    programConfigs: 'id&, monitoringEventId',
    settings: '&id, configuration',
    paddocks: '&id, farmId'
};
export const v6 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite, name',
    events: '&id, name, farmId, assignedTo',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId, [monitoringWorkflowId+monitoringEventId]',
    tasks: '&id, key, taskStatusId, type, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type],[monitoringEventId+formName]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
    programConfigs: 'id&, monitoringEventId',
    settings: '&id, configuration',
    paddocks: '&id, farmId'
};
export const v7 = {
    farms: '&id',
    samplingAreas: '&id, farmId',
    sites: '&id, samplingAreaId, farmId, isRandomSite, name',
    events: '&id, name, farmId, assignedTo, isDeleted, [farmId+isDeleted]',
    activities: '&id, key, monitoringEventId, [monitoringWorkflowId+samplingAreaId+monitoringEventId], monitoringSiteId, [monitoringWorkflowId+monitoringEventId]',
    tasks: '&id, key, taskStatusId, type, monitoringActivityId, [key+monitoringActivityId], plannedLocation, actualLocation, [monitoringSiteId+type], monitoringEventId,[monitoringActivityId+gridPositionKey],[monitoringEventId+monitoringSiteId+type],[monitoringEventId+formName]',
    species: '&id, scientificName, commonName',
    changelog: '&id, monitoringEventId, entityId, syncStatus, [monitoringEventId+syncStatus], timestamp',
    pictures: '&id, monitoringEventId, entity, entityId, [entity+entityId]',
    programConfigs: 'id&, monitoringEventId',
    settings: '&id, configuration',
    paddocks: '&id, farmId'
}