import { useState, useEffect, useContext } from "react";

//Context
import AppContext from "../../context/appContext.js";

//Hook Form
import { useForm, Controller } from "react-hook-form";

//MUI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Drawer, InputLabel, MenuItem, Select, Chip, Box, FormControl, FormHelperText, ButtonGroup } from "@mui/material";

//services
import * as localDb from "../../services/localDb";
import DrawerHeaderBar from "../DrawerHeaderBar/DrawerHeaderBar.jsx";
import generateActivity from "../../services/monitoring/generateActivity.js";
import refDataByKey from "../../services/localRefData/refDataByKey.js";
import NumericInput from "./utils/NumericInput.jsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

function CreateNewActivity({ show, handleClose, sites, samplingAreas, event }) {
    const { currentUser, setNotify, setAlert, currentProgramConfig, currentEvent } = useContext(AppContext);
    const [savingData, setSavingData] = useState(false);
    const [filteredSites, setFilteredSites] = useState(sites);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        criteriaMode: "onChange",
        shouldFocusError: false,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        defaultValues: {
            workflowId: "",
            samplingAreaId: "",
            monitoringSites: [],
            monitoringEventId: event.id,
        },
    });
    const samplingAreaId = watch("samplingAreaId");
    const workflowId = watch("workflowId");

    const handleTaskComplete = async (data) => {
        setSavingData(true);
        try {
            if (selectedWorkflow.siteRequired) {
                await Promise.all(
                    data.monitoringSites.map(async (site) => {
                        return generateActivity(currentUser.userName, site, event.id, currentEvent.farmId, data.workflowId, data.samplingAreaId === 99 ? null : data.samplingAreaId);
                    })
                );
            } else {
                await generateActivity(currentUser.userName, null, event.id, currentEvent.farmId, data.workflowId, null, { amountOfSites: data.amountOfSites });
            }
            setSavingData(false);
            setAlert({
                show: true,
                severity: "success",
                message: "Actividad generada con éxito",
                action: () => closeForm(),
                actionButtonTitle: "ACEPTAR",
            });
        } catch (error) {
            console.error(error);
            setSavingData(false);
            setNotify({
                show: true,
                message: `No se pudo generar actividad: ${error.message}`,
                severity: "error",
            });
        }
    };
    const closeForm = () => {
        reset();
        handleClose();
    };

    useEffect(() => {
        async function handleAreaChange(samplingAreaId) {
            setValue("monitoringSites", []);
            let saId = samplingAreaId === 99 ? null : samplingAreaId;
            if (selectedWorkflow.siteRequired) {
                let activities = await localDb.getMany("activities").then((result) =>
                    result
                        .where({
                            monitoringWorkflowId: getValues("workflowId"),
                            monitoringEventId: event.id,
                        })
                        .toArray()
                );
                let sitesWithActivityWorkflow = activities.map((activity) => activity.monitoringSiteId);
                let fSites = sites.filter((site) => !sitesWithActivityWorkflow.includes(site.id) && site.samplingAreaId === saId);
                setFilteredSites(fSites);
                if (!fSites.length) {
                    setAlert({
                        show: true,
                        severity: "warning",
                        message: "No hay sitios disponibles en este estrato para la actividad elegida.",
                    });
                }
            }
        }
        if (show && samplingAreaId) handleAreaChange(samplingAreaId);
    }, [samplingAreaId, selectedWorkflow, event.id, sites, getValues, setValue, setAlert, show]);

    useEffect(() => {
        if (show && workflowId != null) {
            let [workflow] = refDataByKey("workflows").filter((workflow) => workflow.id === workflowId);
            setSelectedWorkflow(workflow);
        }
    }, [workflowId, show]);

    useEffect(() => {
        if (selectedWorkflow) {
            if (!selectedWorkflow.siteRequired) {
                setValue("samplingAreaId", 99);
            } else {
                setValue("samplingAreaId", "");
            }
            setValue("monitoringSites", []);
        }
    }, [selectedWorkflow, setValue, show]);

    return (
        <Drawer
            PaperProps={{
                sx: { borderRadius: "10px 10px 0 0", height: "100vh", maxWidth: "100vw" },
            }}
            anchor='bottom'
            open={show}
            onClose={() => closeForm()}
        >
            <DrawerHeaderBar title={"NUEVA ACTIVIDAD"} handleClose={handleClose} />
            <Stack
                component='form'
                onSubmit={handleSubmit(handleTaskComplete)}
                m={2}
                spacing={2}
                height={"100%"}
                display={"flex"}
                flexGrow={1}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Stack width={"100%"} spacing={2}>
                    <Controller
                        control={control}
                        name='workflowId'
                        shouldUnregister={true}
                        defaultValue={""}
                        rules={{required: 'Campo requerido'}}
                        render={({ field, fieldState }) => (
                            <FormControl>
                                <InputLabel>Actividad</InputLabel>
                                <Select
                                    error={fieldState.error ? true: false}
                                    sx={{ textOverflow: "ellipsis" }}
                                    name='workflowId'
                                    label='Actividad'
                                    {...field}
                                    labelId={`workflowId-label`}
                                    onChange={(_, change) => {
                                        _.preventDefault();
                                        field.onChange(change.props.value);
                                    }}
                                >
                                    {refDataByKey("workflows")
                                        .filter((workflow) => currentProgramConfig?.monitoringWorkflowIds.includes(workflow.id))
                                        .map((option, index) => {
                                            return (
                                                <MenuItem key={index} value={option.id}>
                                                    {option.name} - {option.description}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                <FormHelperText>{fieldState.error ? fieldState.error.message : 'Seleccionar tipo de activdad'}</FormHelperText>
                            </FormControl>
                            
                        )}
                    />
                    {watch("workflowId") === 4 && (
                        <Controller
                            control={control}
                            name='amountOfSites'
                            rules={{
                                required: selectedWorkflow?.siteRequired ? "Campo requerido" : false,
                                min: 4,
                                validate: (value) => /^\d+$/.test(value) //only integer
                            }}
                            defaultValue={4}
                            render={({ field, fieldState }) => (
                                <FormControl>
                                    <NumericInput 
                                        field={field}
                                        fieldState={fieldState}
                                        label={'Cantidad de sitios'}
                                        min={4}
                                        helperMessage={'Ingrese la cantidad de sitios de validación'}
                                    />
                                </FormControl>
                            )}
                        />
                    )}

                    {watch("workflowId") !== 4 && (
                        <Controller
                            control={control}
                            name='samplingAreaId'
                            rules={{
                                required: selectedWorkflow?.siteRequired ? "Campo requerido" : false,
                            }}
                            defaultValue={""}
                            render={({ field, fieldState }) => (
                                <FormControl>
                                    <InputLabel htmlFor={"samplingArea"}>Estrato</InputLabel>
                                    <Select
                                        label={'Estrato'}
                                        error={fieldState.error ? true: false}
                                        name='samplingAreaId'
                                        labelId={`samplingAreaId-label`}
                                        {...field}
                                        fullWidth
                                        onChange={(_, change) => {
                                            _.preventDefault();
                                            field.onChange(change.props.value);
                                        }}
                                    >
                                        {[...samplingAreas, { id: 99, name: "Sin estrato" }].map((area, index) => {
                                            return (
                                                <MenuItem key={index} value={area.id}>
                                                    {area.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText>{fieldState.error ? fieldState.error.message : 'Seleccionar estrato' }</FormHelperText>
                                </FormControl>
                            )}
                        />
                    )}
                    {watch("workflowId") !== 4 && (
                        <Controller
                            control={control}
                            name='monitoringSites'
                            defaultValue={""}
                            rules={{
                                required: selectedWorkflow?.siteRequired ? "Campo requerido" : false,
                            }}
                            render={({ field, fieldState }) => (
                                <FormControl>
                                    <InputLabel>Sitio</InputLabel>
                                    <Select
                                        {...field}
                                        error={fieldState.error ? true: false}
                                        labelId='monitoringSites-label'
                                        label={'Sitio'}
                                        multiple
                                        onChange={(_, change) => {
                                            _.preventDefault();
                                            let newVal;
                                            if (field.value.length && field.value.map((val) => val.id).some((val) => val === change.props.value.id)) {
                                                newVal = field.value.filter((s) => s.id !== change.props.value.id);
                                                return field.onChange(newVal);
                                            } else {
                                                newVal = [...field.value, change.props.value];
                                                return field.onChange(newVal);
                                            }
                                        }}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value.id} label={value.name} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        fullWidth
                                    >
                                        {filteredSites.map((site) => (
                                            <MenuItem key={site.id} value={site}>
                                                {site.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldState.error ? fieldState.error.message : 'Seleccionar sitio' }</FormHelperText>
                                </FormControl>
                            )}
                        />
                    )}
                </Stack>
            </Stack>
            <Stack
                    sx={{ boxShadow: 3 }}
                    backgroundColor='white'
                    flex
                    p={2}
                    pb={3.5}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent='center'
                    flexWrap={"nowrap"}
                    justifySelf={"end"}
                    position={"sticky"}
                    bottom='0'
                    flexGrow={0}
                    zIndex={999}
                    width={'100vw'}
                >
                    <ButtonGroup fullWidth variant='contained' size='large'>
                        <Button disabled={savingData} onClick={handleSubmit(handleTaskComplete)} type='submit' variant='contained' color='primary'>
                            GUARDAR
                        </Button>
                        <Button variant='outlined' color='secondary' onClick={() => closeForm()}>
                            CANCELAR
                        </Button>
                    </ButtonGroup>
                </Stack>    
        </Drawer>
    );
}

export default CreateNewActivity;
