//REACT
import { useEffect, useState } from 'react';

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Box, Divider } from '@mui/material';
import NumberInputStepper from '../../NumberInputStepper/NumberInputStepper.jsx'

//ICONS
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';

const fields = [
    {
        label: 'Abundacia canopeo',
        name: 'liveCanopyAbundance',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Organismos vivos',
        name: 'livingOrganisms',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Pastos de verano',
        name: 'warmSeasonGrasses',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Pastos de invierno',
        name: 'coolSeasonGrasses',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Hierbas y leguminosas',
        name: 'forbsAndLegumes',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Árboles y arbustos',
        name: 'treesAndShrubs',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Especies raras',
        name: 'desirableRareSpecies',
        min: 0,
        max: 10,
        default: 0,
        lastValue: 0,
        enabled: false
    },
    {
        label: 'Especies indeseables',
        name: 'nonDesirableRareSpecies',
        min: -10,
        max: 0,
        default: 0,
        enabled: false
    },
    {
        label: 'Mantillo',
        name: 'litterAbundance',
        min: 0,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Incorp. Mantillo',
        name: 'litterDecomposition',
        min: 0,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Desap. Bosta',
        name: 'dungDecomposition',
        min: 0,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Suelo desnudo',
        name: 'bareSoil',
        min: -20,
        max: 20,
        default: 0,
        enabled: false
    },
    {
        label: 'Encostramiento',
        name: 'capping',
        min: -10,
        max: 0,
        default: 0,
        enabled: false
    },
    {
        label: 'Erosion eólica',
        name: 'windErosion',
        min: -20,
        max: 0,
        default: 0,
        enabled: false
    },
    {
        label: 'Erosion hídrica',
        name: 'waterErosion',
        min: -20,
        max: 0,
        default: 0,
        enabled: false
    }
]

export default function TaskForm({
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    position,
    geolocation

}) {
    const { control, watch, setValue } = useFormContext();
    const [ehi, setEhi] = useState(0);
    const iseValues = watch(fields.map(f => f.name))

    useEffect(() => {
        let totalIse = iseValues.reduce((acc, value) => {
            acc += value || 0;
            return acc;
        }, 0);
        setValue('totalIse', totalIse);
        setEhi(totalIse);
    }, [iseValues, setValue]);

    useEffect(() => {
        if (!editMode) {
            fields.forEach((field) => {
                setValue(field.name, field.default);
                setValue(`${field.name}-check`, field.enabled);
            }
            )
        }
    }, [editMode, setValue])

    return (
        <Stack width={'100%'}>
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Controller
                control={control}
                name='totalIse'
                render={(field, fieldSate) =>
                    <Stack
                        backgroundColor={'white'}
                        position='sticky'
                        top={40}
                        zIndex={999}
                        pb={3}
                    >
                        <Typography alignSelf='center' variant='h1' fontWeight={600}>{ehi}</Typography>
                        <Typography alignSelf='center' variant='caption'>PUNTAJE TOTAL</Typography>
                    </Stack>}
            />
            <Divider />

            {fields?.map((fieldConfig, index) => {
                return (
                    <Stack width={'100%'} spacing={1} pt={1} key={index}>
                        <Stack  width={'100%'} flex flexGrow={1} direction='row' alignItems='start' justifyContent='space-between' spacing={1} key={index}>
                            <Stack width={'50%'} m={0} direction='row' alignItems={'center'} spacing={0.5}>
                                <Controller
                                    control={control}
                                    name={`${fieldConfig.name}-check`}
                                    defaultValue={fieldConfig.enabled}
                                    render={({ field }) =>
                                        <Checkbox
                                            checked={field.value}
                                            sx={{ pl: 0 }}
                                            {...field}
                                            onChange={(_, value) => {
                                                field.onChange(value);
                                                if (value === false) {
                                                    setValue(fieldConfig.name, 0)
                                                }
                                            }}
                                        />}
                                />
                                <Typography sx={{overflow: 'hidden', textOverflow:'ellipsis', whiteSpace:'break-spaces'}} component='div' variant='caption'>{fieldConfig.label.toUpperCase()} </Typography>
                            </Stack>
                            <Box >
                                <Controller
                                    control={control}
                                    name={fieldConfig.name}
                                    rules={{ disabled: !watch(`${fieldConfig.name}-check`) }}
                                    defaultValue={fieldConfig.default}
                                    render={({ field }) => (
                                        <Stack direction='column' spacing={1} alignItems='center'>
                                            <NumberInputStepper
                                                {...field}
                                                disabled={!watch(`${fieldConfig.name}-check`)}
                                                readOnly={true}
                                                min={fieldConfig.min}
                                                max={fieldConfig.max}
                                                name={fieldConfig.name}
                                                step={(fieldConfig.min === -20 || fieldConfig.max === 20) ? 10 : 5}
                                                onChange={(evt, value) => {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    field.onChange(value);
                                                }}
                                            />
                                            <Typography variant='caption'>{fieldConfig.min}/{fieldConfig.max}</Typography>
                                        </Stack>
                                    )}
                                />
                            </Box>
                        </Stack>
                        <Divider />
                    </Stack>
                )
            })}
        </Stack>
    )
}