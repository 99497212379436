//React 
import { useState, useEffect, useCallback, useRef, useContext, useLayoutEffect } from 'react';

import * as localDb from '../../services/localDb';
import AppContext from '../../context/appContext';

//Leaflet
import L from 'leaflet';
import "leaflet.offline";
import "leaflet-rotatedmarker";
import 'leaflet/dist/leaflet.css'
import { MapContainer } from 'react-leaflet/MapContainer';
import { Popup } from 'react-leaflet/Popup';
import { Marker } from 'react-leaflet/Marker';
import { LayerGroup } from 'react-leaflet/LayerGroup';
import { Pane } from 'react-leaflet/Pane';
import { GeoJSON } from 'react-leaflet/GeoJSON';
import { Polyline } from 'react-leaflet/Polyline'
import { initialTask, pendingTask, currentLocation } from '../../assets/leafletIcons';

//Turf
import { helpers } from "@turf/turf";

//MUI
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Zoom from '@mui/material/Zoom';


//Icons
import LocationOn from '@mui/icons-material/MyLocation';
import LocationOff from '@mui/icons-material/GpsNotFixed';
import { ReactComponent as Relocate } from '../../assets/relocate.svg'; 
import getCentroid from '../../services/geolocationScripts/getCentroid';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

//Consts for MapContainer Config
const zoom = 15;
const maxZoom = 21;
const maxNativeZoom = 19;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function Navigator({
  position,
  destination,
  heading,
  destinationName,
  stopWatch,
  watch,
  activityGrid,
  getBackupPoint,
  randomizeArea,
  backupDestination,
  handleCancel,
  allowShuffle,
  taskKey,
  withinRange,
  editMode,
  pointShuffled,
  tempGrid,
  perimeter,
  geolocation,
  rangeUnlocked,
  setRangeUnlocked,
  allowRangeOverride
}) {
  const { windowHeight, windowWidth } = useContext(AppContext);
  const [map, setMap] = useState();
  const [watching, setWatching] = useState(true);
  const [gridPreview, setGridPreview] = useState([]);
  const [firstLocation, setFirstLocation] = useState(true);
  const [showOnLocation, setShowOnLocation] = useState(false);
  const [samplingAreas, setSamplingAreas] = useState();
  const [paddocks, setPaddocks] = useState();
  const positionMarker = useRef();

  const toggleWatch = () => {
    if (watching) {
      setWatching(false);
      stopWatch();
      setShowOnLocation(true);
      setTimeout(() => setShowOnLocation(false), 2000);
    }
    else {
      setFirstLocation(true);
      setWatching(true);
      setShowOnLocation(true);
      watch();
      setTimeout(() => setShowOnLocation(false), 2000);
    }
  }

  /*const toggleRangeLock = () => {
    if (rangeUnlocked) {
      setRangeUnlocked(false);
    } else {
      setAlert({
        show: true,
        severity: 'warning',
        title: 'Deshabilitar rango',
        message: 'Está deshabilitando el rango de acción estipulado para esta tarea. ¿Desea continuar?',
        action: () => {
          stopWatch();
          setRangeUnlocked(true)
        } ,
        actionButtonTitle: 'CONTINUAR',
        //cancelAction: setRangeUnlocked(false),
        cancelButtonTitle: 'CANCELAR'
      })
    }
  }*/

  const handleStyle = (feature) => {
    return {
      fillColor: feature.properties.color,
      color: feature.properties.color,
      weight: 2,
      opacity: 1,
      fillOpacity: 0,
    }
  }
  const handlePaddockStyle = (feature) => {
    return {
      fillColor: "",
      color: '#0F0F0F',
      weight: 2,
      opacity: 1,
      fillOpacity: 0,
    }
  }
  const handlePerimeterStyle = (feature) => {
    return {
      fillColor: "",
      color: '#0000FF',
      weight: 2,
      opacity: 1,
      fillOpacity: 0,
    }
  }


  const setupGridLayer = (feature, layer) => {
    layer.bindPopup(feature.properties.key);
  }
  const setupMakers = (feature, latlng) => {
    if (feature.properties.key === taskKey) {
      return L.marker(latlng, { icon: initialTask });
    }
    return L.marker(latlng, { icon: pendingTask });
  }

  const setupMakersComponents = (f, i) => {
    if (f.properties.key === taskKey) {
      return (
        <Marker
          key={i}
          position={[f.geometry.coordinates[1], f.geometry.coordinates[0]]}
          icon={initialTask}
        />)
    }
    return (
      <Marker
        key={i}
        position={[f.geometry.coordinates[1], f.geometry.coordinates[0]]}
        icon={pendingTask}
      />)
  }



  const handleNewPoint = () => {
    //setWatching(false);
    //stopWatch();
    getBackupPoint();
  }

  const getSamplingAreas = useCallback(async () => {
    let eventId = localStorage.getItem('currentEventId');
    let event = await localDb.getOne('events', eventId);
    let sampAreas = await localDb.getMany('samplingAreas').then(result => result.where({ farmId: event.farmId }).toArray())
    let featureCollection = helpers.featureCollection(sampAreas.map(area => area.toGeoJSON));
    setSamplingAreas(featureCollection)
  }, []);

  const getPaddocks = useCallback(async () => {
    let eventId = localStorage.getItem('currentEventId');
    let event = await localDb.getOne('events', eventId);
    let paddocks = await localDb.getMany('paddocks').then(result => result.where({ farmId: event.farmId }).toArray())
    let featureCollection = helpers.featureCollection(paddocks.map(area => area.toGeoJSON));
    setPaddocks(featureCollection)
  }, []);


  useLayoutEffect(() => {
    if (!positionMarker.current && map && position) {
      positionMarker.current = new L.marker(position, {
        rotationAngle: heading,
        rotationOrigin: "center center",
        icon: currentLocation,
        zIndexOffset: 601
      }).addTo(map)
    }
  }, [map, position, heading, destination, watching, firstLocation]);

  useLayoutEffect(() => {
    positionMarker.current?.setRotationAngle(heading ?? positionMarker.current);
  }, [heading]);

  useLayoutEffect(() => {
    if (map && watching && position) {
      positionMarker.current?.setLatLng(position);
      if (firstLocation && !editMode) {
        map.panTo(position, 15);
        setFirstLocation(false);
      }
    }
  }, [position, map, firstLocation, watching, editMode])

  useEffect(() => {
    if (map) {
      L.tileLayer.offline("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        maxZoom: maxZoom + 1,
        maxNativeZoom: { maxNativeZoom },
        zoom: zoom
      }).addTo(map);
    }
  }, [map]);

  useEffect(() => {
    getSamplingAreas();
    getPaddocks();
  }, [map, getSamplingAreas, getPaddocks]);

  useLayoutEffect(() => {
    setGridPreview([]);
    if (tempGrid) {
      let gridFeatures = tempGrid.features.map(f => f)
      setGridPreview(gridFeatures)
    }
  }, [tempGrid])

  useLayoutEffect(() => {
    if (map && tempGrid) {
      map.flyToBounds(L.geoJSON(tempGrid).getBounds(), { padding: [50, 20], maxZoom: 19 })
    }
  }, [tempGrid, map]);

  useLayoutEffect(() => {
    if (map && activityGrid) {
      map.flyToBounds(L.geoJSON(activityGrid).getBounds(), { padding: [50, 20], maxZoom: 19 })
    }
  }, [activityGrid, map])

  useLayoutEffect(() => {
    if (map) map.invalidateSize(false)
  }, [windowHeight, windowWidth, map])

  useEffect(() => {
    if (map) {
      map.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
      });
    }
  }, [map])

  useEffect(() => setWatching(geolocation), [geolocation])

  return (
    <Stack id="map-cc" sx={{ width: '100vw', height: '100%', overflow: 'hidden' }}>
      <MapContainer
        style={{ height: 'calc(100%)', width: '100vw' }}
        center={destination || getCentroid(perimeter)}
        zoom={zoom}
        maxZoom={maxZoom}
        maxNativeZoom={maxNativeZoom}
        zoomDelta={2}
        zoomSnap={1}
        scrollWheelZoom={false}
        ref={setMap}>
        <LayerGroup>
          {gridPreview?.length > 0 && gridPreview.map((f, i) => setupMakersComponents(f, i))
          }
          { destination &&
            <Marker
              title={destinationName}
              position={destination}
              //icon={pendingTask}
            >
              <Popup>
                {destinationName}
              </Popup>
            </Marker>}
          {backupDestination &&
            <Marker
              title={'Nuevo Sitio'}
              position={backupDestination}
              icon={initialTask}
            >
              <Popup>
                'Nuevo Sitio'
              </Popup>
            </Marker>}
        </LayerGroup>
        <Pane style={{ zIndex: 450 }}>
          {position && destination &&
            <LayerGroup>
              <Polyline
                pathOptions={{ color: 'white', dashArray: 4 }}
                positions={[position, destination]}
              >
              </Polyline>
            </LayerGroup>
          }
        </Pane>
        {randomizeArea &&
          <GeoJSON
            data={randomizeArea}
          ></GeoJSON>}
        {activityGrid &&
          <GeoJSON
            data={activityGrid}
            pointToLayer={setupMakers}
            onEachFeature={setupGridLayer}
          ></GeoJSON>}
        {samplingAreas &&
          <Pane style={{ zIndex: 400 }}>
            <GeoJSON data={samplingAreas}
              style={handleStyle}
            ></GeoJSON>
          </Pane>
        }
        {paddocks &&
          <Pane style={{ zIndex: 400 }}>
            <GeoJSON data={paddocks}
              style={handlePaddockStyle}
            ></GeoJSON>
          </Pane>
        }
        {perimeter &&
          <Pane style={{ zIndex: 400 }}>
            <GeoJSON
              data={perimeter}
              style={handlePerimeterStyle}
            ></GeoJSON>
          </Pane>
        }

      </MapContainer>
      <Stack
        position='absolute'
        //top='30vh'
        pt={2}
        right='2.5vw'
        spacing={2}
        alignItems='flex-end'
      >

        {/*<Stack display={'flex'} alignItems={'center'} direction={'row'} spacing={1}>
          <Fab disabled={!allowRangeOverride || withinRange} color={rangeUnlocked ? 'warning' : 'primary'} size="medium" onClick={toggleRangeLock}>
            {rangeUnlocked ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
          </Fab>
      </Stack>*/}

        <Stack display={'flex'} alignItems={'center'} direction={'row'} spacing={1}>
          <Zoom in={showOnLocation}>
            <Fab variant='extended' color={watching ? 'success' : 'warning'} size="medium" onClick={handleCancel}>
              {watching ? 'LOCATION ON' : 'LOCATION OFF'}
            </Fab>
          </Zoom>
          <Fab color={watching ? 'primary' : 'greyedOut'} size="medium" onClick={toggleWatch}>
            {watching ? <LocationOn /> : <LocationOff />}
          </Fab>
        </Stack>

        <Stack display={'flex'} alignItems={'center'} direction={'row'} spacing={1}>
          <Fab disabled={!allowShuffle || withinRange } color='primary' size="medium" onClick={handleNewPoint}>
            {watching ? <Relocate /> : <Relocate />}
          </Fab>
        </Stack>
      </Stack>
    </Stack >
  )
}

export default Navigator;
