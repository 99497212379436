import { useEffect, useContext, useState } from 'react';
import SdStorageIcon from '@mui/icons-material/SdStorage';

import { getStorageUsageEstimate } from '../../services/helpers/getStorageUsageEstimate.js';
//Context
import AppContext from '../../context/appContext';
import {StorageInformationModal} from '../StorageInformationModal/StorageInformationModal.jsx';


export function StorageStatusIndicator() {
  const { storageData, setStorageData, setStorageFreeze } = useContext(AppContext);
  const [showModal, setShowModal] = useState({show: false});


  const handleClick = () => {
    if(!showModal.show) {
      if (!storageData) 
        return setShowModal({ severity: 'warning', show: true, message: `No se pudo obtener información de almacenamiento. Asegúrese de instalar las últimas actualizaciones del sistema operativo` });
      if (storageData.colorCode === 'error') 
        return setShowModal({ severity: 'error', show: true, message: `El espacio utilizado es de ${storageData.usagePct} %. Se encuentra por encima del lìmite recomendado. Libere espacio en su dispositivo para seguir trabajando. No borre los datos del navegador a menos que haya sincornizado todos los eventos con el servidor.` });
      if (storageData.colorCode === 'warning') 
        return setShowModal({ severity: 'warning', show: true, message: `El espacio utilizado es de ${storageData.usagePct} %. Se recomienda liberar espacio en su dispositivo.` })
      if (storageData.colorCode === 'primary') 
        return setShowModal({ severity: 'success', show: true, message: `Está utilizando ${storageData.usagePct}% del espacio disponible. El espacio disponible para la app puede ser inferior al espacio disponible del dispositivo.` })
    } else {
      setShowModal({show: false})
    }

  }
    useEffect(() => {
    async function getStorage() {
      let storageEstimate = await getStorageUsageEstimate();
      setStorageData(storageEstimate)
      if (storageEstimate) {
        setStorageFreeze(storageEstimate.usagePct >= storageEstimate.maxUsagePct);
      } else {
        setStorageFreeze(false);
      }
    }
    getStorage();
  }, [setStorageData, setStorageFreeze]);

  return (
    <>
      {<SdStorageIcon onClick={handleClick} color={storageData?.colorCode || 'primary'} />}
      {showModal && 
        <StorageInformationModal
          handleClose={() => setShowModal(false)} 
          show={showModal.show} 
          message={showModal.message} 
          severity={showModal.severity}
        />}
    </>
  )
}