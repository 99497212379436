import { point, nearestPointOnLine, lineString } from '@turf/turf';
import { LatLng } from 'leaflet';

// Function to find the closest point on a feature perimeter to the given input point
export default function findClosestPointOnPerimeter(inputPoint, feature) {
  const inputPointGeometry = point(inputPoint);
  const featureGeometry = feature.geometry;

  let closestPoint = null;
  let closestDistance = Infinity;

  // Check if the feature is a Polygon or MultiPolygon
  if (featureGeometry.type === 'Polygon') {
    const polygon = featureGeometry.coordinates;
    polygon.forEach(ring => {
      const ls = lineString([...ring, ring[0]]); // Close the loop
      const nearest = nearestPointOnLine(ls, inputPointGeometry);
      const distance = nearest.properties.dist;

      if (distance < closestDistance) {
        closestDistance = distance;
        closestPoint = nearest.geometry.coordinates;
      }
    });
  } else if (featureGeometry.type === 'MultiPolygon') {
    const multiPolygon = featureGeometry.coordinates;
    multiPolygon.forEach(polygon => {
      polygon.forEach(ring => {
        const ls = lineString([...ring, ring[0]]); // Close the loop
        const nearest = nearestPointOnLine(ls, inputPointGeometry);
        const distance = nearest.properties.dist;

        if (distance < closestDistance) {
          closestDistance = distance;
          closestPoint = nearest.geometry.coordinates;
        }
      });
    });
  }
  return new LatLng(closestPoint[1], closestPoint[0]);
}
