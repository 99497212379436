//REACT
import { useContext, useState, useEffect } from 'react'

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, FormHelperText, MenuItem, InputLabel, Select } from '@mui/material';


//Components
import { PhotoInput } from '../../PhotoInput/PhotoInput.jsx';
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';
import refDataByKey from '../../../services/localRefData/refDataByKey.js';

export default function TaskForm({
    geolocation,
    position,
    handleCameraInput,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    pictures,
}) {
    const { task } = useContext(TaskContext);
    const [species, setSpecies] = useState();
    const { setValue, control } = useFormContext();


    useEffect(() => {
        async function getSpecies() {
            let speciesAll = refDataByKey('species');
            setSpecies(speciesAll);
        }
        getSpecies();
    }, [task])

    return (
        species &&
        <Stack spacing={2}>

            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Controller
                control={control}
                name={`dominantSpeciesName`}
                defaultValue={''}
                rules={{ required: 'Campo requerido' }}
                render={({ field, fieldState }) => (
                    <FormControl variant='outlined' error={fieldState.error}>
                        <Autocomplete
                            {...field}
                            key={`dominantSpeciesName`}
                            autoSelect
                            disablePortal
                            freeSolo
                            options={species}
                            getOptionLabel={(option) => option.commonName || option}
                            onChange={(event, value) => {
                                field.onChange(value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Especie Dominante"
                                    error={fieldState.error ? true:false}
                                />}

                        />
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>

                )}
            />

            <Controller
                control={control}
                rules={{ required: 'Campo requerido' }}
                name={`secondarySpeciesName`}
                defaultValue={''}
                render={({ field, fieldState }) => (
                    <FormControl variant='outlined' error={fieldState.error}>
                        <Autocomplete
                            {...field}
                            disablePortal
                            freeSolo
                            autoSelect
                            options={species}
                            getOptionLabel={(option) => option.commonName || option}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={fieldState.error ? true:false}
                                    label="Especie Secundaria"
                                />}
                            onChange={(event, value) => {
                                field.onChange(value);
                            }}
                        />
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                )}
            />
            <Controller
                control={control}
                name="woodyCoverGreaterThan20Pct"
                rules={{ required: 'Campo requerido' }}
                render={({ field, fieldState }) => (
                    <FormControl variant='outlined' error={fieldState.error}>
                        <InputLabel id="woodyCover-select-label">Cobertura Leñosa mayor a 20%?</InputLabel>
                        <Select
                            {...field}
                            MenuProps={{ sx: { zIndex: 10000 } }}
                            label="woodyCoverGreaterThan20Pct"
                            fullWidth
                            labelId='woodyCover-select-label'
                            onChange={(_, change) => {
                                field.onChange(change.props.value);
                            }}
                            value={field.value}
                        >
                            <MenuItem value={1}>Si</MenuItem>
                            <MenuItem value={0}>No</MenuItem>

                        </Select>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                )}
            />
            <Controller
                control={control}
                name="bareGroundPct"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                    <NumericInput
                        field={field}
                        fieldState={fieldState}
                        label='Suelo desnudo'
                        min={0}
                        max={100}
                        unit={'%'}
                    />
                )}
            />
            <Controller
                control={control}
                name="litterPct"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                    <NumericInput
                        field={field}
                        fieldState={fieldState}
                        label='Mantillo'
                        min={0}
                        max={100}
                        unit={'%'}
                    />
                )}
            />
            <Controller
                control={control}
                name="livePlantPct"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                    <NumericInput
                        field={field}
                        fieldState={fieldState}
                        label='Cobertura vegetal viva'
                        min={0}
                        max={100}
                        unit={'%'}
                    />
                    )}
            />
            <Stack alignItems={'center'} spacing={1}>
                <Typography>Fotografía del sitio</Typography>
                <PhotoInput
                    width='150'
                    height='150'
                    handleCameraFile={handleCameraInput}
                    picture={pictures.find(pic => pic.key === task.key)}
                    pictureKey={pictures.find(pic => pic.key === task.key)?.key || task.key}
                    pictureIndex={pictures.findIndex(pic => pic.key === task.key) >= 0 ? pictures.findIndex(pic => pic.key === task.key) : 0}
                    pictureId={pictures.find(pic => pic.key === task.key)?.id || null}
                />
            </Stack>
        </Stack>
    )
}