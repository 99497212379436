import { useState, useEffect, useContext } from "react";

//Context
import AppContext from "../../context/appContext.js";

//Hook Form
import { useForm, Controller } from "react-hook-form";

//MUI
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Drawer, InputLabel, FormGroup, FormControlLabel, FormLabel, Checkbox, ButtonGroup } from "@mui/material";

//services
import * as localDb from '../../services/localDb'
import DrawerHeaderBar from '../DrawerHeaderBar/DrawerHeaderBar.jsx';
import OverviewMap from '../OverviewMap/OverviewMap.jsx';
import { v4 as UUIDV4 } from 'uuid';
import generateActivity from '../../services/monitoring/generateActivity.js';
import * as turf from '@turf/turf';
import refDataById from '../../services/localRefData/refDataById.js';
import { validateExistingSiteName } from '../../services/helpers/validateExistingSiteName.js';

function CreateNewSite({ show, handleClose, event, samplingAreas, sites }) {
    const { currentUser, currentProgramConfig, currentEvent } = useContext(AppContext);
    const { setNotify, setAlert } = useContext(AppContext);
    const [position, setPosition] = useState();
    const [savingData, setSavingData] = useState(false);
    const [currentSamplingAreaId, setCurrentSamplingAreaId] = useState(null);
    const [currentSamplingAreaName, setCurrentSamplingAreaName] = useState(null);
    const [workflowIds, setWorkflowIds] = useState([]);
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { isValid },
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        criteriaMode: "all",
        shouldFocusError: false,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        defaultValues: {
            plannedLocation: "",
            name: "",
            randomSite: false,
            samplingAreaId: "",
            samplingArea: "Ninguno",
            workflowIds: [],
        },
    });
    const handleTaskComplete = async (data) => {
        setSavingData(true);
        try {
            let newSiteId = UUIDV4();
            let newSiteData = {
                id: newSiteId,
                name: data.name,
                farmId: event.farmId,
                samplingAreaId: data.samplingAreaId || null,
                plannedLocation: data.plannedLocation,
                actualLocation: data.plannedLocation,
                _rev: UUIDV4(),
                isRandomSite: false,
            };
            await localDb.put("sites", newSiteId, newSiteData);

            for (let workflowId of data.workflowIds) {
                await generateActivity(currentUser.userName, newSiteData, event.id, currentEvent.farmId, workflowId, data.samplingAreaId || null);
            }
            setSavingData(false);
            setAlert({
                show: true,
                severity: "success",
                message: "Sitio generado con éxito",
                action: () => closeForm(),
                actionButtonTitle: "ACEPTAR",
            });
        } catch (error) {
            console.error(error);
            setSavingData(false);
            setNotify({
                show: true,
                message: `No se pudo generar sitio: ${error.message}`,
                severity: "error",
            });
        }
    };
    const closeForm = () => {
        reset();
        handleClose();
    };

    useEffect(() => {
        async function getSamplingArea() {
            let match = false;
            for (let sa of samplingAreas) {
                match = turf.booleanPointInPolygon([position.coords.longitude, position.coords.latitude], sa.toGeoJSON);
                if (match) {
                    setValue("samplingArea", sa.name);
                    setValue("samplingAreaId", sa.id);
                    setCurrentSamplingAreaId(sa.id);
                    setCurrentSamplingAreaName(sa.name);
                    break;
                } else {
                    setValue("samplingArea", "Ninguno");
                    setValue("samplingAreaId", "");
                }
            }
        }
        if (position) {
            setValue("plannedLocation", [position.coords.latitude, position.coords.longitude]);
            getSamplingArea();
        }
    }, [position, setValue, samplingAreas, sites]);

    useEffect(() => {
        if (currentSamplingAreaId) {
            let siteNumber = sites.filter((site) => site.samplingAreaId === currentSamplingAreaId).length;
            setValue("name", currentSamplingAreaName + "-" + (siteNumber + 1));
        }
    }, [currentSamplingAreaId, currentSamplingAreaName, setValue, sites]);

    useEffect(() => {
        if(currentProgramConfig) {
            const allowedWorkflowIds = [ 0, 1, 2, 3]
            setWorkflowIds(currentProgramConfig.monitoringWorkflowIds?.filter((option) => allowedWorkflowIds.includes(option)))
        }
    }, [workflowIds, currentProgramConfig])

    return (
        <Drawer PaperProps={{ sx: { borderRadius: "10px 10px 0 0", height: "100vh" } }} anchor='bottom' open={show} onClose={() => closeForm()}>
            <DrawerHeaderBar title={"NUEVO SITIO"} handleClose={handleClose} />
            <OverviewMap
                height={"270px"}
                width={"100%"}
                allowZoom={true}
                enableLocation={true}
                initialZoom={16}
                eventId={event.id}
                allowDonwload={false}
                flyToPosition={true}
                getCoords={setPosition}
            />
            <Stack
                component='form'
                onSubmit={handleSubmit(handleTaskComplete)}
                m={2}
                spacing={2}
                height={"100%"}
                display={"flex"}
                flexGrow={1}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Stack width={"100%"} spacing={1}>
                    <Controller
                        control={control}
                        name='samplingArea'
                        rules={{ required: "Campo requerido" }}
                        render={({ field, fieldState }) => (
                            <>
                                <InputLabel htmlFor={"samplingArea"}>
                                    <b>Estrato</b>
                                </InputLabel>
                                <TextField
                                    id='samplingArea'
                                    {...field}
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                    disabled
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                    }}
                                />
                            </>
                        )}
                    />
                    <Controller
                        control={control}
                        name='plannedLocation'
                        rules={{ required: "Campo requerido" }}
                        render={({ field, fieldState }) => (
                            <>
                                <InputLabel htmlFor={"plannedLocation"}>
                                    <b>Coordenadas</b>
                                </InputLabel>
                                <TextField
                                    {...field}
                                    id={"plannedLocation"}
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                    disabled
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                    }}
                                />
                            </>
                        )}
                    />
                    <Controller
                        control={control}
                        name='name'
                        rules={{
                            required: "Campo requerido",
                            validate: async (value) => await validateExistingSiteName(value, currentEvent.farmId),
                        }}
                        render={({ field, fieldState }) => (
                            <>
                                <InputLabel htmlFor={"name"}>
                                    <b>Nombre del sitio</b>
                                </InputLabel>
                                <TextField
                                    id={"name"}
                                    name='name'
                                    {...field}
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                    }}
                                />
                            </>
                        )}
                    />
                    <FormLabel id={"workflow"}>
                        <Typography variant='label'>
                            <b>Actividades</b>
                        </Typography>
                    </FormLabel>
                    <Controller
                        control={control}
                        name='workflowIds'
                        shouldUnregister={true}
                        defaultValue={[]}
                        render={({ field }) => (

                            <FormGroup
                                row={true}
                            >
                                {workflowIds.map((option, index) =>
                                    <FormControlLabel
                                        key={index}
                                        label={refDataById("workflows", option)}
                                        {...field}
                                        control={
                                            <Checkbox
                                                checked={field.value?.includes(option)}
                                                onChange={(evt, value) => {
                                                    if (!value.length) field.onChange([option]);
                                                    if (value) {
                                                        let fieldValue = field.value;
                                                        fieldValue.push(option);
                                                        field.onChange(fieldValue);
                                                    } else {
                                                        let fieldValue = field.value.filter((value) => value !== option);
                                                        field.onChange(fieldValue);
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </FormGroup>
                        )}
                    />
                </Stack>
            </Stack>
            <Stack
                    sx={{ boxShadow: 3 }}
                    backgroundColor='white'
                    flex
                    p={2}
                    pb={3.5}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent='center'
                    flexWrap={"nowrap"}
                    justifySelf={"end"}
                    position={"sticky"}
                    bottom='0'
                    flexGrow={0}
                    zIndex={999}
                >
                    <ButtonGroup fullWidth variant='contained' size='large'>
                        <Button disabled={savingData || !isValid} onClick={handleSubmit(handleTaskComplete)} variant='contained' color='primary'>
                            GUARDAR
                        </Button>
                        <Button variant='outlined' color='secondary' onClick={() => closeForm()}>
                            CANCELAR
                        </Button>
                    </ButtonGroup>
                </Stack>
        </Drawer>
    );
}

export default CreateNewSite;
