export function getConfig(env) {
  return ({
    local: {
      domain: "dev-3k15sordpab5eoud.us.auth0.com",
      clientId: "Yli8NgFBmZ6y0LJ8dkKWDma0YkGRfgik",
      audience: "https://api-dev.ruuts.la"
    },
    development: {
      domain: "dev-3k15sordpab5eoud.us.auth0.com",
      clientId: "Yli8NgFBmZ6y0LJ8dkKWDma0YkGRfgik",
      audience: "https://api-dev.ruuts.la"
    },
    staging: {
      domain: "dev-3k15sordpab5eoud.us.auth0.com",
      clientId: "Yli8NgFBmZ6y0LJ8dkKWDma0YkGRfgik",
      audience: "https://api-dev.ruuts.la"
    },
    production: {
      domain: "ruuts.us.auth0.com",
      clientId: "BTrpRWHhO0RYqN206eymwuHcuf6PHTNm",
      audience: "https://api.ruuts.la"
    },
  }[env]);
}