import { v4 as UUIDV4 } from 'uuid';
import * as localDb from '../../services/localDb/index.js';
import { latLngToArray } from '../geolocationScripts/latLngToArray.js';

export async function createValidationSite ({data, currentEvent, task}) {
        let newSiteData = {
            id: data.id || UUIDV4(),
            name: data.siteName,
            farmId: currentEvent.farmId,
            samplingAreaId: null,
            plannedLocation: latLngToArray(data.actualLocation),
            actualLocation: latLngToArray(data.actualLocation),
            deviceLocationData: data.deviceLocationData,
            _rev: UUIDV4(),
            isRandomSite: false,
            isValidationSite: true
        }
        let taskUpdate = {
            taskStatusId: 2,
            actualLocation: latLngToArray(data.actualLocation), 
            plannedLocation: latLngToArray(data.actualLocation),
            deviceLocationData: data.deviceLocationData,
        }
        let db = await localDb.getDb();
        await db.transaction('rw', ['tasks', 'sites', 'changelog'], async () => {
            await localDb.update('tasks', task.id, taskUpdate, true, db)
            await localDb.put('sites', newSiteData.id, newSiteData, true, db);
        })
}