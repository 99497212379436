import L from 'leaflet';
import redCricle from './redCircle.svg'
import yellowCircle from './yellowCircle.svg'
import greenCircle from './greenCircle.svg'
import navigation from './navigation.svg';
import greyCircle from './greyCircle.svg';
import blueCircle from './blueCircle.svg';

export const currentLocation = L.icon({
    iconUrl: navigation,
    iconRetinaUrl: navigation,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [35, 35],
    className: "leaflet-venue-icon",
  });
  
export const pendingTask = L.icon({
    iconUrl: yellowCircle,
    iconRetinaUrl: yellowCircle,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: "leaflet-venue-icon",
  });

  export const initialTask = L.icon({
    iconUrl: redCricle,
    iconRetinaUrl: redCricle,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: "leaflet-venue-icon",
  });

  export const completedTask = L.icon({
    iconUrl: greenCircle,
    iconRetinaUrl: greenCircle,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: "leaflet-venue-icon",
  });

  export const noTasks = L.icon({
    iconUrl: greyCircle,
    iconRetinaUrl: greyCircle,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: "leaflet-venue-icon",
  });

  export const validationSite = L.icon({
    iconUrl: blueCircle,
    iconRetinaUrl: blueCircle,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: "leaflet-venue-icon",
  });