//Turf
import { helpers } from "@turf/turf";
import * as localDb from '../localDb'
import refDataById from "../localRefData/refDataById";

export default async function buildSiteFeatureCollection(sites) {
    let features = await Promise.all(sites.map(site => {
        return new Promise(async (resolve, reject) => {
            let lng = site.actualLocation ? site.actualLocation[1] : site.plannedLocation[1];
            let lat = site.actualLocation ? site.actualLocation[0] : site.plannedLocation[0];
            let turfPosition = [lng, lat];
            let siteFeature = helpers.point(turfPosition, { id: site.name });
            let activities = await localDb.getMany('activities').then(result => result.where({monitoringSiteId: site.id}).toArray());
            let properties = activities.reduce((acc, curr) =>{
                acc[curr.key] = refDataById('taskStatus', curr.taskStatusId);;
                return acc
            }, { 
                hasActivities: activities && activities.length,
                isValidationSite: site.isValidationSite
            });
            siteFeature.properties = {...siteFeature.properties, ...properties}
            resolve(siteFeature);
        });
    }));
    let fc = helpers.featureCollection(features);
    return fc;
}