import { v4 as UUIDV4 } from 'uuid';

export default function stmTasks(site, userName, monitoringEventId, monitoringActivityId) {
  return (
    [
      {
        id: UUIDV4(),
        key: `${site.name.toUpperCase()}-CONFIRMAR-SITIO`,
        order: 0,
        enabled: true,
        actionRangeMts: 10,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        taskStatusId: site.locationConfirmed ? 2 : 0,
        formName: '',
        type: 'confirmSite',
        actualLocation: site.actualLocation || [],
        plannedLocation: site.plannedLocation,
        dependencies: [],
        description: 'Confirmar sitio de monitoreo',
        dataPayload: null,
        pictures: null,
        updatedBy: null,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `STM-${site.name.toUpperCase()}-ISE`,
        order: 2,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Índice de Salud Ecológica",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [0],
        formName: "StmIseForm",
        type: "stmIseTask",
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `STM-${site.name.toUpperCase()}-FORRAJE`,
        order: 1,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Fotos y Forraje",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [0],
        formName: "StmForagePictures",
        type: "stmForagePictures",
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      }
    ]
  )
}

