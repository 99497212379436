const endpoints = {
    activities: '/v1/monitoring/activities',
    events: '/v1/monitoring/events',
    tasks: '/v1/monitoring/tasks',
    sites: '/v1/monitoring/sites',
    pictures: '/v1/monitoring/pictures',
    samplingAreas: '/v1/samplingAreas',
    farms: '/v1/farms',
    workflows: '/v1/refData/workflows',
    taskStatus: '/v1/refData/taskStatus',
    horizonCodes: '/v1/refData/RefHorizonCodes',
    soilTextures: '/v1/refData/RefSoilTextures',
    structureTypes: '/v1/refData/RefStructureTypes',
    structureSizes: '/v1/refData/RefStructureSizes',
    structureGrades: '/v1/refData/RefStructureGrades',
    activityLayouts: '/v1/refData/RefActivityLayouts',
    species: '/v1/biodiversity/species',
    programConfig: '/v1/programConfig',
    programs: '/v1/programs',
    syncDownloadNew: '/v1/monitoring/sync/download/new'
}
export default endpoints;