import { appStore } from '../lfStorage/lfStorage.js';
import * as localDb from '../localDb/index.js';
import getBackupInfo from './getBackupInfo.js';

export default async function restoreBackup({backup, file, setLoadingAlert}) {
    try {
        const currentUser = await appStore.get('currentUser');

        let fileData = file;

        if (!file) {
            fileData = await backup.handle.getFile();
        } 

        const backupInfo = await getBackupInfo({fileData});

        if (backupInfo.formatName !== 'dexie') {
            throw new Error(`Invalid backup format`);
        }

        if (backupInfo.data.databaseName !== currentUser.userName) {
            throw new Error(`Invalid db owner`);
        }

        return await localDb.importDb(
            {
                fileData, 
                options: {
                    progressCallback: ({completedRows, totalRows, done}) => setLoadingAlert((prev) => ({...prev, show: !done, message: `Restaurando backup ${Math.round((completedRows / totalRows)*100)}%`}))
                }
            });
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to backup current data: ${error.message}`);
    }
}