import checkActivityComplete from '../helpers/checkActivityComplete.js';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import savePictures from '../helpers/savePictures.js';

import * as localDb from '../localDb';

export async function overrideSiteConfirmation( { position, data, pictures, monitoringSite, task, currentUser, setTask, setMonitoringSite }) {
    try {
        const taskUpdate = {
            actualLocation: position ? [position.lat, position.lng] : data.actualLocation,
            taskStatusId: 4, // 4 = Omitido
            updatedAt: moment.utc().format(),
            updatedBy: currentUser.userName,
            _rev: uuid()
        }
        const siteUpdate = {
            actualLocation: data.actualLocation,
            locationConfirmed: false,
            locationMoved: false,
            locationMovedComments: data.comments,
            allocated: false,
            updatedAt: moment.utc().format(),
            updatedBy: currentUser.userName,
            _rev: uuid()
        }

        const db = await localDb.getDb();

        db.transaction('rw?', ['sites', 'tasks', 'activities'], async () => {
            let tasksWithSameSite = await localDb.getMany('tasks').then(result => result.where({ monitoringEventId: task.monitoringEventId, monitoringSiteId: task.monitoringSiteId, type: 'confirmSite' }).toArray());
            for (let task of tasksWithSameSite) {
                await localDb.update('tasks', task.id, taskUpdate, true);
                checkActivityComplete(task.monitoringActivityId, currentUser.userName);
            }
    
            await localDb.update('sites', monitoringSite.id, siteUpdate, true);
            await localDb.update('tasks', task.id, taskUpdate, true);
            await localDb.update('activities', task.monitoringActivityId, { taskStatusId: 1 }, true);
            if (pictures?.length) await savePictures(currentUser.userName, task.monitoringEventId, 'site', monitoringSite.id, pictures);

        }).then(() => {
            setTask({ ...task, taskUpdate });
            setMonitoringSite({ ...monitoringSite, siteUpdate });
            return true;
        }).catch((error) => {
            throw new Error (error.message)
        })        
    } catch (error) {
        console.error(error.message);
        throw new Error (`No se pudo omitir el sitio: ${error.message}`)
    }
}
