export default function statusColorCoder(id) {
    switch(id) {
      case 0:
        return 'secondary';
      case 1:
        return 'error';
      case 2: 
        return 'primary';
      case 3:
        return 'error';
      default:
        return 'secondary'
    }
  }