import axios from 'axios';
import auth0Client from '../auth0/authClient.js'

export default async function getApiData(endpoint, query) {
    try {
        let authData = await auth0Client.getTokenSilently({ detailedResponse: true, ignoreCache: false, authorizeTimeoutInSeconds: 30 }).catch(e => console.error(e));
        let options = {
            url: process.env.REACT_APP_RUUTS_API_URL + endpoint,
            method: 'GET',
            params: query,
            headers: {
                Authorization: `Bearer ${authData.access_token}`
            }
        }
        let response = await axios.request(options);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to get data from ${endpoint}: ${error.message}`)
    }
}