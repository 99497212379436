// React
import { useState, useEffect, useContext } from "react";

//Services
import refDataById from "../../services/localRefData/refDataById";
import statusColorCoder from "../../services/helpers/statusColorCoder";
import downloadEventData from "../../services/ruutsApi/downloadEventData";

//Context
import AppContext from "../../context/appContext";

//libs
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

//Components
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

//Icons
import PlaceIcon from "@mui/icons-material/Place";
import DownloadDone from "@mui/icons-material/DownloadDone";
import Download from "@mui/icons-material/Download";
import Alarm from "@mui/icons-material/Alarm";
import Phone from "@mui/icons-material/Phone";
import Hail from "@mui/icons-material/Hail";
import OverviewMap from "../OverviewMap/OverviewMap";

//Custom
import DrawerHeaderBar from "../DrawerHeaderBar/DrawerHeaderBar";
import { ApplicationError, BuildReportError } from "../../models/errors";
import { DeleteIndicator } from "../MyEvents/DeleteIndicator";
import { errorMessages } from "../../models/errors/errorsMessages";
import permanentlyDeleteEvent from "../../services/monitoring/permanentlyDeleteEvent";
import { Delete } from "@mui/icons-material";
import { successMessages } from "../../models/success/successMessages";
import { ButtonGroup } from "@mui/material";

export default function EventOverview({ show, handleClose, eventData, farm }) {
  const { storageFreeze, errorCatcher, setAlert, locale, setLoadingAlert } =
    useContext(AppContext);
  const [downloadStatus, setDownloadStatus] = useState("available");
  const navigate = useNavigate();
  let timeOut;

  function handleEventSelect(e, eventData) {
    localStorage.setItem("currentEventId", eventData.id);
    navigate(`/events/${eventData.id}`, { replace: true });
  }

  async function handleDeleteEventPermanently() {
    try {
      setAlert({
        ...errorMessages.permanentlyDeleteEvent[locale],
        action: async () => {
          try {
            setLoadingAlert({
              message: "Espere por favor",
              severity: "warning",
              show: true,
            });
            await permanentlyDeleteEvent(eventData);
            setLoadingAlert({ show: false });
            setAlert({
              ...successMessages.successDeleteEvent[locale],
              action: () => {
                handleClose({ reload: true });
              },
            });
          } catch (error) {
            errorCatcher(new ApplicationError(error.message));
          }
        },
      });
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  }

  async function handleDownload() {
    try {
      setDownloadStatus("downloading");
      await downloadEventData(eventData.id);
      setDownloadStatus("success");
      timeOut = setTimeout(() => setDownloadStatus("available"), 20000);
    } catch (error) {
      console.log(error);
      errorCatcher(new BuildReportError(error.message));
    }
  }
  const getDownloadStatusIcon = (downloadStatus) => {
    if (downloadStatus === "available") return <Download color="primary" />;
    if (downloadStatus === "success") return <DownloadDone color="success" />;
    if (downloadStatus === "downloading") <CircularProgress color="primary" />;
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeOut);
    };
  }, [timeOut]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          borderRadius: "10px 10px 0 0",
          height: "100vh",
          maxWidth: "100vw",
        },
      }}
      anchor="bottom"
      open={show}
      onClose={() => handleClose()}
    >
      <Stack
        display="flex"
        flexGrow={1}
        justifyItems="flex-start"
        alignContent={"space-between"}
        sx={{ height: "95vh", width: "100vw", borderRadius: "10px 10px 0 0" }}
      >
        <DrawerHeaderBar
          alignTitle="left"
          title={farm?.name.toUpperCase() || eventData.name.toUpperCase()}
          rightIcon={
            eventData.isDeleted ? (
              <DeleteIndicator eventData={eventData} />
            ) : (
              <Chip
                variant="outlined"
                color={statusColorCoder(eventData.taskStatusId)}
                label={refDataById(
                  "taskStatus",
                  eventData.taskStatusId
                ).toLowerCase()}
                size="small"
              />
            )
          }
          handleClose={handleClose}
        />
        <Paper elevation={0} sx={{ maxHeight: "90vh", overflow: "auto" }}>
          <Grid container sx={{ overflow: "scroll" }} spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box
                p={1}
                display={"flex"}
                flexGrow={1}
                flexDirection={"column"}
                height={"450px"}
                width={"100%"}
                alignItems={"center"}
              >
                {farm && show && (
                  <OverviewMap
                    height={"400px"}
                    width={"100%"}
                    allowZoom={false}
                    enableLocation={false}
                    initialZoom={16}
                    eventId={eventData.id}
                    allowDonwload={true}
                    farm={farm}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Stack m={1} display={"flex"} direction={"column"} spacing={1}>
                <Box display="flex" flexDirection="row">
                  <Alarm />
                  <Typography sx={{ ml: 1 }}>
                    <Moment format="H:mm">{eventData.date}</Moment>hr.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <PlaceIcon />
                  <Typography sx={{ ml: 1 }}>{farm?.address}</Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Phone />
                  <Typography sx={{ ml: 1 }}>
                    {farm?.primaryContact} {farm?.phoneNumber}{" "}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Hail />
                  <Typography sx={{ ml: 1 }}>{eventData.assignedTo}</Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
      {eventData.isDeleted && (
        <Stack
          p={1}
          direction={"row"}
        >
          <Typography textAlign="center" variant="caption" color={"error"}>
            Este evento ha sido eliminado en el servidor principal. No será
            posible trabajar sobre el mismo. Puede descargar los datos
            disponibles.
          </Typography>
        </Stack>
      )}
      <Stack
        sx={{ boxShadow: 3 }}
        backgroundColor="white"
        flex
        p={2}
        pb={3.5}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent="center"
        flexWrap={"nowrap"}
        justifySelf={"end"}
        position={"sticky"}
        bottom="0"
        flexGrow={0}
        zIndex={999}
        width={"100vw"}
      >
        <ButtonGroup fullWidth variant="outlined">
          <Button
            startIcon={getDownloadStatusIcon(downloadStatus)}
            onClick={(e) => handleDownload()}
          >
            DATOS
          </Button>
          {!eventData.isDeleted ? (
            <Button
              disabled={storageFreeze}
              onClick={(e) => handleEventSelect(e, eventData)}
              variant="contained"
            >
              {eventData.taskStatusId > 0 ? "CONTINUAR" : "COMENZAR"}
            </Button>
          ) : (
            <Button
              startIcon={<Delete />}
              color="error"
              variant="outlined"
              onClick={handleDeleteEventPermanently}
            >
              ELIMINAR
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    </Drawer>
  );
}
