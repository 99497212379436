import generateUtmGrid from "../geolocationScripts/generateUtmGrid";

export function generateRelativeGridOverride ({data, activity, setTempGrid}) {
    try {
        if (!(data.actualLocation)) {
            throw new Error ('Missing actual location');
        }
        let initialPoint = {
            lat: data.actualLocation[0],
            lng: data.actualLocation[1]
        }
        let gridGeoJson = generateUtmGrid(initialPoint, activity.activityLayoutId);
        setTempGrid(gridGeoJson);
    } catch (error) {
        console.error(error);
        throw new Error (`Unable to project grid: ${error.message}`);
    }
}