//REACT
import { useContext, useEffect, useState } from 'react'

//Hook Form
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/RemoveCircle';

//Components
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';
import refDataByKey from '../../../services/localRefData/refDataByKey.js';

export default function TaskForm({
    geolocation,
    position,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
}) {
    const { task } = useContext(TaskContext);
    const [species, setSpecies] = useState([]);
    const [accordionExpanded, setAccordionExpanded] = useState([1, 2])
    const { control, watch, setValue, formState } = useFormContext();

    const handleAccordion = (num) => {
        if (accordionExpanded.includes(num)) {
            setAccordionExpanded(s => s.filter( a => a !== num))
        } else {
            setAccordionExpanded(s => [...s, num])
        }
    } 

    const species1to50Array = useFieldArray({
        control,
        name: 'species1to50'
    });

    const species51to100Array = useFieldArray({
        control,
        name: 'species51to100'
    });

    useEffect(() => {
        async function getSpecies() {
            let speciesAll = refDataByKey('species');
            setSpecies(speciesAll);
        }
        getSpecies();
    }, [task])

    return (
        <Stack spacing={1}>
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Box pt={1} textAlign={'center'}>
                <Typography variant='h6'><b>Tramo 1 de 2 (1 a 50)</b></Typography>
                <Typography variant='body2'>Ingrese en el desplegable cada especie y el puntaje total de la misma en el tramo observado</Typography>
            </Box>

            <Accordion expanded={ 'species1to50' in formState.errors ? true : accordionExpanded.includes(1)} onChange={() => handleAccordion(1)}>
                <AccordionSummary
                    sx={{ backgroundColor: 'primary' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='body2'>PUNTAJE: <b>{watch('species1to50')?.reduce((acc, cur) => acc += parseFloat(cur?.score || 0), 0)}</b></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 1 }}>
                    <Stack display='flex' direction={'column'} spacing={1}>
                        {species && species.length > 1 && species1to50Array.fields.map((item, index) =>
                            <Stack display={'flex'} alignItems={'center'} justifyContent={'space-between'} direction={'row'} key={item.id} spacing={1}>
                                <Controller
                                    rules={{required: true}}
                                    key={`species1to50.${item.id}.speciesName`}
                                    control={control}
                                    name={`species1to50.${index}.speciesName`}
                                    render={({ field, fieldState }) =>
                                        <Box>
                                            <Autocomplete
                                                {...field}
                                                disablePortal
                                                autoSelect
                                                freeSolo
                                                sx={{ width: '250px' }}
                                                options={species}
                                                getOptionLabel={(option) => option.commonName || option}
                                                size="small"
                                                renderInput={(params) => 
                                                <TextField
                                                    {...params}
                                                    label="Especie"
                                                    error={fieldState.error? true : false}
                                                />}
                                                onChange={(event, value) => {
                                                    field.onChange(value);
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`species1to50.${item.id}.score`}
                                    control={control}
                                    name={`species1to50.${index}.score`}
                                    render={({ field, fieldState }) => (
                                        <NumericInput
                                            size={'small'}
                                            sx={{ width: '50px' }}
                                            field={field}
                                            fieldState={fieldState}
                                            label={'Pts'}
                                            min={0}
                                            max={50}
                                        />
                                    )}
                                />
                                {<IconButton disabled={index === 0} size='small' color='error' onClick={() => species1to50Array.remove(index)}> <DeleteIcon /></IconButton>}
                            </Stack>
                        )}
                        <Button color='primary' variant='text' size='small' onClick={() => species1to50Array.append({ speciesName: '', score: 0 })}>+ AGREGAR ESPECIE</Button>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <Controller
                control={control}
                name={`totalScore1to50`}
                defaultValue={''}
                rules={{ min: 45, max: 55 }}
                render={({ field }) => (
                    <input
                        {...field}
                        name='totalScore1to50'
                        type='hidden'
                        min={45}
                        max={55}
                    />)}
            />
            <Box pt={1} textAlign={'center'}>
                <Typography variant='h6'><b>Tramo 2 de 2 (51 a 100)</b></Typography>
                <Typography variant='body2'>Ingrese en el desplegable cada especie y el puntaje total de la misma en el tramo observado</Typography>
            </Box>
            <Accordion expanded={ 'species51to100' in formState.errors ? true : accordionExpanded.includes(2) } onChange={() => handleAccordion(2)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel2a-header"
                >
                    <Typography variant='body2'>PUNTAJE: <b>{watch('species51to100')?.reduce((acc, cur) => acc += parseInt(cur?.score || 0), 0)}</b></Typography>

                </AccordionSummary>
                <AccordionDetails sx={{ padding: 1 }}>
                    <Stack display='flex' direction={'column'} spacing={1}>
                        {species51to100Array.fields.map((item, index) =>
                            <Stack display={'flex'} alignItems={'center'} justifyContent={'space-between'} direction={'row'} key={item.id} spacing={.5}>
                                <Controller
                                    rules={{required: true}}
                                    key={`species51to100.${item.id}.speciesName`}
                                    control={control}
                                    name={`species51to100.${index}.speciesName`}
                                    render={({ field, fieldState }) => (
                                        <Box>
                                            <Autocomplete
                                                {...field}
                                                autoSelect
                                                disablePortal
                                                freeSolo
                                                sx={{ width: '240px' }}
                                                options={species}
                                                getOptionLabel={(option) => option.commonName || option}
                                                size="small"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params} label="Especie"
                                                        error={fieldState.error? true : false}
                                                    />}
                                                onChange={(event, value) => {
                                                    field.onChange(value);
                                                }}
                                            />
                                        </Box>
                                    )}
                                />
                                <Controller
                                    rules={{required: true}}
                                    key={`species51to100.${item.id}.score`}
                                    control={control}
                                    name={`species51to100.${index}.score`}
                                    render={({ field, fieldState }) => (
                                        <NumericInput
                                            size={'small'}
                                            sx={{ width: '50px' }}
                                            field={field}
                                            fieldState={fieldState}
                                            label={'Pts'}
                                            min={0}
                                            max={50}
                                        />)}
                                />
                                {<IconButton disabled={index === 0} size='small' color='error' onClick={() => species51to100Array.remove(index)}> <DeleteIcon /></IconButton>}
                            </Stack>
                        )}
                        <Button color='primary' variant='text' size='small' onClick={() => species51to100Array.append({ speciesName: '', score: 0 })}>+ AGREGAR ESPECIE</Button>
                    </Stack>

                </AccordionDetails>
            </Accordion>
            <Controller
                control={control}
                name={`totalScore51to100`}
                defaultValue={''}
                render={({ field }) => (
                    <input
                        {...field}
                        name='totalScore51to100'
                        type='hidden'
                        min={45}
                        max={55}
                    />)}
            />
        </Stack>
    )
}