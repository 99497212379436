export const successMessages = {
    taskComplete: {
        es_AR: { show: true, severity: 'success', message: 'Tarea completeda con éxito' },
        en_US: { show: true, severity: 'success', message: 'Task completed' }
    },
    successDeleteEvent: {
        es_AR: { show: true, severity: 'success', message: 'Evento borrado con éxito'},
        en_US: { show: true, severity: 'success', message: 'Event successfully deleted'}
    },
    taskPending: {
        es_AR: { show: true, severity: 'success', message: 'Se guardó el progreso de la tarea como Pendiente' },
        en_US: { show: true, severity: 'success', message: 'Task saved as pending' }
    },
    backupRestoreSuccess: {
        es_AR: { show: true, severity: 'success', message: 'Se restauró el backup correctamente' },
        en_US: { show: true, severity: 'success', message: 'Backup restored successfully    ' }
    },
}