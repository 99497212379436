export default function refDataById(key, id) {
    if (!key) {
        throw new Error ('Missing key');
    }
    if (id === undefined ) {
        throw new Error ('Missing ID');
    }
    try {
        let [data] = JSON.parse(localStorage.getItem(key)).filter((obj) => {
            return obj.id === id
        });
        return data.name
    } catch (error) {
        console.error(error);
        throw new Error (`Unable to retrieve reference data for ${key}`);
    }
}