import { LatLng } from 'leaflet';
import * as turf from '@turf/turf';
import * as localDb from '../localDb';

export default async function getRandomPoint(plannedLocation, samplingAreaId) {

    let samplingArea = await localDb.getOne('samplingAreas', samplingAreaId);
    let areaSqrMts = turf.area(samplingArea.toGeoJSON);
    let center = [plannedLocation[1], plannedLocation[0]];
    let radiusMax = (Math.sqrt(areaSqrMts) / 5);
    let options = { steps: 32, units: 'meters' }
    let circleMax = turf.circle(center, radiusMax, options);
    
    let newPoint = {};
    let pointWithinArea = false;

    while (!pointWithinArea) {
        let r = (radiusMax / 1e5) * Math.sqrt(Math.random());
        let theta = Math.random() * 2 * Math.PI;
        let x = plannedLocation[0] + r * Math.cos(theta);
        let y =plannedLocation[1] + r * Math.sin(theta);
        pointWithinArea = turf.booleanWithin(turf.point([y,x]), samplingArea.toGeoJSON);
        if (pointWithinArea) { 
            newPoint= new LatLng(x, y);
        }
    }
    return ({ newPoint: newPoint, polygon: circleMax, })
}