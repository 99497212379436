import * as utm from 'utm'
import * as turf from '@turf/turf';

function getDistance(x1, y1, x2, y2) {
    let y = x2 - x1;
    let x = y2 - y1;

    return Math.sqrt(x * x + y * y);
  }


export default function getDistanceUtm(from, to) {
    let fromUtm = utm.fromLatLon(from.lat, from.lng);
    let toUtm = utm.fromLatLon(to.lat, to.lng);

    let distance = getDistance( toUtm.easting, toUtm.northing, fromUtm.easting, fromUtm.northing);
    let bearing = turf.bearing(turf.point([from.lng, from.lat]), turf.point([to.lng, to.lat]));
    
    return ({
        distance: distance, 
        bearing: isNaN(bearing) ? null : bearing,
    });
}