//REACT
import { useContext, useEffect, useState } from 'react'

//Hook Form
import { Controller, useFieldArray, useFormContext } from "react-hook-form";


//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';

import DeleteIcon from '@mui/icons-material/RemoveCircle';

//Components
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';
import refDataByKey from '../../../services/localRefData/refDataByKey.js';

export default function TaskForm({
    geolocation,
    position,
    handleCameraInput,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    pictures,
}) {
    const { task } = useContext(TaskContext);
    const [species, setSpecies] = useState();
    const { control, setValue } = useFormContext();

    const speciesFlexibleAreaArray = useFieldArray({
        control,
        name: 'speciesFlexibleArea'
    });

    useEffect(() => {
        async function getSpecies() {
            let speciesAll = refDataByKey('species');
            setSpecies(speciesAll);
        }
        getSpecies();
    }, [task])

    return (

        <Stack spacing={2}>
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Stack component='fieldset' display='flex' direction={'column'} spacing={2}>
                <Box pt={1} textAlign={'center'}>
                    <Typography variant='h6'><b>Lectura área flexible</b></Typography>
                    <Typography>Ingrese cada especie, y cantidad de individuos observados dentro de la faja comprendida 1 mts de cada lado de la transecta #2</Typography>
                </Box>
                {species && species.length > 1 && speciesFlexibleAreaArray.fields.map((item, index) =>
                    <Stack display={'flex'} direction={'column'} key={item.id} spacing={2}>
                        <Stack direction={'row'} width={'100%'} spacing={.5} display={'flex'} justifyContent={'space-evenly'}>
                            <Controller
                                rules={{required: true}}
                                key={`speciesFlexibleArea.${item.id}.speciesName`}
                                control={control}
                                name={`speciesFlexibleArea.${index}.speciesName`}
                                render={({ field, fieldState }) =>
                                    <Box display={'flex'} flexGrow={1}>
                                        <Autocomplete
                                            {...field}
                                            fullWidth
                                            autoSelect
                                            disablePortal
                                            freeSolo
                                            options={species}
                                            getOptionLabel={(option) => option.commonName || option}
                                            size="small"
                                            renderInput={(params) => 
                                                <TextField 
                                                    {...params} 
                                                    label="Especie"
                                                    error={fieldState.error ? true :  false} 
                                                />}
                                            onChange={(event, value) => {
                                                field.onChange(value);
                                            }}
                                        />
                                    </Box>
                                }
                            />
                            {<IconButton disabled={index === 0} size='small' color='error' onClick={() => speciesFlexibleAreaArray.remove(index)}> <DeleteIcon /></IconButton>}
                        </Stack>
                        <Stack direction={'row'} width={'100%'} spacing={1}>
                            <Controller
                                rules={{required: true}}
                                key={`speciesFlexibleArea.${item.id}.amountOfIndividuals`}
                                control={control}
                                name={`speciesFlexibleArea.${index}.amountOfIndividuals`}
                                render={({ field, fieldState }) => (
                                    <NumericInput
                                        field={field}
                                        fieldState={fieldState}
                                        label='Cantidad'
                                        min={0}
                                        max={null}
                                    />
                                    )}
                            />
                            <Controller
                                rules={{required: true}}
                                key={`speciesFlexibleArea.${item.id}.areaCovered`}
                                control={control}
                                name={`speciesFlexibleArea.${index}.areaCovered`}
                                render={({ field, fieldState}) => (
                                    <NumericInput
                                        field={field}
                                        fieldState={fieldState}
                                        label='Area cubierta'
                                        min={0}
                                        max={null}
                                        unit={'m2'}
                                    />
                                    )}
                            />
                        </Stack>
                    </Stack>
                )}
                <Button color='primary' variant='text' size='small' onClick={() => speciesFlexibleAreaArray.append({ speciesName: '', score: 0 })}>+ AGREGAR ESPECIE</Button>
            </Stack>
        </Stack>
    )
}