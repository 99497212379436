import { geoJSON } from 'leaflet';
import { getStorageInfo, getStoredTilesAsJson } from 'leaflet.offline';

const urlTemplate = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'

export default function storageLayer(baseLayer, layerswitcher) {
  let layer;

  const getGeoJsonData = () =>
    getStorageInfo(urlTemplate).then((tiles) =>
      getStoredTilesAsJson(baseLayer, tiles)
    );

  const addStorageLayer = () => {
    getGeoJsonData().then((geojson) => {
      layer = geoJSON(geojson).bindPopup(
        (clickedLayer) => clickedLayer.feature.properties.key
      );
      layerswitcher.addOverlay(layer, 'Ver descarga');
    });
  };

  addStorageLayer();

  baseLayer.on('storagesize', (e) => {
    document.getElementById('storage').innerHTML = `Map cache: ~${Math.round(e.storagesize * 10 /1024, 2)} MB`;
    if (layer) {
      layer.clearLayers();
      getGeoJsonData().then((data) => {
        layer.addData(data);
      });
    }
  });
}