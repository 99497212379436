export const stmForageFields = [
    {
        label: 'Biomasa (kg de materia seca x HA)',
        name: 'dryMatterPerHectare',
        min: 0,
        max: null,
        default: 0,
        enabled: true
    },
    {
        label: 'Biomasa (m2 para un día animal)',
        name: 'dryMatterPerAnimalDayM2',
        min: 0,
        max: 600,
        default: 0,
        enabled: true
    },
    {
        label: 'Calidad Forraje',
        name: 'forageQuality',
        min: 1,
        max: 5,
        step: 0.5,
        default: 1,
        enabled: true
    },
    {
        label: 'Patrón de Uso',
        name: 'forageUsePattern',
        min: null,
        max: null,
        default: '',
        enabled: true
    },
    {
        label: 'Intensidad',
        name: 'forageIntensity',
        min: null,
        max: null,
        default: '',
        enabled: true
    },
]