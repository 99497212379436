import { useContext } from 'react';

//Context
import AppContext from '../../context/appContext';
import { useAuth0 } from "@auth0/auth0-react";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Avatar } from '@mui/material';

export default function UserInformationModal({ show, closeModal }) {
    const { currentUser, isOnline } = useContext(AppContext);

    const {
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();

    const handleClose = () => {
        closeModal();
    };

    const handleAction = async () => {
        handleClose();
    }


    return (
        <Dialog
            open={show || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ zIndex: 9999 }}
        >
            <DialogTitle id="alert-dialog-title">
                Información del usuario
            </DialogTitle>
            <DialogContent>
                <Stack display='flex' direction='row' pt={1} pb={2} alignItems={'center'} justifyContent={'space-around'}>
                    <Avatar alt={currentUser.userName} src={currentUser.picture} />
                </Stack>
                <DialogContentText sx={{ textAlign: 'center' }} id="alert-dialog-description">
                    Usuario: {currentUser.userName}
                </DialogContentText>
                <DialogContentText sx={{ textAlign: 'center' }} id="alert-dialog-description">
                    Sesión: {isAuthenticated ? 'Iniciada' : 'No iniciada'}
                </DialogContentText>
                <Stack display='flex' direction='row' pt={2} spacing={1} alignItems={'center'} justifyContent={'space-around'}>
                    <Button onClick={handleAction}>
                        {'CERRAR'}
                    </Button>
                    {!isAuthenticated && isOnline &&
                        <Button color='secondary' onClick={loginWithRedirect}>
                            LOGIN
                        </Button>
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    );
}