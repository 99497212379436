import { useState, useContext, useEffect } from 'react';

//Context
import AppContext from '../../context/appContext';

//Components
import Toolbar from '@mui/material/Toolbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

//Icons
//import Sort from '@mui/icons-material/Sort'

//Styles
import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Theme for this component if needed
const theme = createTheme({
    selectBg: '#3F3938',
    labelTextFocused: '#9BC8F5',
    /*palette: {
        primary: {
            light: grey[100],
            main: '#698BE2',
            dark: grey[400],
            contrastText: grey[50]
        },
        secondary: {
            light: grey[200],
            main: '#9BC8F5',
            dark: grey[400],
            contrastText: grey[50]
        },
    },*/
    typography: {
        fontFamily: 'Quicksand',
        h1: {
            fontFamily: 'Montserrat',
            fontSize: '4rem'
        },
        h2: {
            fontFamily: 'Montserrat',
            fontSize: '3rem'

        },
        h3: {
            fontFamily: 'Montserrat',
            fontSize: '2.5rem'

        },
        h4: {
            fontFamily: 'Montserrat',
            fontSize: '2rem'

        },
        h5: {
            fontFamily: 'Montserrat',
            fontSize: '1.5rem'
        },
        h6: {
            fontFamily: 'Montserrat',
            fontSize: '1.2rem',
        },
    }
})

export default function ToolbarFilters(props) {
    const { activeFilter, setActiveFilter, currentEvent } = useContext(AppContext);
    const [mainFilter, setMainFilter] = useState(props.mainFilter.default);
    const [secondaryFilter, setSecondaryFilter] = useState(props.secondaryFilter.default)

    function handleFilterChange(e) {
        e.preventDefault();
        let filterType = e.target.name === 'mainFilter' ? 'mainFilter' : 'secondaryFilter';
        if (filterType === 'mainFilter') setMainFilter(e.target.value);
        if (filterType === 'secondaryFilter') setSecondaryFilter(e.target.value);
        let newFilter = { ...activeFilter };
        newFilter[filterType] = e.target.value;
        setActiveFilter(newFilter);
    }

    useEffect(() => {
        if (currentEvent) {
            let newFilter = {
                mainFilter: 999,
                secondaryFilter: 999
            }
            setActiveFilter(newFilter)
        }
    }, [currentEvent, setActiveFilter])

    return (
        <ThemeProvider theme={theme}>
            <Toolbar id='appbar-filters'
                sx={{ backGroundColor: 'primary', justifyContent: 'space-between', height: props.height }}
            >
                <FormControl variant='filled' size='small' sx={{ mr: 0.5, width: '0.5' }}>
                    <InputLabel
                        sx={{ color: grey[50], "&.Mui-focused": { color: theme.labelTextFocused }}} 
                        id="select-actividad-label">{props.mainFilter.name}
                    </InputLabel>
                    <Select
                        name='mainFilter'
                        size='medium'
                        sx={{ color: '#FFFF', opacity: '0.7', backgroundColor: theme.selectBg, "&.Mui-focused": { backgroundColor: theme.selectBg }, "&.MuiFilledInput-underline:after": { borderBottomColor: theme.labelTextFocused } }}
                        id='select-actividad'
                        type='text'
                        value={mainFilter}
                        onChange={(e) => handleFilterChange(e)}
                    >
                        {props.mainFilter.list.length > 0 && props.mainFilter.list.map((option) => {
                            return (<MenuItem key={option.id} value={option.id}> {option.name} </MenuItem>)
                        }
                        )}
                    </Select>
                </FormControl>
                <FormControl variant='filled' size='medium' sx={{ ml: 0.5, width: '0.5' }}>
                    <InputLabel
                        sx={{ color: grey[50], "&.Mui-focused": { color: theme.labelTextFocused } }} 
                        id="select-tarea-label">{props.secondaryFilter.name}
                    </InputLabel>
                    <Select
                        name='secondaryFilter'
                        size='medium'
                        sx={{ color: '#FFFF', opacity: '0.7', backgroundColor: theme.selectBg, "&.Mui-focused": { backgroundColor: theme.selectBg }, "&.MuiFilledInput-underline:after": { borderBottomColor: theme.labelTextFocused } }}
                        id='select-tarea'
                        type='text'
                        value={secondaryFilter}
                        onChange={(e) => handleFilterChange(e)}
                    >
                        {props.secondaryFilter.list.length > 0 && props.secondaryFilter.list.map((option) => {
                            return (<MenuItem key={option.id} value={option.id}> {option.name} </MenuItem>)
                        }
                        )}
                    </Select>
                </FormControl>
            </Toolbar>
        </ThemeProvider>
    )
}