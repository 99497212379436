import { useCallback, useEffect, useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Typography } from '@mui/material';

export default function GettingPositionModal({ show, cancelAction, handleClose, geolocationObject }) {
    //const [error, setError] = useState();
    const [position, setPosition] = useState();
    const timer = useRef();
    const [duration, setDuration] = useState(0);

    const startTimer = useCallback(() => {
        timer.current = setInterval(() => setDuration((d) => d + 1), 1000)
    },[]);

    const resetTimer = useCallback(() => {
        clearInterval(timer.current);
    },[])

    const handleCancel = async () => {
        resetTimer();
        if (cancelAction) cancelAction();
        handleClose();
    }

    useEffect(() => {
        setPosition(geolocationObject)
        startTimer();
        return () => {
            if (timer.current) clearInterval(timer.current);
        }
    }, [startTimer, geolocationObject])


    return (
        <Dialog
            open={show || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'space-around'}>
                    <MyLocationIcon color='primary' />
                    <Typography variant='h6'>Obteniendo ubicación</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                    <Stack alignItems={'center'}>
                        <Typography variant='h6'>Precisión: {position?.accuracy || ' - '}mts</Typography>
                        <Typography>Latitud: {position?.latitude} </Typography>
                        <Typography>Longitud: {position?.longitude}</Typography>
                        <Typography>Tiempo transcurrido: {duration}s</Typography>
                    </Stack>
                <Stack display='flex' direction='row' pt={2} spacing={1} alignItems={'center'} justifyContent={'space-around'}>
                    <Button color='primary' onClick={handleCancel}>
                        CANCELAR
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}