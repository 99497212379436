//REACT
import { useContext, useEffect } from 'react'

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

//Components
import { PhotoInput } from '../../PhotoInput/PhotoInput.jsx';
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';


export default function TaskForm({
    pictures,
    handleCameraInput,
    editMode,
    position,
    plannedLocation,
    watchPosition,
    stopWatch,
    geolocation
}) {
    const { task } = useContext(TaskContext);
    const { control, setValue } = useFormContext();

    useEffect(() => {
        setValue('sampleLabel', task.key.slice(0, task.key.length - 8),)
    }, [setValue, task])

    return (
        <Stack spacing={2}>
            <Controller
                control={control}
                name="sampleLabel"
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        name="sampleLabel"
                        label="Rótulo Muestra"
                    />
                )}
            />
            <Controller
                control={control}
                name="timestamp"
                render={({ field }) => (
                    <TextField
                        {...field}
                        name='timestamp'
                        fullWidth
                        label='Fecha y Hora'
                        type={'datetime'}
                    />)}
            />
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Controller
                control={control}
                name="sampleWetWeightKg"
                render={({ field, fieldState }) => (
                    <NumericInput
                            field={field}
                            fieldState={fieldState}
                            label='Peso fresco de la muestra'
                            min={0}
                            max={null}
                            unit={'kg'}
                        />
                    )}
            />
            <Controller
                control={control}
                name="comments"
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        multiline
                        name='comments'
                        type={'text'}
                        label='Comentarios'
                    />
                )}
            />
            <PhotoInput
                width='150'
                height='150'
                handleCameraFile={handleCameraInput}
                picture={pictures.find(pic => pic.key === task.key)}
                pictureKey={pictures.find(pic => pic.key === task.key)?.key || task.key}
                pictureIndex={pictures.findIndex(pic => pic.key === task.key) >= 0 ? pictures.findIndex(pic => pic.key === task.key) : 0}
                pictureId={pictures.find(pic => pic.key === task.key)?.id || null}
            />
        </Stack>
    )
}