//REACT
import { useContext } from 'react'

//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import HeightIcon from '@mui/icons-material/Height';

//Components
import { PhotoInput } from '../../PhotoInput/PhotoInput.jsx';

import { Controller, useFormContext } from "react-hook-form";

//localDB
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';

export default function TaskForm({
    geolocation,
    position,
    handleCameraInput,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    pictures,
}) {
    const { task } = useContext(TaskContext);
    const { control, setValue } = useFormContext();

    return (
        <Stack spacing={2}>
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Grid
                component='fieldset'
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                container
            >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((a, i) =>
                    <Grid key={i} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack direction='column' display='flex' alignItems={'center'} justifyContent='center' spacing={2}>
                            <Stack textAlign='center' spacing={1}>
                                <Typography variant='h6'><b>FOTO {i + 1}</b></Typography>
                                <Typography textAlign={'center'} variant='body'>Tomar la foto hacia el suelo. Utilizar marco de 0.25m<sup>2</sup></Typography>
                            </Stack>
                            <PhotoInput
                                required
                                width={200}
                                height={150}
                                handleCameraFile={handleCameraInput}
                                picture={pictures.find(pic => pic.key === task.key + '-take-' + i)}
                                pictureKey={task.key + '-take-' + i}
                                pictureIndex={pictures.findIndex(pic => pic.key === task.key + '-take-' + i) >= 0 ? pictures.findIndex(pic => pic.key === task.key + '-take-' + i) : i}
                                pictureId={pictures.find(pic => pic.key === task.key + '-take-' + i)?.id || null}
                            />
                            <Controller
                                control={control}
                                name={`closestPerennialDistanceMts-${i}`}
                                rules={{ required: 'Campo requerido' }}
                                render={({ field, fieldState }) => (
                                    <NumericInput
                                        field={field}
                                        fieldState={fieldState}
                                        label='Distancia a perenne m/cercana'
                                        min={0}
                                        max={null}
                                        unit={'m'}
                                    />
                                )}
                            />
                            {i !== 9 && <Stack direction={'row'} display={'flex'} alignItems={'center'} pb={2}>
                                <HeightIcon fontSize='large' />
                                <Typography variant='h6'>2.5m</Typography>
                            </Stack>}
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Stack>
    )
}