import L from 'leaflet';
import getDistanceUtm from '../geolocationScripts/getDistanceUtm.js';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import * as localDb from '../localDb';

export async function confirmMonitoringSite({ geolocationObject, position, monitoringSite, task, setTask, setMonitoringSite, userName, taskComplete }) {
    try {
        const taskUpdate = {
            actualLocation: [position.lat, position.lng],
            deviceLocationData: geolocationObject,
            taskStatusId: 2,
            updatedAt: moment.utc().format(),
            updatedBy: userName,
            _rev: uuid()
        }
        let siteUpdate = {
            actualLocation: [position.lat, position.lng],
            deviceLocationData: geolocationObject,
            locationConfirmed: true,
            locationMoved: false,
            distanceToPlannedLocation: getDistanceUtm(position, new L.LatLng(monitoringSite.plannedLocation[0], monitoringSite.plannedLocation[1])).distance,
            allocated: true,
            updatedAt: moment.utc().format(),
            updatedBy: userName,
            _rev: uuid()
        }
        let tasksWithSameSite = await localDb.getMany('tasks').then(result => result.where({ monitoringEventId: task.monitoringEventId, monitoringSiteId: task.monitoringSiteId, type: 'confirmSite' }).toArray());

        const db = await localDb.getDb();

        await db.transaction('rw?', ['tasks', 'sites', 'activities', 'changelog'], async (trx) => {
            for (let task of tasksWithSameSite) {
                await localDb.update('tasks', task.id, taskUpdate, true, db);
            }
            await localDb.update('sites', monitoringSite.id, siteUpdate, true, db);
            await localDb.update('tasks', task.id, taskUpdate, true, db);
            await localDb.update('activities', task.monitoringActivityId, { taskStatusId: 1 }, true, db);
        });
        setMonitoringSite({ ...monitoringSite, siteUpdate });
        setTask((task) => ({ ...task, ...taskUpdate }));
        taskComplete();
    } catch (error) {
        console.error(error.message)
    }
}
