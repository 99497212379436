import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Loading from '../Loading/Loading';

export default function LoadingModal({ show, toggleAlert, severity, message, action, actionButtonTitle, cancelButtonTitle, cancelAction}) {
    const handleClose = () => {
        toggleAlert(false);
        if (cancelAction) cancelAction();
    };

    const getIcon = (severity) => {
        if (severity === 'error') {
            return (
                <Stack display='flex' direction='row' spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <ErrorOutlinedIcon color='error' sx={{ fontSize: '50px' }} />
                </Stack>
            )
        }
        if (severity === 'success') {
            return (
                <Stack display='flex' direction='row' spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <CheckCircleOutlineOutlinedIcon color='success' sx={{ fontSize: '50px' }} />
                </Stack>
            )
        }
        if (severity === 'warning') {
            return (
                <Stack display='flex' direction='row' spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <WarningAmberIcon color='warning' sx={{ fontSize: '50px' }} />
                </Stack>
            )
        }
    }

    return (
        <Dialog
            open={show || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{zIndex: 9999}}
        >
            <DialogTitle id="alert-dialog-title">
                {getIcon(severity)}
            </DialogTitle>
            <DialogContent sx={{m:1}}>
                <DialogContentText sx={{ textAlign: 'center',pb: 3 }} id="alert-dialog-description">
                    {message}
                </DialogContentText>
                <Loading sx={{pb: 2}} width={'100%'} height={'100%'}/>
            </DialogContent>
        </Dialog>
    );
}