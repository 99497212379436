import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function Loading({width, height}) {
    return(
        <Box display='flex' height={height || '60vh'} width={width || '100vw'} alignItems='center' justifyContent='center' flexGrow={1}>
            <CircularProgress />
        </Box>
    )
}