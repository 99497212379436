import localforage from "localforage";

let authCache = localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'ruuts-mdc',
    version: 1.0,
    storeName: 'authCache',
    description: 'cache for auth0'
})

let appCache = localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'ruuts-mdc',
    version: 1.0,
    storeName: 'appCache',
    description: 'cache for app'
})

export const appStore = {
    get: async function (key) {
        try {
            let value = await appCache.getItem(key);
            return value;
        } catch (e) {
            console.error(e);
        }

    },
    set: async function (key, value) {
        try {
            await appCache.setItem(key, value);
        } catch (e) {
            console.error(e)
        }

    },
    remove: async function (key) {
        await appCache.removeItem(key);
    }
}

export const authStore = {
    get: async function (key) {
        try {
            let value = await authCache.getItem(key);
            return value;
        } catch (e) {
            console.error(e);
        }

    },
    set: async function (key, value) {
        try {
            await authCache.setItem(key, value);
        } catch (e) {
            console.error(e)
        }

    },
    remove: async function (key) {
        await authCache.removeItem(key);
    }
}