//REACT
import { useState, useContext, useEffect, useCallback } from 'react'
import TaskContext from '../../context/taskContext';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ButtonGroup } from '@mui/material';
import Edit from '@mui/icons-material/Edit';


export default function TaskActions({
    actionHandler,
    withinRange,
    activityGrid,
    pointShuffled,
    taskActions,
    editMode,
    taskNotStarted,
    geolocation,
    rangeUnlocked
}) {
    const { task } = useContext(TaskContext);
    const [showDialog, setShowDialog] = useState();
    const [actionSelected, setActionSelected] = useState(0);
    const [enabledActions, setEnabledActions] = useState([0]);
    const [visibleActions, setVisibleActions] = useState([0]);

    const askConfirmation = async (index) => {
        setActionSelected(index);
        if (taskActions[index].needConfirmation)
            setShowDialog(true);
        else
            await actionHandler(index);
    }

    const hideDialog = () => {
        setShowDialog(false);
    }

    const handleEdit = () => {
        actionHandler(actionSelected);
    }

    const handleConfirm = async () => {
        setShowDialog(true);
        await actionHandler(actionSelected);
        hideDialog();
    }

    const evaluateDependencies = useCallback( (dependencies, values = arguments[0]) => {
        if (!dependencies || typeof dependencies !== 'object') {
            throw new Error('Invalid dependencies object');
        }

        if (!values || typeof values !== 'object') {
            throw new Error('Invalid values object');
        }

        let is = true;
        let isNot = true;

        if ('is' in dependencies) {
            let isOr = true;
            let isAnd = true;
            if ('or' in dependencies.is && Array.isArray(dependencies.is.or)) {
                 isOr = dependencies.is.or.some(dependency => values[dependency]);
            }
            if ('and' in dependencies.is && Array.isArray(dependencies.is.and)) {
                isAnd = dependencies.is.and.every(dependency => values[dependency]);
            }
            is = isOr && isAnd
        }
        if ('not' in dependencies) {
            let isNotOr = true;
            let isNotAnd = true;
            if ('or' in dependencies.not && Array.isArray(dependencies.not.or)) {
                 isNotOr = !dependencies.not.or.some(dependency => values[dependency]);
            }
            if ('and' in dependencies.not && Array.isArray(dependencies.not.and)) {
                isNotAnd = !dependencies.not.and.every(dependency => values[dependency]);
            }
            isNot = isNotOr && isNotAnd
        }
        return is && isNot
    }, []);

    useEffect(() => {

        if (taskActions) {
            let enabled = taskActions.map((action) => {
                let isEnabled = evaluateDependencies(action.enableDeps, arguments[0]);
                return isEnabled;
            });
            setEnabledActions(enabled)
            let visible = taskActions.map((action) => {
                let isVisible = evaluateDependencies(action.visibleDeps, arguments[0]);
                return isVisible;
            });
            setVisibleActions(visible)
        }

    }, [withinRange, pointShuffled, taskActions, taskNotStarted, editMode, activityGrid, geolocation, rangeUnlocked, evaluateDependencies])

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent={'center'}
            width={'100vw'}
            height={'75px'}
        >
            <Dialog
                open={showDialog || false}
            >
                <DialogTitle><Typography>{taskActions[actionSelected].name.toUpperCase()}</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {taskActions[actionSelected].dialog}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack flexGrow={1} spacing={2} direction='row' alignItems={'center'} justifyContent='center'>
                        <Button variant="outlined" color="primary" onClick={() => handleConfirm()}>ACEPTAR</Button>
                        <Button variant="contained" color="secondary" onClick={() => hideDialog()}>CANCELAR</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Stack flex flexDirection={'row'} alignItems={'center'} justifyContent='center' spacing={0} width={'80vw'} flexWrap={'nowrap'}>
                <ButtonGroup
                    fullWidth
                    variant='contained'
                    size='large'
                    disableElevation
                    sx={{ backgroundColor: 'white' }}
                >
                    {!editMode && task.taskStatusId === 0 && taskActions.map((buttonConfig, index) => {
                        return (visibleActions[index] &&
                            <Button
                                key={index}
                                disabled={!enabledActions[index]}
                                color={buttonConfig.color || 'primary'}
                                variant={buttonConfig.variant || 'contained'}
                                onClick={() => askConfirmation(index)}
                                endIcon={buttonConfig.icon || null}
                            >
                                {buttonConfig.name}
                            </Button>
                        )
                    })}
                    {
                        editMode && !task.taskStatusId !== 0 && !['confirmSite', 'socGenerateGrid', 'ltmGenerateGrid'].includes(task.type) &&
                        <Button
                            disabled={task.taskStatusId === 0}
                            color='primary'
                            onClick={() => handleEdit()}
                            endIcon={<Edit />}
                        >
                            Editar
                        </Button>
                    }
                </ButtonGroup>
            </Stack>
        </Box>
    )
}