import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomAlert({ show, title, message, icon, severity, handleClose }) {
  return (
    <Box sx={{ width: "100%", position: 'fixed', zIndex: '99999' }}>
      <Collapse in={show}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {title && <AlertTitle sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{title}</AlertTitle>}
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}