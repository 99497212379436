import { ruutsApi } from ".";
import moment from "moment";
//DB
import * as localDb from "../../services/localDb";

async function updateDbSyncStatus({element, changeId, changeStatus, monitoringEventId, syncStatusDetail}) {
    let db = await localDb.getDb();
    await db.transaction('rw', ['changelog', 'events'], (db) => {
        if (changeId) {
            db.changelog.update(changeId, {
                syncStatus: changeStatus,
                lastSyncDate: moment().format()
            })
        }
        db.events.update(monitoringEventId, {syncStatus: syncStatusDetail});
        element.dispatchEvent(new CustomEvent("dataSync", { detail: syncStatusDetail }));
    });
}

export default async function syncEventDataUp(changes, monitoringEventId, element) {

    function toggleCancel(e) {
        if (e.detail.monitoringEventId === monitoringEventId) {
            cancelSync = true
        }
    }
    let cancelSync = false;

    try {
        element.addEventListener("cancelDataSync", toggleCancel);
        let totalChanges = changes.length;

        if (totalChanges === 0) {
            element.dispatchEvent(
                new CustomEvent("dataSync", {
                    detail: {
                        monitoringEventId: monitoringEventId,
                        totalChanges: totalChanges,
                        syncedChanges: 0,
                        status: "complete",
                        timestamp: moment().format(),
                    },
                })
            );
        }

        for (let i = 0; i < totalChanges; i++) {
            if (cancelSync) {
                const syncStatusDetail = {
                    monitoringEventId: monitoringEventId,
                    totalChanges: totalChanges,
                    syncedChanges: i,
                    status: "cancelled",
                    timestamp: moment().format(),
                };
                await updateDbSyncStatus({element, monitoringEventId, syncStatusDetail});
                break;
            }
            let updatePayload = await localDb.getOne(changes[i].entity, changes[i].entityId);
            if (i === 0) {
                const syncStatusDetail = {
                    monitoringEventId: changes[i].monitoringEventId,
                    totalChanges: totalChanges,
                    syncedChanges: i,
                    status: "starting",
                    timestamp: moment().format(),
                };
                await updateDbSyncStatus({element, monitoringEventId, syncStatusDetail});
            }
            let apiAction = changes[i].type === "put" ? "postApiData" : "updateApiData";
            let apiPayload = changes[i].type === "put" ? updatePayload : { id: updatePayload.id, data: updatePayload };
            await ruutsApi[apiAction](ruutsApi.endpoints[changes[i].entity], apiPayload)
                .then(async function (result) {
                    const syncStatusDetail = {
                        monitoringEventId: changes[i].monitoringEventId,
                        totalChanges: totalChanges,
                        syncedChanges: i + 1,
                        status: i + 1 >= totalChanges ? "complete" : "inprogress",
                        timestamp: moment().format(),
                    };
                    await updateDbSyncStatus({element, changeId: changes[i].id, changeStatus: 'succcess', monitoringEventId, syncStatusDetail});
                })
                .catch(async function (error) {
                    const syncStatusDetail = {
                        monitoringEventId: changes[i].monitoringEventId,
                        totalChanges: totalChanges,
                        syncedChanges: i,
                        status: "failed",
                        message: error.message,
                        timestamp: moment().format(),
                    };
                    await updateDbSyncStatus({element, changeId: changes[i].id, changeStatus: 'failed', monitoringEventId, syncStatusDetail});
                    throw new Error(error.message);
                });
        }
        return element.removeEventListener("cancelDataSync", toggleCancel);
    } catch (error) {
        console.error(error);
        const syncStatusDetail = {
            monitoringEventId: monitoringEventId,
            totalChanges: null,
            syncedChanges: null,
            status: "failed",
            message: error.message,
            timestamp: moment().format(),
        };
        await updateDbSyncStatus({element, monitoringEventId, syncStatusDetail});
        element.removeEventListener("cancelDataSync", toggleCancel);
        throw new Error(`Unable to perform data sync: ${error.message}`);
    }
}
