import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Logout from '@mui/icons-material/Logout';
import Link from '@mui/material/Link';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { useContext } from 'react';

import AppContext from '../../context/appContext';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
export default function AccountMenu({ anchorEl, handleClose, open, authenticated, handleLogin, handleLogout}) {
    const { setShowSettings, handleUpgrade, setShowBackups } = useContext(AppContext);

    const handleSettings = () => {
        setShowSettings(true);
        handleClose();
    }

    const handleBackups = () => {
        setShowBackups(true);
        handleClose();
    }

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 0,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 0,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 8,
                        width: 6,
                        height: 8,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {authenticated ?
                <MenuItem>
                    <ListItemIcon>
                        <HelpOutlineOutlinedIcon />
                    </ListItemIcon>
                    <Link color='inherit' target={'_blank'} href="https://share.hsforms.com/1JLOc-3huQviHqJDhNl7XjQd7qz7" underline="none">
                        Ayuda
                    </Link>
                </MenuItem>
                :
                <MenuItem onClick={handleLogin}>
                    <Typography variant='body2'>Login</Typography>
                </MenuItem>
            }
            {authenticated &&
                <div>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='body2'>Logout</Typography>
                    </MenuItem>
                </div>
            }
            <MenuItem onClick={() => handleSettings()}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <Typography component='div' sx={{ textAlign: 'right' }} variant='body2'>Configurar</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleBackups()}>
                <ListItemIcon>
                    <SettingsBackupRestoreIcon fontSize="small" />
                </ListItemIcon>
                <Typography component='div' sx={{ textAlign: 'right' }} variant='body2'>Backups</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleUpgrade({manual: true})}>
                <ListItemIcon>
                    <SystemUpdateAltIcon fontSize="small" />
                </ListItemIcon>
                <Typography component='div' sx={{ textAlign: 'right' }} variant='body2'>Actualizar</Typography>
            </MenuItem>
            <MenuItem>
                <Typography component='div' sx={{ textAlign: 'right' }} variant='body2'>Version {process.env.REACT_APP_VERSION}</Typography>
            </MenuItem>
        </Menu>
    );
}