import { v4 as UUIDV4 } from 'uuid';

export default function ltmTasks(site, userName, monitoringEventId, monitoringActivityId) {
    return (
        [
            {
                id: UUIDV4(),
                key: `${site.name.toUpperCase()}-CONFIRMAR-SITIO`,
                order: 0,
                enabled: true,
                actionRangeMts: 10,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: site.locationConfirmed ? 2 : 0,
                formName: '',
                type: 'confirmSite',
                actualLocation: site.actualLocation || [],
                plannedLocation: site.plannedLocation,
                dependencies: [],
                description: 'Confirmar sitio de monitoreo',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName,
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-PROYECTAR-GRILLA`,
                order: 1,
                enabled: true,
                actionRangeMts: 3,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: '',
                type: 'ltmGenerateGrid',
                actualLocation: [],
                plannedLocation: site.actualLocation,
                dependencies: [0],
                description: 'Instalar monitor',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName,
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-FOTOGRAFÍAS`,
                order: 2,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmSitePictures',
                type: 'ltmSitePictures',
                gridPositionKey: 'P1',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1],
                description: 'Fotografías del sitio',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName,
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-PERFIL-SUELOS`,
                order: 3,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmSoilProfile',
                type: 'ltmSoilProfile',
                //gridPositionKey: 'INFILT-1',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Perfil de suelos',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-INFILTROMETRO-1`,
                order: 4,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmInfiltrometer',
                type: 'ltmInfiltrometer',
                gridPositionKey: 'INFILT-1',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Infiltrómetro 1',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-INFILTROMETRO-2`,
                order: 5,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmInfiltrometer',
                type: 'ltmInfiltrometer',
                gridPositionKey: 'INFILT-2',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Infiltrómetro 2',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-INFILTROMETRO-3`,
                order: 6,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmInfiltrometer',
                type: 'ltmInfiltrometer',
                gridPositionKey: 'INFILT-3',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Infiltrómetro 3',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-INFILTROMETRO-4`,
                order: 7,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmInfiltrometer',
                type: 'ltmInfiltrometer',
                gridPositionKey: 'INFILT-4',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Infiltrómetro 4',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-BIODIVERSIDAD-T1`,
                order: 8,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmBiodiversity',
                type: 'ltmBiodiversityT1',
                gridPositionKey: 'TRANSECT-1-START',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Biodiversidad lectura T1',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-BIODIVERSIDAD-T2`,
                order: 9,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmBiodiversity',
                type: 'ltmBiodiversityT2',
                gridPositionKey: 'TRANSECT-2-START',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Biodiversidad lectura T2',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-BAF-1`,
                order: 10,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmBiodiversityFlexibleAreaStep1',
                type: 'ltmBiodiversityFlexibleAreaStep1',
                gridPositionKey: 'TRANSECT-2-START',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Biodiversidad Area Flexible',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-BAF-2`,
                order: 11,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmBiodiversityFlexibleAreaStep2',
                type: 'ltmBiodiversityFlexibleAreaStep2',
                gridPositionKey: 'TRANSECT-2-START',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Biodiversidad Area Extendida',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-BIODIVERSIDAD-T3`,
                order: 12,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'LtmBiodiversityT3',
                type: 'ltmBiodiversityT3',
                gridPositionKey: 'TRANSECT-3-START',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Biodiversidad lectura T3',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            },
            {
                id: UUIDV4(),
                key: `LTM-${site.name.toUpperCase()}-ISE`,
                order: 13,
                enabled: true,
                actionRangeMts: null,
                monitoringSiteId: site.id,
                monitoringActivityId: monitoringActivityId,
                monitoringEventId: monitoringEventId,
                taskStatusId: 0,
                formName: 'StmIseForm',
                type: 'ltmIse',
                gridPositionKey: 'TRANSECT-3-START',
                actualLocation: [],
                plannedLocation: [],
                dependencies: [0, 1, 2],
                description: 'Evaluación ISE',
                dataPayload: null,
                pictures: null,
                updatedBy: null,
                createdBy: userName
            }
        ]
    )
}

