//MUI
import { Stack, Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';

//Components

import MyEvents from '../MyEvents/MyEvents';

//Services
import { StorageStatusIndicator } from '../StorageStatusIndicator/StorageStatusIndicator';
import { LocationStatusIndicator } from '../LocationStatusIndicator/LocationStatusIndicator';
import { WifiIconIndicator } from '../WifiIconIndicator/WifiIconIndicator';
import { UserInformationButton } from '../UserInformationButton/UserInformationButton';


export default function Home() {
    return (
        <Stack display='flex' height='100%' flexGrow={1} width='100%'>
            <MyEvents />
            <Paper sx={{ height: '80px', position: 'fixed', bottom: 0, left: 0, right: 0, paddingBottom: 1, zIndex: 99}} elevation={3}>
                <BottomNavigation
                    showLabels
                >
                    <BottomNavigationAction disableRipple={true} disableTouchRipple={true} label="Ubicación" icon={<LocationStatusIndicator />} />
                    <BottomNavigationAction disableRipple={true} disableTouchRipple={true} label="Conectividad" icon={<WifiIconIndicator />} />
                    <BottomNavigationAction disableRipple={true} disableTouchRipple={true} label="Almacenamiento" icon={<StorageStatusIndicator />} />
                    <BottomNavigationAction disableRipple={true} disableTouchRipple={true} label="Usuario" icon={<UserInformationButton />} />
                </BottomNavigation>
            </Paper>
        </Stack>
    );
} 