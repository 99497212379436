import RejectSite from './common/RejectSite';
import SocGridSampling from './soc/SocGridSampling';
import SocSampleSummary from './soc/SocSampleSummary';
import SocSiteDescription from './soc/SocSiteDescription';
import DapSample from './soc/DapSample';
import StmForagePictures from './stm/StmForagePictures';
import StmIseForm from './stm/StmIseForm';
import LtmSitePictures from './ltm/LtmSitePictures';
import LtmBiodiversity from './ltm/LtmBiodiversity';
import LtmInfiltrometer from './ltm/LtmInfiltrometer';
import LtmBiodiversityFlexibleAreaStep1 from './ltm/LtmBiodiversityFlexibleAreaStep1';
import LtmBiodiversityFlexibleAreaStep2 from './ltm/LtmBiodiversityFlexibleAreaStep2';
import LtmBiodiversityT3 from './ltm/LtmBiodiversityT3';
import LtmSoilProfile from './ltm/LtmSoilProfile';
import CreateValidationSite from './fbc/CreateValidationSite';
import OverrideSiteConfirmation from './common/OverrideSiteConfirmation';
import ManualGridLocation from './common/ManualGridLocation';

export const taskForms = {
    RejectSite,
    SocGridSampling,
    SocSampleSummary,
    SocSiteDescription,
    DapSample,
    StmForagePictures,
    StmIseForm,
    LtmSitePictures,
    LtmBiodiversity,
    LtmInfiltrometer,
    LtmBiodiversityFlexibleAreaStep1,
    LtmBiodiversityFlexibleAreaStep2,
    LtmBiodiversityT3,
    LtmSoilProfile,
    CreateValidationSite,
    OverrideSiteConfirmation,
    ManualGridLocation
}