//import downloadMonitoringData from './downloadMonitoringData.js';
import downloadMonitoringData from './downloadMonitoringData.js';
import getReferenceData from './getReferenceData.js';
import getApiData from './getApiData.js';
import updateApiData from './updateApiData.js';
import postApiData from './postApiData.js';
import syncEventDataUp from './syncEventDataUp.js'
import endpoints from './endpoints.js';

export const ruutsApi = {
    downloadMonitoringData,
    getReferenceData,
    getApiData,
    updateApiData,
    postApiData,
    syncEventDataUp,
    endpoints
}