import { splitCoordsString } from "./splitCoords";
import { validateInputStringCoordinates } from "./validateInputStringCoordinates";

export function latLngToArray(position) {

    try {
        if (position === null || position === undefined) {
            return null
        }
        if (Array.isArray(position) && position.length === 2) {
            return position;
        }
        if ('string' === typeof position) {
            if (validateInputStringCoordinates(position)) {
                return splitCoordsString(position)
            }
        }
        if (typeof position === 'object') {
            const keys = Object.keys(position).map(k => k.toLowerCase());
            if (keys.includes('lat') && keys.includes('lng')) {
                return [position.lat, position.lng]
            }
        }
    } catch (error) {
        console.error(error)
        throw new Error ('Invalid coordinates input')
    }
}