import * as localDb from '../localDb';
import { saveAs } from 'file-saver';
import imageCompression from 'browser-image-compression';
import getReport from '../monitoring/getReport';

const JSzip = require('jszip')

export default async function downloadEventData(eventId) {
    try {
        const [event] = await localDb.getMany('events').then(result => result.where({ id: eventId }).toArray());
        const farm = await localDb.getOne('farms', event.farmId);
        const pictures = await localDb.getMany('pictures').then(result => result.where({ monitoringEventId: eventId }).toArray());

        const zip = new JSzip();

        const report = await getReport(eventId);

        const picturesZipFolder = zip.folder('fotos');
        const pictureLinks = []

        for (let picture of pictures) {
            if (picture.data) {
                picturesZipFolder.file(`${picture.name}`, imageCompression.getFilefromDataUrl(picture.data), {base64: true});
            }

            if(picture.link) {
                pictureLinks.push(picture.link)
            }
        }
        if (pictureLinks.length) {
            picturesZipFolder.file('links.txt', pictureLinks.reduce((links, current) => links = links + '\n'+ current));
        }
        zip.file(`reporte_${farm.name}_${event.date}.xlsx`, report);
        
        zip.generateAsync({type:"blob"}).then(function(content) {
            return saveAs(content, `${event.name}-report.zip`);
        });
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to download data: ${error.message}`)
    }
}