import { v4 as UUIDV4 } from 'uuid';

export default function socTasks(site, userName, monitoringEventId, monitoringActivityId) {
  return (
    [
      {
        id: UUIDV4(),
        key: `${site.name.toUpperCase()}-CONFIRMAR-SITIO`,
        order: 0,
        enabled: true,
        actionRangeMts: 10,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        taskStatusId: site.locationConfirmed ? 2 : 0,
        formName: '',
        type: 'confirmSite',
        actualLocation: site.actualLocation || [],
        plannedLocation: site.plannedLocation,
        dependencies: [],
        description: 'Confirmar sitio de monitoreo',
        dataPayload: null,
        pictures: null,
        updatedBy: null,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `DAP-${site.name.toUpperCase()}-1`,
        order: 1,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Densidad aparente",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [0],
        formName: "DapSample",
        type: "dapSample",
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `SOC-${site.name.toUpperCase()}-PROYECTAR-GRILLA`,
        order: 2,
        enabled: true,
        actionRangeMts: 3,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        taskStatusId: 0,
        formName: '',
        type: 'socGenerateGrid',
        actualLocation: [],
        plannedLocation: site.actualLocation,
        dependencies: [0],
        description: 'Instalar monitor',
        dataPayload: null,
        pictures: null,
        updatedBy: null,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `SOC-${site.name.toUpperCase()}-P01`,
        order: 3,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Punto 1",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [2],
        formName: "SocGridSampling",
        type: "socGridSampling",
        gridPositionKey: 'P1',
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `SOC-${site.name.toUpperCase()}-P02`,
        order: 4,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Punto 2",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [2],
        formName: "SocGridSampling",
        type: "socGridSampling",
        gridPositionKey: 'P2',
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `SOC-${site.name.toUpperCase()}-P03`,
        order: 5,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Punto 3",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [2],
        formName: "SocGridSampling",
        type: "socGridSampling",
        gridPositionKey: 'P3',
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `SOC-${site.name.toUpperCase()}-P04`,
        order: 6,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Punto 4",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [2],
        formName: "SocGridSampling",
        type: "socGridSampling",
        gridPositionKey: 'P4',
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      },
      {
        id: UUIDV4(),
        key: `SOC-${site.name.toUpperCase()}-SUMMARY`,
        order: 7,
        actionRangeMts: null,
        monitoringSiteId: site.id,
        monitoringActivityId: monitoringActivityId,
        monitoringEventId: monitoringEventId,
        description: "Pesaje y comentarios",
        actualLocation: [],
        plannedLocation: [],
        dependencies: [1, 2, 3, 4, 5],
        formName: "SocSampleSummary",
        type: "socSampleSummary",
        enabled: false,
        taskStatusId: 0,
        createdBy: userName,
      }
    ]
  )
}

