import generateUtmGrid from "../geolocationScripts/generateUtmGrid";

export function generateRelativeGrid ({position, activity, setTempGrid}) {
    try {
        let gridGeoJson = generateUtmGrid(position, activity.activityLayoutId);
        setTempGrid(gridGeoJson);
    } catch (error) {
        console.error(error);
        throw new Error (`Unable to project grid: ${error.message}`);
    }
}