import { useEffect, useState, useContext, useCallback } from 'react';


//Context
import AppContext from '../../context/appContext.js';


//DB
import * as localDb from '../../services/localDb'

//services
import statusColorCoder from '../../services/helpers/statusColorCoder';

//Router
import { useNavigate } from 'react-router-dom';

//MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

//Icons
import PlayCircleOutlined from '@mui/icons-material/PlayCircleOutlined';
import Edit from '@mui/icons-material/Edit';

//Services
import refDataById from '../../services/localRefData/refDataById';
import checkTaskDependencies from '../../services/helpers/checkTaskDependencies';
import DrawerHeaderBar from '../DrawerHeaderBar/DrawerHeaderBar';
import generateTasks from '../../services/monitoring/generateTasks';
import refDataByKey from '../../services/localRefData/refDataByKey.js';

export default function ActivitySummary(props) {
  const { setAlert, currentUser } = useContext(AppContext);
  const [tasks, setTasks] = useState();
  const [workflow, setWorkflow] = useState();
  const navigate = useNavigate();

  const handleTaskClick = (taskData, mode) => {
    localStorage.setItem('currentEventId', props.activity.monitoringEventId);
    localStorage.setItem('currentActivityId', props.activity.id);
    localStorage.setItem('currentSiteId', props.activity.monitoringSiteId);
    navigate(`/tasks/${taskData.id}?${mode === 'edit' ? 'edit=true' : ''}`, {replace: true});
  }

  const createTasks = async () => {
    if (!workflow.allowRepetitiveTasks) {
      throw new Error('This activity does not allow new tasks');
    }
    try {
      let newTasks = await generateTasks(null, currentUser.userName, props.activity.monitoringWorkflowId, props.activity.monitoringEventId, props.activity.id, { amountOfSites: 1, startOrder: tasks.length })
      let activityUpdate = props.activity;
      activityUpdate.totalTasks += newTasks.length;
      await localDb.update('activities', activityUpdate.id, activityUpdate);
      await localDb.bulkPut('tasks', newTasks);
      setAlert({ show: true, severity: 'success', message: 'Tarea generada con éxito', action: () => getTasks() })
    } catch (e) {
      console.error(e)
      throw new Error(e.message);
    }
  }

  const handleNewTask = async () => {
    try {
      setAlert({ show: true, severity: 'warning', message: `Desea agregar una nueva tarea?`, actionButtonTitle: 'CREAR', action: () => createTasks(), cancelButtonTitle: 'CANCEL' });
    } catch (e) {
      console.error(e);
      setAlert({ show: true, severity: 'error', message: `No se pudo generar la tarea. Error: ${e.message}` })
    }
  }

  const getTasks = useCallback(async () => {
    try {
      let taskList = await localDb.getMany('tasks').then(result => result.where({ monitoringActivityId: props.activity.id }).sortBy('order'));
      await checkTaskDependencies(props.activity.monitoringWorkflowId, taskList);
      setTasks(taskList);
    } catch (error) {
      console.error(error.message);
    }
  }, [props])

  useEffect(() => {
    let [workflowData] = refDataByKey('workflows').filter(w => w.id === props.activity.monitoringWorkflowId);
    setWorkflow(workflowData);
    getTasks();
  }, [props.activity, getTasks]);



  return (
    <Box>
      <Drawer
        PaperProps={{ sx: { borderRadius: '10px 10px 0 0' } }}
        anchor='right'
        open={props.open}
        onClose={() => props.handleClose()}
      >
        <Stack display='flex' flexGrow={1} justifyItems='flex-start' alignContent={'space-between'} sx={{ height: '95vh', width: '100vw', borderRadius: '10px 10px 0 0' }}>
          <DrawerHeaderBar title={props.activity.key} handleClose={props.handleClose} />
          <Divider />
          <Paper elevation={0} sx={{ maxHeight: '90vh', overflow: 'auto' }}>
            <List>
              {tasks?.map((task, index) => {
                return (
                  <div key={index}>
                    <ListItem>
                      <ListItemText primary={task.description} secondary={refDataById('taskStatus', task.taskStatusId)} primaryTypographyProps={{ color: task.enabled ? 'default' : '#9e9e9e' }} secondaryTypographyProps={{ color: statusColorCoder(task.taskStatusId) }} />
                      <ListItemSecondaryAction>
                        {[1,2].includes(task.taskStatusId) && !['confirmSite', 'socGenerateGrid', 'ltmGenerateGrid'].includes(task.type) &&
                          <IconButton
                            disabled={['confirmSite', 'socGenerateGrid', 'ltmGenerateGrid'].includes(task.type)}
                            onClick={() => handleTaskClick(task, 'edit')}
                          >
                            <Edit color={['confirmSite', 'socGenerateGrid', 'ltmGenerateGrid'].includes(task.type) ? 'disabled' : 'primary'} sx={{ fontSize: '26px' }} />
                          </IconButton>
                        }
                        {
                          task.enabled && task.taskStatusId === 0 &&
                          <IconButton disabled={!task.enabled} onClick={() => handleTaskClick(task)}>
                            <PlayCircleOutlined color={task.enabled ? 'primary' : 'disabled'} sx={{ fontSize: '32px' }} />
                          </IconButton>
                        }
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Box pr={2} pl={2}><Divider /> </Box>
                  </div>
                )
              }
              )}
            </List>
          </Paper>
          <Stack direction={'row'} justifySelf='flex-end' justifyContent={'center'} p={2} spacing={2}>
            {
              workflow?.allowRepetitiveTasks &&
              <Button onClick={() => handleNewTask()}>+ TAREA</Button>
            }
            <Button onClick={() => props.handleClose()}>CERRAR</Button>
          </Stack>
        </Stack>
      </Drawer>
    </Box >
  )
}
