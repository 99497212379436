import { validateInputStringCoordinates } from "./validateInputStringCoordinates";
import { roundNumber } from "../helpers/roundNumber";
export function splitCoordsString (position, decimals = null) {
    if (!position) {
        throw new Error(`Missing position`);
    }
    if (Array.isArray(position)) {
        throw new Error('Position is already an array. Must be a string')
    }
    if(typeof position !== 'string') {
        throw new Error('Position needs to be a string')
    }
    if (!validateInputStringCoordinates(position)) {
        throw new Error ('Invalid coordinates in the provided string')
    }
    let stringLatLng = position.split(',');
    let lat = decimals ? roundNumber(parseFloat(stringLatLng[0]), decimals): parseFloat(stringLatLng[0]);
    let lng = decimals ? roundNumber(parseFloat(stringLatLng[1]), decimals): parseFloat(stringLatLng[1]);
    return [lat, lng]
}