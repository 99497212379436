import { useEffect, useState, useContext } from 'react';
import requestPermissions from '../../services/helpers/requestPermissions';
import LocationOn from '@mui/icons-material/LocationOn'
import LocationOff from '@mui/icons-material/LocationOff';
import AppContext from '../../context/appContext';


export function LocationStatusIndicator() {
    const [locationAccess, setlocationAccess] = useState();
    const { setAlert } = useContext(AppContext);


    const geolocationPermissionCheck = async () => {
        try {
            let geolocationAccess = await requestPermissions('geolocation');
            if (geolocationAccess.state === 'prompt') {
                setlocationAccess(false);
            } else if (geolocationAccess.state === 'denied') {
                setlocationAccess(false);
            } else if (geolocationAccess.state === 'granted') {
                setlocationAccess(true)
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }
    const promptGeo = () => {
        navigator.geolocation.getCurrentPosition(locationSuccess, locationError, { maximumAge: 1000, timeout: 60000 });
    }
    const locationSuccess = (position) => {
        setlocationAccess(true);
        setAlert({show: true, severity: 'success', message: `Geolocalización habilitada.Precisión actual: ${position.coords.accuracy.toFixed(2)} m`})
    }

    const locationError = (error) => {
        setlocationAccess(false);
        setAlert({show: true, severity: 'success', message: `Error al habilitar geolocalización.`})
    }

    useEffect(() => {
        geolocationPermissionCheck();
    }, []);

    return (
        locationAccess ?
                <LocationOn onClick={promptGeo} color='primary'/>
            :
                <LocationOff onClick={promptGeo} color='warning'/>
    )
}