import { Auth0Client } from '@auth0/auth0-spa-js';
import { getConfig } from "../../configAuth.js";
import { authStore } from '../lfStorage/lfStorage.js'

let config = getConfig(process.env.REACT_APP_ENV);

const auth0Client = new Auth0Client({
    domain: config.domain,
    clientId: config.clientId,
    cache: authStore,
    useRefreshTokens: true,
    authorizeTimeoutInSeconds: 30,
    authorizationParams: {
        audience: config.audience,
        useRefreshTokens: true,
    }
});

export default auth0Client;