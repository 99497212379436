import { getTaskDependencies } from "../monitoring/getTaskDependencies";

export default async function checkTaskDependencies(workflowId, tasks) {
    try {
        for (let task of tasks) {
            let deps = getTaskDependencies(workflowId, task.order, tasks.length);
            if (deps.length) {
                let dependenciesCompleted = deps.reduce((acc, curr) => {
                    acc = acc && ([2,4].includes(tasks[curr].taskStatusId)); //2=complete, 4=omitido
                    return acc;
                }, true);
                if (dependenciesCompleted) task.enabled = true
                else task.enabled = false;
            }
        }
    } catch (error) {
        console.error(`Unable to check dependencies: ${error.message}`);
    }
}