export const stmIseFields = [
    {
        label: 'Abundacia canopeo',
        name: 'liveCanopyAbundance',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Organismos vivos',
        name: 'livingOrganisms',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Pastos de verano',
        name: 'warmSeasonGrasses',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Pastos de invierno',
        name: 'coolSeasonGrasses',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Hierbas y leguminosas',
        name: 'forbsAndLegumes',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Árboles y arbustos',
        name: 'treesAndShrubs',
        min: -10,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Especies raras',
        name: 'desirableRareSpecies',
        min: 0,
        max: 10,
        default: 0,
        lastValue: 0,
        enabled: false
    },
    {
        label: 'Especies indeseables',
        name: 'nonDesirableRareSpecies',
        min: -10,
        max: 0,
        default: 0,
        enabled: false
    },
    {
        label: 'Mantillo',
        name: 'litterAbundance',
        min: 0,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Incorp. Mantillo',
        name: 'litterDecomposition',
        min: 0,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Desap. Bosta',
        name: 'dungDecomposition',
        min: 0,
        max: 10,
        default: 0,
        enabled: false
    },
    {
        label: 'Suelo desnudo',
        name: 'bareSoil',
        min: -20,
        max: 20,
        default: 0,
        enabled: false
    },
    {
        label: 'Encostramiento',
        name: 'capping',
        min: -10,
        max: 0,
        default: 0,
        enabled: false
    },
    {
        label: 'Erosion eólica',
        name: 'windErosion',
        min: -20,
        max: 0,
        default: 0,
        enabled: false
    },
    {
        label: 'Erosion hídrica',
        name: 'waterErosion',
        min: -20,
        max: 0,
        default: 0,
        enabled: false
    }
]