import getBackupsDirHandle from "./getBackDirHandle";

const userBackupRegex = /^user_backup_.+\.json$/;
const autoBackupRegex = /^auto_backup\.json$/;
const uploadedBackupRegex = /^uploaded_backup\.json$/;

function identifyBackupType(fileName) {
    if (userBackupRegex.test(fileName)) {
        return 'User';
    } else if (autoBackupRegex.test(fileName)) {
        return 'Auto';
    } else if (uploadedBackupRegex.test(fileName)) {
        return 'Uploaded'
    } else {
        return 'Unknown';
    }
}

export default async function listBackups() {
    try {
        // Get handle to draft file in OPFS
        
        const backupsDirHandle = await getBackupsDirHandle()

        let files = [];

        for await (const entry of backupsDirHandle.values()) {
            if (entry.kind === 'file') {
                const fileHandle = await backupsDirHandle.getFileHandle(entry.name);
                // Get the file
                const file = await fileHandle.getFile();
                // Calculate the size in MB
                const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
                // Add file to the list
                files.push({
                    fileName: entry.name, 
                    size: sizeInMB, 
                    handle: fileHandle, 
                    date: file.lastModified,
                    type: identifyBackupType(entry.name)
                });
            }
        }

        return files.sort((a, b) => new Date(b.date) - new Date(a.date))
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to list backups ${error.message}`);
    }
}
