import { useContext, useState } from 'react';

//Context
import AppContext from '../../context/appContext';

//Router
import { useLocation, useNavigate } from "react-router-dom";

//Components
import AppMenu from '../AppMenu/AppMenu';
import ToolbarFilters from '../ToolbarFilters/ToolbarFilters';

//MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';


//Icons
import MoreVert from '@mui/icons-material/MoreVert';
import ArrowBack from '@mui/icons-material/ArrowBack';

//Images
import ruutsAppBarLogo from '../../assets/ruutsAppBarLogo.png';

//Styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { appBarTheme } from '../../themes/appBar';

import { useAuth0, OAuthError } from "@auth0/auth0-react";

//Theme for this component if needed
const theme = createTheme(appBarTheme)
const TOOLBAR_HEIGHT = '70px';

export default function CustomAppBar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const navigate = useNavigate();
    const {
        appBarTitle,
        showAppBar,
        navBackTarget,
        showFilters,
        filtersConfig,
        setShowFilters,
        loadingSomething
    } = useContext(AppContext);



    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
        getAccessTokenSilently
    } = useAuth0();

    const handleLogin = async () => {
        if (!user) {
            try {
                console.log('Need authentication - Attempt silent')
                await getAccessTokenSilently({ detailedResponse: true, ignoreCache: true });
            } catch (error) {
                console.error(error);
                if (error instanceof OAuthError && error.message === 'Login required' && window.navigator.onLine) {
                    loginWithRedirect();
                }
            }
        };
    }

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const locationUpdate = (location) => {
        switch (true) {
            case "/":
                return `Eventos ${process.env.REACT_APP_ENV !== 'production' ? '(' + process.env.REACT_APP_ENV + ')' : ''}`
            case /\/events/.test(location.pathname):
                return 'Eventos'
            case /tasks/.test(location.pathname):
                return 'Tareas'
            default:
                return `Eventos ${process.env.REACT_APP_ENV !== 'production' ? '(' + process.env.REACT_APP_ENV + ')' : ''}`
        }
    }
    const handleNavBack = () => {
        setShowFilters(false);
        navigate(navBackTarget || -1, {replace: true});
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (showAppBar &&
        <ThemeProvider theme={theme}>
            <AppBar id='appbar' position='sticky' elevation={3}>
                <Toolbar disableGutters id='appbar-title' sx={{ justifyContent: 'space-between', height: TOOLBAR_HEIGHT }}>
                    <IconButton
                        size="large"
                        edge="end"
                        color='inherit'
                        disabled={location.pathname === '/'}
                        onClick={handleNavBack}
                    >
                        <ArrowBack 
                            color={location.pathname === '/' ? "primary" : "#FFFF"}

                        fontSize='35px' />
                    </IconButton>
                    
                    {
                        location.pathname === '/' ?
                            <div sx={{ mt: 1 }}>
                                <img height='46px' alt='ruuts logo' src={ruutsAppBarLogo} />
                            </div>
                            :
                            <Typography
                                variant='headerBold'
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { sm: 'block' } }}
                                align="center"
                            >
                                {(appBarTitle || locationUpdate(location)).toUpperCase()}
                            </Typography>
                    }
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        edge='start'
                        //sx={{ ml: 2 }}
                        onClick={handleClick}
                    >
                        <MoreVert fontSize='35px' />
                    </IconButton>
                    {anchorEl &&
                        <AppMenu
                            authenticated={isAuthenticated}
                            handleLogin={handleLogin}
                            handleLogout={logoutWithRedirect}
                            anchorEl={anchorEl}
                            open={open}
                            handleClose={handleClose} />
                    }
                </Toolbar>
                {location.pathname === '/' ?
                    <Toolbar disableGutters sx={{ mt: 1, height: TOOLBAR_HEIGHT }}>
                        <Typography
                            variant='headerBold'
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { sm: 'block' } }}
                            align="center"
                        >
                            {locationUpdate(location)}
                        </Typography>
                    </Toolbar> : null}
                {
                    showFilters && filtersConfig &&
                    <ToolbarFilters
                        height={TOOLBAR_HEIGHT}
                        mainFilter={filtersConfig.mainFilter}
                        secondaryFilter={filtersConfig.secondaryFilter}
                    />
                }
                {loadingSomething &&
                    <LinearProgress color='secondary' />
                }
            </AppBar>
        </ThemeProvider >
    );
}
