import { useEffect, useState, useContext, useCallback, useRef } from "react";
import * as localDb from "../../services/localDb";

//Context
import AppContext from "../../context/appContext";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CircularProgress, Chip } from "@mui/material";
import { ApplicationError } from "../../models/errors";
import createBackup from "../../services/backup/createBackup";
import { warningMessages } from "../../models/warnings/warningMessages";

export function SyncIndicator({ eventData, handleUpload }) {
    const ref = useRef(null);
    const { isOnline, errorCatcher, setLoadingAlert, locale, setAlert } = useContext(AppContext);
    const [changes, setChanges] = useState();
    const [notSynced, setNotSynced] = useState(0);
    const [syncStatus, setSyncStatus] = useState(eventData.syncStatus);

    const getProps = () => {
        if (syncStatus?.status === "cancelled" && changes?.length) {
            return {
                icon: <CloudUploadIcon />,
                color: "warning",
                disabled: !isOnline,
                label: `Subir ${changes.length} cambio${changes.length ? "s" : ""}`,
                onClick: () => handleSync(eventData),
            };
        }

        if (syncStatus?.status === "failed") {
            return {
                icon: <CircularProgress size={"small"} />,
                color: "error",
                disabled: !isOnline,
                label: "Error - Click para reintentar",
                onClick: () => handleSync(eventData),
            };
        }
        if (syncStatus?.status === "inprogress") {
            return {
                icon: <CircularProgress size={"small"} />,
                color: "primary",
                label: `Subiendo ${((syncStatus.syncedChanges / syncStatus.totalChanges) * 100).toFixed(0)}% - Click para cancelar`,
                onClick: () => handleCancelSync(),
            };
        }
        if (!changes?.length) {
            return {
                color: "success",
                label: "Sin cambios",
            };
        }
        if (changes?.length) {
            return {
                icon: <CloudUploadIcon />,
                color: "warning",
                disabled: !isOnline,
                label: `Subir ${changes.length} cambio${changes.length ? "s" : ""}`,
                onClick: () => handleSync(eventData),
            };
        }

        return null;
    };

    const getChangesFromDb = useCallback(
        async function getChangesFromDb() {
            try {
                let changes = await localDb.getPendingChanges(eventData.id);
                let uniqueTasks = new Set(
                    changes
                        .filter((c) => {
                            return c.entity === "tasks" && ["not-synced", "failed"].includes(c.syncStatus);
                        })
                        .map((c) => c.entityId)
                );
                setNotSynced(uniqueTasks);
                setChanges(changes);
            } catch (error) {
                console.error(error);
                errorCatcher(new ApplicationError(error.message));
            }
        },
        [eventData, errorCatcher, syncStatus]
    );

    const handleSync = async (eventData) => {
        setAlert({
            ...warningMessages.createBackup[locale],
            action: async () => {
                await createBackup({ type: "auto", setLoadingAlert: setLoadingAlert, downloadWhenComplete: true });
                await handleUpload(changes, eventData, window);
                await getChangesFromDb();
            },
            cancelAction: async () => {
                await handleUpload(changes, eventData, window);
                await getChangesFromDb();
            },
        });
    };

    const handleCancelSync = useCallback(
        async function handleCancelSync() {
            await localDb.update("events", eventData.id, { id: eventData.id, syncStatus: { status: "cancelled" } });
            window.dispatchEvent(new CustomEvent("cancelDataSync", { detail: { monitoringEventId: eventData.id } }));
            await getChangesFromDb();
            setSyncStatus({ status: "cancelled" });
        },
        [getChangesFromDb, eventData]
    );

    const getSyncStatusFromDb = useCallback(async () => {
        let updatedEventData = await localDb.getOne("events", eventData.id);
        if (updatedEventData.syncStatus) {
            setSyncStatus((prev) => updatedEventData.syncStatus);
        }
    }, [eventData]);

    const updateSyncActivity = useCallback(
        async (e) => {
            try {
                if (e.detail.monitoringEventId === eventData.id) {
                    getSyncStatusFromDb();
                }
            } catch (error) {
                console.error(error);
                errorCatcher(new ApplicationError(error.message));
            }
        },
        [eventData, errorCatcher, getSyncStatusFromDb]
    );

    useEffect(() => {
        if (changes && changes.length) {
            window.addEventListener("dataSync", updateSyncActivity);
        }
        return () => {
            window.removeEventListener("cancelDataSync", handleCancelSync);
            window.removeEventListener("dataSync", updateSyncActivity);
        };
    }, [updateSyncActivity, handleCancelSync, eventData, changes]);

    useEffect(() => {
        if (eventData) {
            getChangesFromDb();
        }
    }, [eventData, getChangesFromDb]);

    return eventData && changes && <Chip ref={ref} variant='filled' size='small' {...getProps()} />;
}
