//React
import { useState, useContext, useEffect } from 'react';

import listBackups from '../../services/backup/listBackups.js';

//DB

//MUI
import Drawer from '@mui/material/Drawer';
import { Button, ButtonGroup, Divider, IconButton, List, ListItem, ListItemText } from '@mui/material';

//Components
import DrawerHeaderBar from '../DrawerHeaderBar/DrawerHeaderBar.jsx';

//Context
import AppContext from '../../context/appContext';
import { Add, Download, DownloadOutlined, RemoveCircle, Restore, UploadFile } from '@mui/icons-material';
import { errorMessages } from '../../models/errors/errorsMessages.js';
import moment from 'moment';
import restoreBackup from '../../services/backup/restoreBacakup.js';
import { successMessages } from '../../models/success/successMessages.js';
import createBackup from '../../services/backup/createBackup.js';
import deleteBackup from '../../services/backup/deleteBackup.js';
import { warningMessages } from '../../models/warnings/warningMessages.js';
import checkCreateWritableSupported from '../../services/backup/checkCreateWritableSupported.js';
import downloadBackup from '../../services/backup/downloadBackup.js';

export default function BackupList({ open, setShowBackups }) {
    //States
    const { setAlert, locale, setLoadingAlert } = useContext(AppContext);
    const [backups, setBackups] = useState();
    const [supportedFeature, setSupportedFeature] = useState({storage: false, createWritable: false});

    const handleUploadClick = async (e) => {
        const [file] = e.target.files
        if (!file) {
            alert('Please select a file first.');
            return;
        }
        try {
            await handleRestoreBackup({file, setLoadingAlert});
            
        } catch (error) {
            console.error('Error saving file:', error);
            alert('An error occurred while saving the file.');
        }
    };

    useEffect(() => {
        async function getBackups() {
            let backupsFromFs = await listBackups();
            setBackups(backupsFromFs);
        }
        getBackups();
    }, []);

    const handleRestoreBackup = async ({backup, file}) => {
        setAlert({
            ...warningMessages.restoreBackup[locale],
            actionButtonTitle: 'Continuar',
            cancelButtonTitle: 'CANCELAR',
            action: async () => {
                try {
                    await restoreBackup({backup, file, setLoadingAlert});
                    setAlert({
                        ...successMessages.backupRestoreSuccess[locale],
                        action: () => window.location.reload(),
                    });
                } catch (error) {
                    setAlert(errorMessages.backupRestoreError(error)[locale]);
                } finally {
                    setShowBackups(false);
                }
            },
        });
    };

    const handleNewBackup = async () => {
        try {
            await createBackup({
                type: 'user',
                setLoadingAlert,
                downloadWhenComplete: true
            });
            setAlert(successMessages.taskComplete[locale]);
            let backupsFromFs = await listBackups();
            setBackups(backupsFromFs);
        } catch (error) {
            setAlert(errorMessages.createBackupError[locale]);
        }
    };

    const handleDeleteBackup = async ({backup}) => {
        try {
            await deleteBackup(backup);
            setAlert({
                ...warningMessages.deleteBackup[locale],
                action: async () => {
                    setAlert(successMessages.taskComplete[locale]);
                    let backupsFromFs = await listBackups();
                    setBackups(backupsFromFs);
                },
            });
        } catch (error) {
            setAlert(errorMessages.deleteBackupError(error)[locale]);
        }
    };

    const handleDownloadBackup = async ({backup}) => {
        try {
            await downloadBackup({backup})
            setAlert(successMessages.taskComplete[locale])
        } catch(error) {
            setAlert(errorMessages.downloadBackupError(error)[locale])
        }
    }

    useEffect(() => {
        checkCreateWritableSupported({stateSetter: setSupportedFeature});
    },[])

    return (
        backups && (
            <Drawer PaperProps={{ sx: { height: '100vh' } }} anchor="bottom" open={open} onClose={() => setShowBackups(false)}>
                <DrawerHeaderBar height={'50px'} textColor="#FFFF" bgColor="#3F3938" title="Backups" handleClose={() => setShowBackups(false)} />
                <ButtonGroup
                    variant='text'
                    size='large'
                    fullWidth
                    color='primary'>
                        <Button disabled={backups.filter(backup => backup.type === 'User').length >= 3} 
                            endIcon={supportedFeature.createWritable ? <Add /> : <DownloadOutlined />} 
                            onClick={handleNewBackup}>
                            {supportedFeature.createWritable ? 'Nuevo Backup' : 'Descargar backup'}
                        </Button>
                        <Button disabled component={'label'} endIcon={<UploadFile />} sx={{ width: '100%' }}>
                            Subir Archivo
                            <input hidden type="file" accept=".json" id="kml-upload-button" onChange={handleUploadClick} />
                        </Button>
                    </ButtonGroup>
                    <Divider />
                <List>
                    {backups.map((backup, index) => (
                        <ListItem divider key={index}>
                            <ListItemText primary={`${moment(backup.date).locale(locale).calendar()}`} secondary={`${backup.size}MB - ${backup.type}`} />
                            {/*<IconButton color="primary" onClick={() => handleInfoClick(backup)}>
                                <Info />
                            </IconButton>*/}
                            <IconButton disabled color="primary" onClick={() => handleRestoreBackup({backup})}>
                                <Restore />
                            </IconButton>
                            <IconButton color="primary" onClick={() => handleDownloadBackup({backup})}>
                                <Download />
                            </IconButton>
                            <IconButton color="error" onClick={() => handleDeleteBackup({backup})}>
                                <RemoveCircle />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        )
    );
}
