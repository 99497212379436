export const warningMessages = {
    deleteBackup: {
        es_AR: { show: true, severity: 'warning', actionButtonTitle: 'continuar', cancelButtonTitle: 'cancelar' , message: 'Se borrará el backup seleccionado, desea continuar?' },
        en_US: { show: true, severity: 'warning', actionButtonTitle: 'continue' , cancelButtonTitle: 'cancel' , message: 'Selected backup will be permanently deleted, do you to continue?' }
    },
    restoreBackup: {
        es_AR: { title: 'Restaurar backup', show: true, severity: 'warning', message: 
        `Solo realizar en caso de perdida total de la información.  
        Si continua todos los datos actuales se perderán.  
        Se recomiendo solicitar ayuda para realizar esta operación.`},
        en_US: { title: 'Restore backup', show: true, severity: 'warning', message: `All data will be lost. Only use in case of data loss.`},
    },
    createBackup: {
        es_AR: { title: 'Crear backup', show: true, severity: 'warning', actionButtonTitle: 'continuar', cancelButtonTitle: 'omitir', message: `¿Crear backup previo a la sincronización?`},
        en_US: { title: 'Create backup', show: true, severity: 'warning',actionButtonTitle: 'continue', cancelButtonTitle:'skip',  message: `Create backup before download?`},
    },
}