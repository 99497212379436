//React
import React from 'react';
import ReactDOM from 'react-dom/client';
//Auth0
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./configAuth.js";
//Router
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

//CSS
import './index.css';

//Components
import App from './App';

//PWA & Service Workers
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { authStore } from './services/lfStorage/lfStorage.js';

let config = getConfig(process.env.REACT_APP_ENV);
const history = createBrowserHistory();


const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin + window.location.pathname,
  cache: authStore,
  useRefreshTokens: true,
  onRedirectCallback,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <BrowserRouter history={history} basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
reportWebVitals();