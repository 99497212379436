import * as localDb from "../localDb/index.js";
import refDataPayloadById from "../localRefData/refDataPayloadById.js";

import { stmIseFields } from "../../components/TaskForms/stm/stmIseFields.js";
import { stmForageFields } from "../../components/TaskForms/stm/stmForageFields.js";

import { BuildReportError } from "../../models/errors/index.js";

import XLSX from "xlsx-js-style";
import { errorMessages } from "../../models/errors/errorsMessages.js";
import { latLngToArray } from "../geolocationScripts/latLngToArray.js";

import { getInitials } from "../helpers/stringManipulation/getInitials.js";

export default async function getReport(monitoringEventId) {
    try {
        const locale = "es_AR";

        const SHEET_TEMPLATE_MAX_CHARS = 11;
        const SHEET_NAME_MAX_CHARS = 31;
        const SITE_NAME_MAX_CHARS = SHEET_NAME_MAX_CHARS - SHEET_TEMPLATE_MAX_CHARS;

        const eventData = await localDb.getOne("events", monitoringEventId);
        const samplingAreas = await getSamplingAreasByFarmId(eventData.farmId);
        let sites = await getSitesByFarmId(eventData.farmId);

        sites = sites.map((site) => {
            const name = site.name.replace(/[^\w\s-]/gi, "");
            return {
                ...site,
                name,
                siteShortName: getInitials(name),
                hasLongName: name.length > SITE_NAME_MAX_CHARS,
            };
        });

        const socTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "SocGridSampling",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        let socActivities = await localDb.getMany("activities").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                })
                .and((activity) => [0, 3].includes(activity.monitoringWorkflowId) && parseInt(activity.taskStatusId) !== 0)
                .sortBy("key")
        );

        socActivities = socActivities.filter((activity) => socTasks.some((task) => task.monitoringActivityId === activity.id));

        const socSummaryTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "SocSampleSummary",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        const dapTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "DapSample",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        const iseTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "StmIseForm",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        // map iseTask to activity
        iseTasks.forEach(async (iseTask) => {
            let activity = await localDb.getOne("activities", iseTask.monitoringActivityId);
            iseTask['monitoring_type'] = refDataPayloadById("workflows", activity?.monitoringWorkflowId)?.name
        })

        const stmForageTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "StmForagePictures",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );


        const ltmBiodiversityTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "LtmBiodiversity",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );


        const ltmActivities = await localDb.getMany("activities").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                })
                .and((activity) => [2].includes(activity.monitoringWorkflowId))
                .sortBy("key")
        );

        const ltmInfiltrometerTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "LtmInfiltrometer",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        const ltmSoilProfileTasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "LtmSoilProfile",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        const ltmT3Tasks = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "LtmBiodiversityT3",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        const ltmCvData = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "LtmBiodiversityFlexibleAreaStep1",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        const ltmFlexArea = await localDb.getMany("tasks").then((result) =>
            result
                .where({
                    monitoringEventId: monitoringEventId,
                    formName: "LtmBiodiversityFlexibleAreaStep2",
                })
                .and((task) => task.dataPayload !== undefined && task.dataPayload !== null)
                .sortBy("key")
        );

        function parseLtmBiodiversityTask(taskData) {
            try {
                let speciesMap = new Map();
                for (let species of taskData.species1to50) {
                    speciesMap.set(species.speciesName, { especie: species.speciesName, "1_a_50": species.score, "51_a_100": 0 });
                }

                for (let species of taskData.species51to100) {
                    if (speciesMap.has(species.speciesName)) {
                        let current = speciesMap.get(species.speciesName);
                        current["51_a_100"] = species.score;
                        speciesMap.set(species.speciesName, current);
                    }
                    speciesMap.set(species.speciesName, { especie: species.speciesName, "1_a_50": 0, "51_a_100": species.score });
                }
                return Array.from(speciesMap, ([name, value]) => ({ ...value }));
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${taskData.key}`);
            }
        }

        function parseIseDataSummary(taskData) {
            try {
                let record = {};
                if (taskData) {
                    record['SCORE TOTAL'] = taskData["totalIse"];
                    for (const field of stmIseFields) {
                        if (field.name !== `${field.name}-check` && taskData[`${field.name}-check`]) {
                            record[field.label] = taskData[field.name];
                        } else {
                            record[field.label] = null;
                        }
                    }
                }
                return record;
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${taskData.key}`);
            }
        }

        function parseStmForageDataSummary(taskData) {
            try {
                let record = {};
                if (taskData) {
                    for (const field of stmForageFields) {
                        if (taskData[field.name]) {
                            record[field.label] = taskData[field.name];
                        } else {
                            record[field.label] = null;
                        }
                    }
                }
                return record;
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${taskData.key}`);
            }
        }

        const getLtmCvData = (monitoringActivityId) => {
            const task = ltmCvData.find((task) => task.monitoringActivityId === monitoringActivityId);
            try {
                if (task && task.dataPayload) {
                    const taskData = task.dataPayload;
                    if (Array.isArray(taskData.speciesFlexibleArea)) {
                        return taskData.speciesFlexibleArea.map((sp) => ({
                            especie: sp.speciesName,
                            cantidad: sp.amountOfIndividuals,
                            area_cubierta: sp.areaCovered,
                        }));
                    }
                }
                return [];
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${task.key}`);
            }
        }

        const getLtmAfData = (monitoringActivityId) => {
            const task = ltmFlexArea.find((task) => task.monitoringActivityId === monitoringActivityId);
            try {
                if (task && task.dataPayload) {
                    const taskData = task.dataPayload;
                    if (Array.isArray(taskData.speciesExtendedArea)) {
                        return taskData.speciesExtendedArea.map((sp) => ({
                            especie: sp.speciesName,
                            distancia_t2: sp.shortestDistanceToT2,
                            area_cubierta: sp.areaCovered,
                        }));
                    }
                }
                return [];
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${task.key}`);
            }
        };

        const getInfiltrometerData = (monitoringActivityId) => {
            let infiltrometerTasks = ltmInfiltrometerTasks.filter((task) => task.monitoringActivityId === monitoringActivityId);
            try {
                if (infiltrometerTasks) {
                    return infiltrometerTasks.map((task) => {
                        let position = latLngToArray(task.actualLocation) || latLngToArray(task.plannedLocation);
                        return {
                            infiltrometro: task.key,
                            lat: position[0],
                            lng: position[1],
                            saturacion_inicial: task.dataPayload["initialSaturation"] ? 'completa' : 'no',
                            volumen_cc: task.dataPayload["infiltrationTestVolume"],
                            tiempo_mins: task.dataPayload["infiltrationTime"],
                            lamina_remanente_cm: task.dataPayload["remainingWaterDepth"],
                        };
                    });
                } else {
                    return [];
                }
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${infiltrometerTasks.key}`);
            }
        };

        const getLtmT3Data = (monitoringActivityId) => {
            let task = ltmT3Tasks.find((task) => task.monitoringActivityId === monitoringActivityId);
            try {
                if (task && task.dataPayload) {
                    let baseArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
                    let dataReturn = baseArray.map((i) => {
                        let obj = {
                            punto: i,
                            distancia_perenne_mts: task.dataPayload[`closestPerennialDistanceMts-${i}`],
                        };
                        return obj;
                    });
                    return dataReturn;
                } else {
                    return [];
                }
            } catch (error) {
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${task.key}`);
            }
        };

        const getSoilProfileData = (monitoringActivityId) => {
            let soilProfileTask = ltmSoilProfileTasks.find((task) => (task.monitoringActivityId === monitoringActivityId));
            try {
                if (soilProfileTask && soilProfileTask.dataPayload && soilProfileTask.dataPayload.horizons) {
                    return soilProfileTask.dataPayload.horizons.filter((horizon) => horizon.code !== '').map((horizon) => {
                        return {
                            codigo: refDataPayloadById("horizonCodes", parseInt(horizon.code, 10))[locale],
                            profundidad_inicio: horizon.depthStart,
                            profundidad_fin: horizon.depthEnd,
                            textura: horizon.soilTexture && horizon.soilTexture !== '' ? refDataPayloadById("soilTextures", horizon.soilTexture)[locale] : null,
                            estructura_tipo: horizon.structureType && horizon.structureType !== '' ? refDataPayloadById("structureTypes", horizon.structureType)[locale] : null,
                            estructura_tamaño: horizon.structureSize && horizon.structureSize !== '' ? refDataPayloadById("structureSizes", horizon.structureSize)[locale] : null,
                            estructura_grado: horizon.structureGrade && horizon.structureGrade !== '' ? refDataPayloadById("structureGrades", horizon.structureGrade)[locale] : null,
                            color_humedo: horizon.colorWet,
                            color_seco: horizon.colorDry,
                            raices_cantidad: horizon.rootsAmount,
                            gravas_cantidad: horizon.gravelsAmount,
                            comentarios: horizon.comments,
                        };
                    });
                } else {
                    return [];
                }
            } catch (error) {
                console.error(error)
                throw new BuildReportError(`${errorMessages.verifyDataError[locale].message} ${soilProfileTask.key}`);
            }
        };

        const headerStyle = { alignment: { horizontal: "left", vertical: "middle" }, font: { bold: true } };

        const siteHeader = (site) => {
            let position = site.actualLocation || site.plannedLocation;
            return [
                [
                    { v: "Nombre del sitio:", t: "s", s: headerStyle },
                    { v: site.name, s: { alignment: { horizontal: "right" } } },
                ],
                [
                    { v: "Coordenadas:", t: "s", s: headerStyle },
                    { v: position, s: { alignment: { horizontal: "right" } } },
                ],
                [
                    { v: "Latitud:", t: "s", s: headerStyle },
                    { v: position[0], s: { alignment: { horizontal: "right" } } },
                ],
                [
                    { v: "Longitud:", t: "s", s: headerStyle },
                    { v: position[1], s: { alignment: { horizontal: "right" } } },
                ],
            ];
        };

        const taskHeader = (task) => {
            let position = task.actualLocation || task.plannedLocation;
            return [
                [
                    { v: "Tarea:", s: headerStyle },
                    { v: task.key, s: { alignment: { horizontal: "right" } } },
                ],
                [
                    { v: "Coordenadas:", s: headerStyle },
                    { v: position, s: { alignment: { horizontal: "right" } } },
                ],
                [
                    { v: "Latitud:", s: headerStyle },
                    { v: position[0], s: { alignment: { horizontal: "right" } } },
                ],
                [
                    { v: "Longitud:", s: headerStyle },
                    { v: position[1], s: { alignment: { horizontal: "right" } } },
                ],
            ];
        };

        let worksheets = [
            {
                sheet: "SITIOS",
                siteHeader: null,
                taskHeader: null,
                data: [
                    sites.map((site) => {
                        const plannedLocation = latLngToArray(site.plannedLocation);
                        const actualLocation = latLngToArray(site.actualLocation);
                        return {
                            sitio: site.name,
                            plan_lat: plannedLocation ? plannedLocation[0] : "",
                            plan_lng: plannedLocation ? plannedLocation[1] : "",
                            actual_lat: actualLocation ? actualLocation[0] : "",
                            actual_lng: actualLocation ? actualLocation[1] : "",
                            sitio_aleatorio: site.isRandomSite ? "si" : "no",
                            confirmado_a_campo: site.locationConfirmed ? "si" : "no",
                            estrato: samplingAreas.find((sa) => sa.id === site.samplingAreaId)?.name,
                        };
                    }),
                ],
            },

            {
                sheet: "DAP-MUESTREOS",
                siteHeader: null,
                taskHeader: null,
                data: [
                    dapTasks.map((row) => {
                        const site = sites.find((site) => site.id === row.monitoringSiteId);
                        const sitePosition = latLngToArray(site.actualLocation) || latLngToArray(site.plannedLocation);
                        const taskPosition = latLngToArray(row.actualLocation) || null;
                        return {
                            sitio: site.name,
                            sitio_lat: sitePosition ? sitePosition[0] : "",
                            sitio_lng: sitePosition ? sitePosition[1] : "",
                            muestra_rotulo: row.dataPayload?.sampleLabel,
                            muestra_lat: taskPosition ? taskPosition[0] : "",
                            muestra_lng: taskPosition ? taskPosition[1] : "",
                            profundidades: "",
                            volumen_excavación_cc: row.dataPayload?.sampleVolume,
                            peso_fresco_gr: "",
                        };
                    }),
                ],
            },
            {
                sheet: "SOC-MUESTREOS",
                siteHeader: null,
                taskHeader: null,
                data: [
                    socActivities.map((row) => {
                        const site = sites.find((site) => site.id === row.monitoringSiteId);
                        const samplingArea = samplingAreas.find((area) => area.id === site.samplingAreaId);
                        const socTasksFiltered = socTasks.filter((task) => row.id === task.monitoringActivityId && row.monitoringSiteId === task.monitoringSiteId);
                        const socSummaryTask = socSummaryTasks.find((task) => row.id === task.monitoringActivityId && row.monitoringSiteId === task.monitoringSiteId);
                        const sitePosition = latLngToArray(site.actualLocation) || latLngToArray(site.plannedLocation);

                        return {
                            sitio: site.name,
                            sitio_lat: sitePosition ? sitePosition[0] : "",
                            sitio_lng: sitePosition ? sitePosition[1] : "",
                            estrato: samplingArea ? samplingArea.name : "Sin estrato",
                            muestra_rotulo: row.key,
                            cant_sub_muestras: socTasksFiltered.length,
                            profundidad_promedio: socTasksFiltered.map((task) => parseFloat(task.dataPayload?.sampleDepthCm)).reduce((a, b) => a + b, 0) / (socTasksFiltered.length || 1),
                            diametro_promedio: socTasksFiltered.map((task) => parseFloat(task.dataPayload?.sampleDiameterCm)).reduce((a, b) => a + b, 0) / (socTasksFiltered.length || 1),
                            peso_fresco_kg: socSummaryTask?.dataPayload?.sampleWetWeightKg,
                            comentarios: socSummaryTask?.dataPayload?.comments,
                        };
                    }),
                    socTasks.map((row) => {
                        const site = sites.find((site) => site.id === row.monitoringSiteId);
                        const samplingArea = samplingAreas.find((area) => area.id === site.samplingAreaId);
                        const sitePosition = latLngToArray(site.actualLocation) || latLngToArray(site.plannedLocation);
                        const taskPosition = latLngToArray(row.actualLocation) || null;

                        return {
                            estrato: samplingArea ? samplingArea.name : "Sin estrato",
                            sitio: site.name,
                            sitio_lat: sitePosition ? sitePosition[0] : "",
                            sitio_lng: sitePosition ? sitePosition[1] : "",
                            sub_muestra_lat: taskPosition ? taskPosition[0] : "",
                            sub_muestra_lng: taskPosition ? taskPosition[1] : "",
                            id_muestra: row.key,
                            profundidad_calador_cm: row.dataPayload?.sampleDepthCm,
                            diametro_calador_cm: row.dataPayload?.sampleDiameterCm,
                        };
                    }),
                ],
            },
            {
                sheet: "ISE Y FORRAJE",
                siteHeader: null,
                taskHeader: null,
                data: [
                    iseTasks.map((iseTask) => {
                        const site = sites.find((site) => site.id === iseTask.monitoringSiteId);
                        const samplingArea = samplingAreas.find((area) => area.id === site.samplingAreaId);
                        const forageTask = stmForageTasks.find((task) => task.monitoringSiteId === site.id && task.monitoringActivityId === iseTask.monitoringActivityId && task.dataPayload);
                        return {
                            sitio: site.name,
                            sitio_lat: latLngToArray(site.actualLocation) ? latLngToArray(site.actualLocation)[0] : "",
                            sitio_lng: latLngToArray(site.actualLocation) ? latLngToArray(site.actualLocation)[1] : "",
                            estrato: samplingArea ? samplingArea.name : "Sin estrato",
                            monitoreo_ise: iseTask.monitoring_type,
                            fecha: iseTask.dataPayload?.timestamp,
                            ...parseIseDataSummary(iseTask.dataPayload),
                            ...parseStmForageDataSummary(forageTask?.dataPayload),
                        };
                    })
                ],
            },
            ...ltmBiodiversityTasks
                .filter((task) => task.dataPayload !== null)
                .map((task) => {
                    const site = sites.find((site) => site.id === task.monitoringSiteId);
                    const sheet = `LTM-${site.hasLongName ? site.siteShortName : site.name}-BIO-T${task.type === "ltmBiodiversityT1" ? 1 : 2}`;
                    return {
                        sheet,
                        siteHeader: siteHeader(site),
                        taskHeader: taskHeader(task),
                        data: [parseLtmBiodiversityTask(task.dataPayload)],
                    };
                }),
            ...ltmActivities.map((activity) => {
                const site = sites.find((site) => site.id === activity.monitoringSiteId);
                const sheet = `LTM-${site.hasLongName ? site.siteShortName : site.name}-INFILT`;
                return {
                    sheet,
                    siteHeader: siteHeader(site),
                    taskHeader: null,
                    data: [getInfiltrometerData(activity.id), getSoilProfileData(activity.id)],
                };
            }),
            ...ltmActivities.map((activity) => {
                const site = sites.find((site) => site.id === activity.monitoringSiteId);
                const sheet = `LTM-${site.hasLongName ? site.siteShortName : site.name}-CV-AF`;
                return {
                    sheet,
                    siteHeader: siteHeader(site),
                    taskHeader: null,
                    data: [getLtmCvData(activity.id), getLtmAfData(activity.id)],
                };
            }),
            ...ltmActivities.map((activity) => {
                const site = sites.find((site) => site.id === activity.monitoringSiteId);
                const sheet = `LTM-${site.hasLongName ? site.siteShortName : site.name}-T3`;
                return {
                    sheet,
                    siteHeader: siteHeader(site),
                    taskHeader: null,
                    data: [getLtmT3Data(activity.id)],
                };
            }),
        ];

        const wb = XLSX.utils.book_new();

        for (let worksheet of worksheets) {
            if (worksheet.data.some((dataset) => dataset.length > 0)) {
                const ws = XLSX.utils.aoa_to_sheet([
                    [
                        { v: "Nombre de evento:", t: "s", s: headerStyle },
                        { v: eventData.name, s: { alignment: { horizontal: "right" } } },
                    ],
                    [
                        { v: "Fecha:", s: headerStyle },
                        { v: eventData.date, s: { alignment: { horizontal: "right" } } },
                    ],
                ]);

                if (worksheet.siteHeader) XLSX.utils.sheet_add_aoa(ws, worksheet.siteHeader, { origin: 2 });
                if (worksheet.taskHeader) XLSX.utils.sheet_add_aoa(ws, worksheet.taskHeader, { origin: 3 });

                let headerRows = []; //keep header rows for further styling
                let lastRow = 8;

                if (worksheet.data) {
                    for (let data of worksheet.data) {
                        headerRows.push(lastRow); //push header row number
                        XLSX.utils.sheet_add_json(ws, data, { origin: lastRow });
                        lastRow += data.length + 5;
                    }
                }

                //Aplicar estilos generales altura y ancho
                ws["!rows"] = Array(lastRow).fill({ hpx: 20 });
                ws["!cols"] = Array(30).fill({ wch: 25 });

                //ws['!ref'] = { hpx: 20, wch: 25}

                //Get range to apply styles to data and header rows.
                const range = XLSX.utils.decode_range(ws["!ref"]);

                //Aplicar estilo a filas de datos.
                for (let R = 7; R <= range.e.r; ++R) {
                    // Start from 7 (8th row)
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
                        if (!ws[cellAddress]) continue;
                        ws[cellAddress].s = { alignment: { horizontal: "center", vertical: "middle", wrapText: true } };
                    }
                }
                //Aplicar estilo a los headers
                for (let col = 0; col <= range.e.c; col++) {
                    for (let row of headerRows) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!ws[cellAddress]) continue;
                        ws[cellAddress].s = { font: { bold: true }, alignment: { horizontal: "center", vertical: "middle" } };
                    }
                }
                XLSX.utils.book_append_sheet(wb, ws, worksheet.sheet);
            }
        }
        const buffer = XLSX.write(wb, { type: "array", bookType: "xlsx" });
        //Descripción de suelo - Sitio - Coords - Código - Prof Iniciio - Prof Fin - Estructura- Tipo, Estructura- Tamaño - Estruct-Grado,  ColorHuedo ColorSeco, Raices , Comentarios

        return buffer;
    } catch (error) {
        console.error(error);
        throw new Error(error.message);
    }
}

async function getSitesByFarmId(farmId) {
    return await localDb.getMany("sites").then((result) => result.where({ farmId: farmId }).toArray());
}

async function getSamplingAreasByFarmId(farmId) {
    return await localDb.getMany("samplingAreas").then((result) => result.where({ farmId: farmId }).toArray());
}
