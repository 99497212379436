import ltmTasks from "./templates/ltmTasks.js";
import stmTasks from "./templates/stmTasks.js";
import socTasks from "./templates/socTasks.js";
import socTasksNative from "./templates/socTasksNative.js";
import fbcTasks from "./templates/fbcTasks.js";
export default async function generateTasks (site, userName, monitoringWorkflowId, monitoringEventId, monitoringActivityId, options) {
    try {
        if (monitoringWorkflowId === 0) {
            let tasks = socTasks(site, userName, monitoringEventId, monitoringActivityId);
            return tasks;
        }
        if (monitoringWorkflowId === 1) {
            let tasks = stmTasks(site, userName, monitoringEventId, monitoringActivityId);
            return tasks;
        }
        if (monitoringWorkflowId === 2) {
            let tasks = ltmTasks(site, userName, monitoringEventId, monitoringActivityId);
            return tasks;
        }
        if (monitoringWorkflowId === 3) {
            let tasks = socTasksNative(site, userName, monitoringEventId, monitoringActivityId);
            return tasks;
        }
        if (monitoringWorkflowId === 4) {
            let tasks = fbcTasks(userName, monitoringEventId, monitoringActivityId, options);
            return tasks;
        }
    } catch(error) {
        console.error(error);
        throw new Error( `Unable to create tasks: ${error.message}`)
    }
    throw new Error (`Monitoring workflow not found`);
}