export const errorMessages = {
    requiredPicture: {
        es_AR: { show: true, severity: 'error', message: 'Fotografía requerida' },
        en_US: { show: true, severity: 'error', message: 'Required Picture' }
    },
    unableToGenerateSite: {
        es_AR: { show: true, severity: 'error', message: 'No se pudo generar el sitio' },
        en_US: { show: true, severity: 'error', message: 'Unable to generate site'},
    },
    geolocationError: {
        es_AR: { show: true, severity: 'error', message: 'No se pudo obtener ubicación' },
        en_US: { show: true, severity: 'error', message: 'Geolocation Error'}
    },
    saveSettingsError: {
        es_AR: { show: true, severity: 'error', message: 'No se pudo guardar la configuración' },
        en_US: { show: true, severity: 'error', message: 'Unable to save configuration'}
    },
    verifyDataError: {
        es_AR: { show: true, severity: 'error', message: 'No se pudo construir el reporte. Verificar datos en:' },
        en_US: { show: true, severity: 'error', message: 'Unable to build report. Verify data in: '}
    },
    noChangesWarning: {
        es_AR: { title: 'Guardar', show: true, severity: 'warning', message: 'No hay cambios para guardar.' },
        en_US: { title: 'Warning', show: true, severity: 'warning', message: 'No changes to save.'}
    },
    permanentlyDeleteEvent: {
        es_AR: { title: 'Eliminar evento', actionButtonTitle: 'eliminar', cancelButtonTitle: 'cancelar', show: true, severity: 'error', message: 'Se eliminará el evento del dispositivo de manera permanente. Si desea descargar los datos previamente, haga click en **CANCELAR** y luego en el botón de descarga de Datos. Una vez descargados, puede borrar el evento de manera mas segura si lo desea.' },
        en_US: { title: 'Delete event', actionButtonTitle: 'delete', cancelButtonTitle: 'cancel' , show: true, severity: 'error', message: 'This action will permanently delete the event from this device and the data associated. Please download data first if needed with the download data button.'}
    },
    saveWithErrors: (errors) => ({
        es_AR: { title: 'Tarea Incompleta', show: true, severity: 'warning', message: 
        `Hay errores o datos faltantes.
        ¿Guardar de todas formas la tarea en curso?`},
        en_US: { title: 'Incomplete Task', show: true, severity: 'warning', message: `There area errors or missing data:  \- ${Object.keys(errors).join('\- ')}  Save task as **In progress**?`}
    }),
    backupRestoreError: (error) => ({
        es_AR: { title: 'Restaurar backup', show: true, severity: 'warning', message: `Error al restaurar backup.  
        ***${error.message}***`},
        en_US: { title: 'Restore backup', show: true, severity: 'warning', message: `An error ocurred when restoring backup.  
        ***${error.message}***`},
    }),
    deleteBackupError: (error) => ({
        es_AR: { title: 'Eliminar backup', show: true, severity: 'error', message: `No se pudo eliminar el backup.  
        ***${error.message}***`},
        en_US: { title: 'Delete backup', show: true, severity: 'error', message: `An error ocurred when deleting backup.  
        ***${error.message}***`},
    }),
    createBackupError: (error) => ({
        es_AR: { title: 'Nuevo backup', show: true, severity: 'error', message: `No se pudo crear el backup.  
        ***${error.message}***`},
        en_US: { title: 'New backup', show: true, severity: 'error', message: `An error ocurred when creating backup.  
        ***${error.message}***`},
    }),
    downloadBackupError: (error) => ({
        es_AR: { title: 'Descarga backup', show: true, severity: 'error', message: `No se pudo descargar el backup.  
        ***${error.message}***`},
        en_US: { title: 'Download backup', show: true, severity: 'error', message: `An error ocurred when downloading backup.  
        ***${error.message}***`},
    }),
}