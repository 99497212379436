import getApiData from './getApiData.js';
import setRefData from '../localRefData/setRefData.js';
import endpoints from './endpoints.js';

export default async function getReferenceData () {
    try {
        return await Promise.all([
            getApiData(endpoints.species).then((species) => setRefData('species', species.payload,)),
            getApiData(endpoints.taskStatus).then(status => setRefData('taskStatus', status.payload)),
            getApiData(endpoints.workflows).then(workflows => setRefData('workflows', workflows.payload)),
            getApiData(endpoints.activityLayouts).then(activityLayouts =>setRefData('activityLayouts', activityLayouts.payload)),
            getApiData(endpoints.programs).then( programs => setRefData('programs', programs.payload)),
            getApiData(endpoints.programConfig).then(programConfig => setRefData('refProgramConfigs', programConfig.payload)),
            getApiData(endpoints.soilTextures).then(soilTextures => setRefData('soilTextures', soilTextures.payload)),
            getApiData(endpoints.structureTypes).then(structureTypes => setRefData('structureTypes', structureTypes.payload)),
            getApiData(endpoints.structureSizes).then(structureSizes => setRefData('structureSizes', structureSizes.payload)),
            getApiData(endpoints.structureGrades).then(structureGrades => setRefData('structureGrades', structureGrades.payload)),
            getApiData(endpoints.horizonCodes).then(horizonCodes => setRefData('horizonCodes', horizonCodes.payload))
        ])
    } catch (error) {
        console.error(error.message);
        throw new Error(`Unable to get reference data from API: ${error.message}`);
    }
}