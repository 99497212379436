//React
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

//Icons
import CameraAlt from '@mui/icons-material/CameraAlt';

import compressImage from '../../services/helpers/compressImage.js';
import imageCompression from 'browser-image-compression';



export function PhotoInput({picture, pictureKey, pictureId, height, width, pictureIndex, handleCameraFile, required}) {
    const [source, setSource] = useState();

    const handleCapture = async (target) => {

        if (target.files) {
            
            if (target.files.length !== 0) {
                const file = target.files[0];
                let compressedFile = await compressImage(file);
                let dataUrl = await imageCompression.getDataUrlFromFile(compressedFile);
                let fn = `${pictureKey}${file.name.slice(file.name.indexOf('.'))}`;
                let key = pictureKey;
                let exifData = file.exifData || null;
                setSource(dataUrl);
                handleCameraFile(dataUrl, pictureIndex, fn, key, pictureId, exifData);
            }
        }
    }

    const getBorderColor = () => {
        if (required && !source) {
            return '2px dashed red'
        } else {
            return '2px dashed grey'
        }
    }

    useEffect(() => {
        if (picture?.data) {
            setSource(picture.data)
        } else {
            if (picture?.link) {
                setSource(picture.link)
            }
        }
    }, [picture]);

    return (
        <Box display='flex' alignItems={'center'} justifyContent={'center'}  >
            <label htmlFor={pictureKey}>
                <Box
                    border={getBorderColor()}
                    borderRadius={'10px 10px 10px 10px'}
                    display='flex'
                    alignItems={'center'}
                    justifyContent={'center'}
                    height={height + 'px' || '100px'}
                    width={width + 'px' || '100px'}
                    sx={{overflow: 'hidden'}}
                >
                    {source ?
                        <img
                            height={height}
                            style={{ borderRadius: '10px 10px 10px 10px' }}
                            alt={pictureKey + '-photo'}
                            src={source}
                        />
                        :
                        <CameraAlt sx={{color: required ? 'red' : 'black'}}></CameraAlt>}
                </Box>
            </label>
            <input
                name={pictureKey}
                style={{ display: 'none' }}
                accept="image/*"
                id={pictureKey}
                type="file"
                onChange={(e) => handleCapture(e.target)}
            />
        </Box>
    );
}