// calcular grilla con utm.fromLatLng -> toLatLng
import * as utm from 'utm';
import * as turf from '@turf/turf';

export default function generateUtmGrid(initialPoint, activityLayoutId) {
  let gridPoints = [];
  let [filteredLayout] = JSON.parse(localStorage.getItem('activityLayouts')).filter((obj) => 
    obj.id === activityLayoutId
  );

  let grid = filteredLayout.grid

  let initialUtm = utm.fromLatLon(initialPoint.lat, initialPoint.lng);

  for (let i = 0; i < grid.length; i++) {
    let newUtm = {
      northing: initialUtm.northing + grid[i].relativeCoords[0],
      easting: initialUtm.easting + grid[i].relativeCoords[1],
      zoneNum: initialUtm.zoneNum,
      zoneLetter: initialUtm.zoneLetter,
    };
    let latLng = utm.toLatLon(
      newUtm.easting,
      newUtm.northing,
      newUtm.zoneNum,
      newUtm.zoneLetter
    );
    gridPoints.push(turf.helpers.point([latLng.longitude, latLng.latitude], { key: grid[i].key }));
  }
  return turf.helpers.featureCollection(gridPoints);
}
