//REACT
import { useEffect } from 'react'

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

//Components
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';

export default function TaskForm({
    geolocation, 
    position, 
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    monitoringSite
}) {
    const { control, setValue } = useFormContext();

    useEffect(() => {
        setValue('plannedLocation', monitoringSite.plannedLocation);
        setValue('actualLocation', monitoringSite.plannedLocation);
    }, [monitoringSite, setValue])

    return (
            <Stack spacing={2}>
                <Controller
                    control={control}
                    name="plannedLocation"
                    defaultValue={monitoringSite.plannedLocation}
                    readOnly={true}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            name='plannedLocation'
                            type={'text'}
                            label='Coordenadas originales'
                        />)}
                />
                <CoordinatesInputField
                    control={control}
                    editMode={editMode} 
                    setValue={setValue}
                    position={position}
                    plannedLocation={plannedLocation}
                    watch={watchPosition}
                    stopWatch={stopWatch}
                    geolocation={geolocation}
                />
            </Stack>
    )
}