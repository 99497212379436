import { defaultSettings } from '../settings/defaultSettings.js';


export function getDefault () {
    try {
        let settings = defaultSettings;
        return settings;
    } catch(error) {
        throw new Error (`Unable to get config: ${error.message}`)
    }
}