import { grey } from '@mui/material/colors'

export const appBarTheme = {
   /* selectBg: '#504B4B',
    labelTextFocused: '#9BC8F5',*/
    palette: {
        action: {
            disabled: 'inherit'
        },
        primary: {
            light: grey[100],
            main: ['production', 'local'].includes(process.env.REACT_APP_ENV) ? "#74914e" :'#D9785F',
            dark: grey[400],
            contrastText: grey[50],
        },
        secondary: {
            light: grey[200],
            main: '#9BC8F5',
            dark: grey[400],
            contrastText: grey[50]
        },
    },
    typography: {
        fontFamily: 'Quicksand',
        h1: {
            fontFamily: 'Montserrat',
            fontSize: '3rem'
        },
        h2: {
            fontFamily: 'Montserrat',
            fontSize: '2.5rem'

        },
        h3: {
            fontFamily: 'Montserrat',
            fontSize: '2rem'

        },
        h4: {
            fontFamily: 'Montserrat',
            fontSize: '1.5rem'

        },
        h5: {
            fontFamily: 'Montserrat',
            fontSize: '1.2rem'
        },
        h6: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
        },
        headerBold: {
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '1.2rem'
        },

    }
}