//MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Stack';
import Stack from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { AppBar } from '@mui/material';
import Marquee from 'react-fast-marquee';
import { useState } from 'react';

export default function DrawerHeaderBar({ title, handleClose, bgColor, textColor, height, rightIcon, alignTitle = 'center', marquee = false }) {
    const [startMarquee, setStartMarquee] = useState(true);

    return (
        <AppBar elevation={0} sx={{ backgroundColor: '#FFFFFF', color: '#000000' }} position={'sticky'}>
            <Grid container direction={'row'} p={1} justifyContent='space-between' alignItems='center' flexGrow={0} sx={{ height: (height || '50px'), maxWidth: '100vw', backgroundColor: bgColor, color: textColor }}>
                {alignTitle !== 'left' &&
                    <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                        <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'start', flexGrow: 0, flexDirection: 'row', height: '100%' }}>
                        </Box>
                    </Grid>}
                <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                    {marquee && startMarquee ?
                        <Marquee autoFill play={marquee && startMarquee} loop={1} onFinish={() => setStartMarquee(false)}>
                            <Stack spacing={1} sx={{ display: 'flex', alignItems: 'start', justifyContent: alignTitle, flexGrow: 1, flexDirection: 'row', height: '100%', overflow: 'hidden' }}>
                                <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', mr: 1 }} variant='h6'>{title.toUpperCase()}</Typography>
                                {rightIcon}
                            </Stack>
                        </Marquee> :
                        <Stack spacing={1} sx={{ display: 'flex', alignItems: 'start', justifyContent: alignTitle, flexGrow: 1, flexDirection: 'row', height: '100%', overflow: 'hidden' }}>
                            <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', mr: 1 }} variant='h6'>{title.toUpperCase()}</Typography>
                            {rightIcon}
                        </Stack>}
                </Grid>
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                    <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end', flexGrow: 1, flexDirection: 'row', height: '100%', paddingRight: 1, textAlign: 'right' }}>
                        <IconButton
                            size="small"
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <Close fontSize='medium' />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </AppBar>
    )
}