//REACT
import { useContext } from 'react'

//Hook Form
import { useFormContext, Controller } from "react-hook-form";

//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

//Components
import { PhotoInput } from '../../PhotoInput/PhotoInput.jsx';
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';

export default function TaskForm({
    geolocation,
    position,
    handleCameraInput,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
    pictures,
}) {
    const { task } = useContext(TaskContext);
    const { control, setValue } = useFormContext()
    return (

        <Stack spacing={2}>
            <Controller
                control={control}
                name="sampleLabel"
                defaultValue={task.key}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                    <TextField
                        {...field}
                        disabled
                        error={fieldState.error ? true : false}
                        fullWidth
                        name="sampleLabel"
                        label="Rótulo Muestra"
                    />
                )}
            />

            <Controller
                control={control}
                name="timestamp"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                    <TextField
                        {...field}
                        disabled
                        error={fieldState.error ? true : false}
                        name='timestamp'
                        fullWidth
                        label='Fecha y Hora'
                        type={'datetime'}
                    />)}
            />
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Stack spacing={1.5} component='fieldset'>
                <Typography variant='caption' component='legend'>MUESTRA INTACTA</Typography>
                <Controller
                    control={control}
                    name="sampleDiameterCm"
                    rules={{ required: 'Campo requerido' }}
                    render={({ field, fieldState }) => (
                        <NumericInput
                            field={field}
                            fieldState={fieldState}
                            label='Diámetro de calado'
                            min={0}
                            max={null}
                            unit={'cm'}
                        />

                    )}
                />
                <Controller
                    control={control}
                    name="sampleDepthCm"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <NumericInput
                            field={field}
                            fieldState={fieldState}
                            label='Profundidad'
                            min={0}
                            max={null}
                            unit={'cm'}
                        />
                    )}
                />
            </Stack>
            <PhotoInput
                width='150'
                height='150'
                handleCameraFile={handleCameraInput}
                picture={pictures.find(pic => pic.key === task.key)}
                pictureKey={pictures.find(pic => pic.key === task.key)?.key || task.key}
                pictureIndex={pictures.findIndex(pic => pic.key === task.key) >= 0 ? pictures.findIndex(pic => pic.key === task.key) : 0}
                pictureId={pictures.find(pic => pic.key === task.key)?.id || null}
            />
        </Stack>
    )
}