export default function refDataPayloadById(key, id) {
    if (!key) {
        throw new Error ('Missing key');
    }
    if (id === undefined || id === '') {
        return null;
    }
    try {
        let [data] = JSON.parse(localStorage.getItem(key)).filter((obj) => {
            return obj.id === id
        });
        return data
    } catch (error) {
        console.error(error);
        throw new Error (`Unable to retrieve reference data for ${key}`);
    }
}