import getBackupsDirHandle from "./getBackDirHandle.js";

export default async function deleteBackup(backup) {
    try {
        const backupsDirHandle = await getBackupsDirHandle();
        await backupsDirHandle.removeEntry(backup.fileName);
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to backup current data`);
    }
}