export default function setRefData(key, payload) {
    try {
        if (!Array.isArray(payload)) {
            throw new TypeError('Payload must be an array')
        }
        localStorage.setItem(key, JSON.stringify(payload));
        return 'Success';
    } catch (error) {
        console.error(error);
        throw new Error (`Unable to retrieve reference data for ${key}`);
    }
}