import ltmTasks from "./ltmTasks";
import socTasks from './socTasks';
import socTasksNative from './socTasksNative';
import stmTasks from './stmTasks';
import fbcTasks from "./fbcTasks";

export const templates = {
    ltmTasks,
    socTasks,
    socTasksNative,
    stmTasks,
    fbcTasks
}