import React, { useState, useEffect, useCallback } from 'react';

//Router
import { Routes, Route } from "react-router-dom";

//lfstore
import { appStore } from './services/lfStorage/lfStorage';
import * as localDb from './services/localDb';

//MUI
import Container from '@mui/material/Container'
import CssBaseline from "@mui/material/CssBaseline";

//Components
import CustomAppBar from './components/CustomAppBar/CustomAppBar';
import ActivityList from './components/ActivityList/ActivityList';
import Home from './components/Home/Home';
import TaskControl from './components/TaskControl/TaskControl';
import AlertModal from './components/AlertModal/AlertModal';

//Styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from './themes/main.js';

//Context
import AppContext from './context/appContext';

//Libs
import { Buffer } from "buffer";
import CustomAlert from './components/CustomAlert/CustomAlert';
import LoadingModal from './components/LoadingModal/LoadingModal.jsx';
import { getDefault } from './services/settings/getDefault.js';
import Settings from './components/Settings/Settings.jsx';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback/ErrorFallback.jsx';
import { tryPersistWithoutPromtingUser } from './services/helpers/storageUtils.js';
import { useRef } from 'react';
import BackupList from './components/BackupsList/BackupList.jsx';

global.Buffer = Buffer;


const theme = createTheme(themeOptions);

function App() {

  const timeoutUpdate = useRef(null);
  //States
  const [currentPage, setCurrentPage] = useState('');
  const [appBarTitle, setAppBarTitle] = useState('');
  const [showAppBar, setShowAppBar] = useState(true);
  const [navBackTarget, setNavBackTarget] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filtersConfig, setFiltersConfig] = useState();
  const [activeFilter, setActiveFilter] = useState({ mainFilter: 999, secondaryFilter: 999 });
  const [alert, setAlert] = useState(false);
  const [notify, setNotify] = useState({ show: false });
  const [currentEvent, setCurrentEvent] = useState();
  const [storageFreeze, setStorageFreeze] = useState(false);
  const [storageData, setStorageData] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [currentSettings, setCurrentSettings] = useState();
  const [showSettings, setShowSettings] = useState(false);
  const [showBackups, setShowBackups] = useState(false);
  const [locale, setLocale] = useState('es_AR');

  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [loadingSomething, setLoadingSomething] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [lastSyncActivity, setLastSyncActivity] = useState(null);
  const [currentUser, setCurrentUser] = useState(null)
  const [currentProgramConfig, setCurrentProgramConfig] = useState()

  function reportWindowSize(height, width) {
    setWindowHeight(height);
    setWindowWidth(width);
  }

  const errorCatcher = useCallback((error) => {
    if (error.notificationFormat === 'modal') {
      console.error(error.notificationObject)
      return setAlert(error.notificationObject[locale]);
    }
    if (error.notificationFormat === 'toast') {
      console.error(error.notificationObject)
      return setNotify(error.notificationObject[locale]);
    }
  }, [locale] );

  const handleUpgrade = async ({ manual }) => {
    const [registration] = await navigator.serviceWorker.getRegistrations();
    if (registration && registration.waiting && registration.waiting.state === 'installed') {
        setAlert({
            show: true,
            title: `Actualización disponible`,
            message: `Hay una actualización disponible. ¿Desea instalar la nueva versión ?`, 
            severity: 'warning',
            action: () => {
                try {
                  setAlert({show: false})
                  registration.waiting.postMessage({type: 'SKIP_WAITING'});
                  setLoadingAlert({show: true, message: 'Por favor espere...'});
                  timeoutUpdate.current = setTimeout(async () => {
                    const [registration] = await navigator.serviceWorker.getRegistrations();
                    if (registration && !registration.waiting) {
                      setAlert({
                        show: true,
                        message: 'Actualización existosa.',
                        severity: 'success',
                        action: () => {
                          window.location.reload()
                        }
                      })
                    } else {
                      setAlert({
                        show: true,
                        message: 'No se pudo completar la actualización de manera automática, por favor, cierre y abra la aplicación.',
                        severity: 'warning',
                      })
                    }
                  },
                  3000);
                } catch(error) {
                  setAlert({ show: true, message: 'Error al actualizar', severity: 'error'});
                } 
                finally {
                  setLoadingAlert({show: false});
                }
            },
            actionButtonTitle: 'Actualizar',
            cancelButtonTitle: 'Cancelar'
        })
    } else if (manual) {
        setAlert({
            show: true, 
            message: 'No hay actualizaciones disponibles.', 
            severity: 'success'            
        })
    }
  }

  const appContextValue = {
    currentPage,
    setCurrentPage,
    appBarTitle,
    setAppBarTitle,
    navBackTarget,
    setNavBackTarget,
    showFilters,
    setShowFilters,
    filtersConfig,
    setFiltersConfig,
    activeFilter,
    setActiveFilter,
    alert,
    setAlert,
    currentEvent,
    setCurrentEvent,
    isOnline,
    setIsOnline,
    loadingSomething,
    setLoadingSomething,
    lastSyncActivity,
    setLastSyncActivity,
    currentUser,
    setCurrentUser,
    notify,
    setNotify,
    currentProgramConfig,
    setCurrentProgramConfig,
    showAppBar,
    setShowAppBar,
    storageData,
    setStorageData,
    storageFreeze,
    setStorageFreeze,
    windowHeight,
    windowWidth,
    loadingAlert,
    setLoadingAlert,
    currentSettings,
    setCurrentSettings,
    showSettings,
    setShowSettings,
    showBackups,
    setShowBackups,
    locale,
    errorCatcher,
    handleUpgrade
  }

  useEffect(() => {
    async function initStoragePersistence() {
      try {
        const persist = await tryPersistWithoutPromtingUser();
      switch (persist) {
        case "never":
          console.log("Not possible to persist storage");
          break;
        case "persisted":
          console.log("Successfully persisted storage silently");
          break;
        case "prompt":
          console.log("Not persisted, prompting permission.");
          setAlert({
            show: true, 
            message: `Se activará la protección adicional de almacenamiento.`, 
            actionButtonTitle: 'ACEPTAR',
            action: async () => await navigator.storage.persist()
          });
          break;
        default:
          break;
      }
      } catch(error) {
        console.error(`Unable to persist storage: `)
      }
      
    }
    initStoragePersistence()
  },[])

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      reportWindowSize(e.target.innerHeight, e.target.innerWidth);
    });
    return () => {
      clearTimeout(timeoutUpdate);
      window.removeEventListener('resize', (e) => reportWindowSize(e.target.innerHeight, e.target.innerWidth));
    };
  }, []);


  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    async function getCurrentUser() {
      let user = await appStore.get('currentUser');
      setCurrentUser(user)
    }
    getCurrentUser();
  }, [])

  useEffect(() => {
    async function getSettings() {
      try {
        let settings = {};
        if (currentUser) {
          settings = await localDb.getOne('settings', 'custom');
        }
        if (settings) {
          setCurrentSettings(settings.configuration);
        } else {
          let defaultSettings = getDefault();
          setCurrentSettings(defaultSettings.configuration);
        }
      } catch (error) {
        console.error(`Unable to get settings: ${error.message}`);
      }
    }
    getSettings();
  }, [currentUser, setCurrentSettings]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AppContext.Provider value={appContextValue}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CustomAlert
            show={notify.show}
            title={notify.title}
            message={notify.message}
            severity={notify.severity}
            icon={notify.icon}
            handleClose={() => setNotify({ open: false })}
          />
          <AlertModal
            show={alert.show}
            toggleAlert={setAlert}
            severity={alert.severity}
            title={alert.title}
            message={alert.message}
            action={alert.action}
            actionButtonTitle={alert.actionButtonTitle}
            cancelAction={alert.cancelAction}
            cancelButtonTitle={alert.cancelButtonTitle}
          />
          <LoadingModal
            toggleAlert={setLoadingAlert}
            severity={loadingAlert.severity}
            show={loadingAlert.show}
            message={loadingAlert.message}
          />
          <CustomAppBar
            show={showAppBar}
            title={appBarTitle}
            filters={false}
            sort={false}
          />
          <Settings
            show={showSettings}
            setShowSettings={setShowSettings}
          />
          {showBackups && <BackupList
            open={showBackups} 
            setShowBackups={setShowBackups} 
          />}
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
          >
            <Container disableGutters maxWidth={false} sx={{ flexGrow: 1, marginLeft: 0, marginRight: 0, overflow: 'hidden', overscrollBehavior: 'none' }} className='App'>
              <Routes>
                <Route key='home' path='/' element={<Home />} />
                <Route path='/events/:eventId' element={<ActivityList />} />
                <Route path='/tasks/:taskId' element={<TaskControl />} />
              </Routes>
            </Container>
          </ErrorBoundary>
        </ThemeProvider>
      </AppContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
