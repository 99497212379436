import { Button, Stack, Typography, Link } from "@mui/material";
export default function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <Stack flex spacing={1} alignItems={'center'} justifyContent={'center'} height={'80vh'}>
            <Typography>Ha ocurrido un error:</Typography>
            <Typography style={{ color: "red" }}>{error.message}</Typography>
            <Button variant='contained' onClick={() => resetErrorBoundary()}>Reintentar</Button>
            <Link color='inherit' target={'_blank'} href="https://share.hsforms.com/1JLOc-3huQviHqJDhNl7XjQd7qz7" underline="none">
                Ayuda
            </Link>
        </Stack>
    );
}