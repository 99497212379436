//REACT
import { useContext, useEffect, useState } from 'react'

//Hook Form
import { Controller, useFieldArray, useFormContext } from "react-hook-form";


//Context
import TaskContext from '../../../context/taskContext.js';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/RemoveCircle';

//Components
import CoordinatesInputField from '../../CoordinatesInputField/CoordinatesInputField.jsx';
import NumericInput from '../utils/NumericInput.jsx';
import refDataByKey from '../../../services/localRefData/refDataByKey.js';

export default function TaskForm({
    geolocation,
    position,
    watchPosition,
    stopWatch,
    editMode,
    plannedLocation,
}) {
    const { task } = useContext(TaskContext);
    const [species, setSpecies] = useState();
    const { control, setValue } = useFormContext();

    const speciesExtendedAreaArray = useFieldArray({
        control,
        name: 'speciesExtendedArea'
    });

    useEffect(() => {
        async function getSpecies() {
            let speciesAll = refDataByKey('species');
            setSpecies(speciesAll);
        }
        getSpecies();
    }, [task])

    return (
        <Stack spacing={2}>
            <CoordinatesInputField
                control={control}
                editMode={editMode}
                setValue={setValue}
                position={position}
                plannedLocation={plannedLocation}
                watch={watchPosition}
                stopWatch={stopWatch}
                geolocation={geolocation}
            />
            <Stack component='fieldset' display='flex' direction={'column'} spacing={1}>
                <Box pt={1} textAlign={'center'}>
                    <Typography><b>Lectura área extendida</b></Typography>
                    <Typography>Ingrese debajo, para cada especie, el área cubierta y la distancia a transecta #2 del individuo mas cercano.</Typography>
                </Box>

                {species && species.length > 1 && speciesExtendedAreaArray.fields.map((item, index) =>
                    <Stack display={'flex'} direction={'column'} key={item.id} spacing={1.5}>
                        <Stack direction={'row'} width={'100%'} spacing={1} display={'flex'} justifyContent={'space-evenly'}>
                            <Controller
                                rules={{required: true}}
                                key={`speciesExtendedArea.${item.id}.speciesName`}
                                control={control}
                                name={`speciesExtendedArea.${index}.speciesName`}
                                render={({ field, fieldState }) =>
                                    <Box display={'flex'} flexGrow={1}>
                                        <Autocomplete
                                            {...field}
                                            autoSelect
                                            fullWidth
                                            disablePortal
                                            freeSolo
                                            options={species}
                                            getOptionLabel={(option) => option.commonName || option}
                                            size="small"
                                            renderInput={(params) => 
                                                <TextField 
                                                    {...params} 
                                                    label="Especie"
                                                    error={fieldState.error ? true :  false} 
                                                />}
                                            onChange={(event, value) => {
                                                field.onChange(value);
                                            }}
                                        />
                                    </Box>
                                }
                            />
                            {<IconButton disabled={index === 0} size='small' color='error' onClick={() => speciesExtendedAreaArray.remove(index)}> <DeleteIcon /></IconButton>}
                        </Stack>
                        <Stack direction={'row'} width={'100%'} spacing={1}>
                            <Controller
                                rules={{required: true}}
                                key={`speciesExtendedArea.${item.id}.shortestDistanceToT2`}
                                control={control}
                                name={`speciesExtendedArea.${index}.shortestDistanceToT2`}
                                render={({ field, fieldState }) => (
                                    <NumericInput
                                        field={field}
                                        fieldState={fieldState}
                                        label='Distancia T2'
                                        min={0}
                                        max={null}
                                        unit={'m'}
                                    />
                                    )}
                            />
                            <Controller
                                rules={{required: true}}
                                key={`speciesExtendedArea.${item.id}.areaCovered`}
                                control={control}
                                name={`speciesExtendedArea.${index}.areaCovered`}
                                render={({ field, fieldState}) => (
                                    <NumericInput
                                        field={field}
                                        fieldState={fieldState}
                                        label='Area cubierta'
                                        min={0}
                                        max={null}
                                        unit={'m2'}
                                    />)}
                            />
                        </Stack>
                    </Stack>
                )}
                <Button color='primary' variant='text' size='small' onClick={() => speciesExtendedAreaArray.append({ speciesName: '', score: 0 })}>+ AGREGAR ESPECIE</Button>
            </Stack>
        </Stack>
    )
}