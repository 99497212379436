//MUI
import Stack from '@mui/material/Box';
import { Chip } from '@mui/material';

export default function GpsIndicator({editMode, gpsOn}) {

    /*const propsGps = gpsOn ? 
        { sx: { margin: .5, borderRadius: '5px 5px', height: '18px' }, color: 'success', size: 'small', label: 'GPS ON'}
        :
        { sx: { margin: .5, borderRadius: '5px 5px', height: '18px' }, color: 'warning', size: 'small', label: 'GPS OFF'}
    */
    const propsEditMode = { margin: .5, sx: { borderRadius: '5px 5px', height: '18px' }, color: 'primary', size: 'small', label: 'EDITANDO'}
    return (
        <Stack  display={'flex'} direction='row' alignItems={'center'} justifyContent={'center'} width={'100vw'}>
            {editMode && <Chip {...propsEditMode} />}
            {/*<Chip {...propsGps} />*/}
        </Stack>
        )
}